import { createMuiTheme, createStyles, makeStyles } from '@material-ui/core/styles';
import { idataDefaultColor, cardTitle, defaultFontColor, defaultSubCellColor, defaultText } from './../../assets/App';
import { orangeIdataBoxShadow } from './../../assets/App';

export const manageCertificate = () =>
   createStyles({
      root: {
         minHeight: 500,
         '& .MuiButton-textPrimary': {
            color: '#777',
         },
         '& .MuiBadge-colorPrimary': {
            backgroundColor: idataDefaultColor,
         },
         '& .MuiDataGrid-row *:focus': {
            boxShadow: '0 0 0 0',
            border: '0',
            outline: 0,
         }
      },
      container: {
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'space-around',
         alignItems: 'center',
         minHeight: 300
      },
      cardGrid: {
         width: '100%',
      },
      cardIcon: {
         borderRadius: '3px',
         backgroundColor: idataDefaultColor,
         padding: '15px',
         marginTop: '-20px',
         marginRight: '15px',
         float: 'left'
      },
      cardTitle: {
         ...cardTitle,
         padding: '15px 0 0 5px',
         marginBottom: '0px',
      },
      cellAlign: {
         justifyContent: 'center'
      },
      cellColor: {
         backgroundColor: defaultSubCellColor
      },
      button: {
         border: 0,
         backgroundColor: 'transparent',
         cursor: 'pointer',
         right: 0,
         position: 'absolute',
         padding: '10px 30px 0 0',
         zIndex: 1,
      },
      input: {
         color: '#000',
         width: '50%',
         float: 'left',
         alignItems: 'center',

         '&:after': {
            borderBottomColor: `${idataDefaultColor} !important`
         }
      },
      inputFile: {
         cursor: 'pointer',
         display: 'block',
         height: '100%',
         left: 0,
         opacity: 0,
         position: 'absolute',
         top: 0,
         width: '100%',
      },
      BoxInputs: {
         float: 'right',
         display: 'flex'
      },
      buttonSubmit: {
         background: 'transparent',
         border: 'solid 0',
         cursor: 'pointer',
         color: '#000',
         padding: 0,
         '&:hover,&:focus': {
            color: idataDefaultColor,
            boxShadow: orangeIdataBoxShadow,
            cursor: 'pointer'

         }
      },
      buttonEdit: {
         border: 'none',
         background: 'Transparent',
         cursor: 'pointer'
      },
      buttonDelete: {
         border: 'none',
         background: 'Transparent',
         color: '#CF0000',
         cursor: 'pointer',
      },
      box: {
         background: '#fff',
         borderRadius: 5,
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         height: 'auto',
         width: 600,
         border: '2px solid #fff',
         alignItems: 'center'
      },
      boxModalDelete: {
         background: '#fff',
         borderRadius: 5,
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: 600,
         border: '2px solid #fff',
         display: 'flex',
         justifyContent: 'space-between'
      },
      boxTitle: {
         background: '#f6a828',
         borderRadius: 5,
         position: 'absolute',
         color: '#fff',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         height: 35,
         width: 600,
         border: '2px solid #fff',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'space-between',
      },
      titleModal: {
         fontSize: 14,
         margin: '.4em 0 .2em ',
         marginLeft: '5px'
      },
      buttonExit: {
         padding: '0 15px',
         backgroundColor: 'transparent',
         border: '0',
         color: 'white',
         cursor: 'pointer'
      },
      boxForm: {
         fontSize:14,
         margin: '.1em 0 .2em ',
         marginLeft:'4px',
         height:'auto',
         marginTop: 30
      },
      textAreaContainer: {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'space-evenly',
         padding: '15px 30px',
         width: '100%',
      },
      removeCertificateMessage: {
         color: '#000000',
         justifyContent: 'center',
         
      },
      text: {
         ...defaultText,
      },
      fileContainer: {
         marginTop: 20,
         position: 'relative',
         textAlign: 'center',
      },
      hide: {
         overflow: 'hidden',
      },
      filename: {
         ...defaultText,
         marginTop: 20,
         display: 'flex',
         flexDirection: 'row',
         justifyContent: 'center',
         alignItems: 'center'
      },
      form: {
         width: '100%',
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center'
      },
      buttonContainer: {
         marginTop: 20,
         float: 'right',
         marginRight: 12,
         marginBottom: 8,
         color: idataDefaultColor,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
      },
      messageDelete: {
         marginTop: 20,
         marginRight: 12,
         marginBottom: 8,
         display: 'flex',
         justifyContent: 'right',
         alignItems: 'center',
         padding: '0 15px',
         fontSize: '.9rem'
      },
      buttonProgress: {
         color: idataDefaultColor,
         position: 'absolute',
      },

      infoModal: {
         marginTop: 20,
         display: 'block',
         color: '#000',
      },
      messageContainer: {
         width: '30%',
         margin: '0 auto',
         cursor: 'pointer',
         textAlign: 'center',
         position: 'relative',
      },
      addCertificate: {
         float: 'right',
         padding: '0 5px'
      }
   });

export const customTheme = createMuiTheme({
   overrides: {
      MuiSwitch: {
         colorPrimary: {
            '&$checked': {
               color: idataDefaultColor,
            },
            '&$checked + .MuiSwitch-track': {
               backgroundColor: idataDefaultColor,
            },
         },
         track: { backgroundColor: idataDefaultColor }
      },
      MuiFormLabel: {
         root: { '&$focused': { color: defaultFontColor } }
      },
      MuiInput: {
         underline: {
            '&:after': {
               borderBottomColor: idataDefaultColor
            }
         }
      },
      MuiButton: {
         textPrimary: {
            fontSize: 13,
            color: idataDefaultColor,
         },
      },
      MuiCircularProgress: {
         colorPrimary: { color: idataDefaultColor }
      },
      MuiMenuItem: {
         root: {
            fontSize: 15
         }
      }
   },
});

export const gridCellExpandStyles = makeStyles(() =>
   createStyles({
      root: {
         alignItems: 'center',
         lineHeight: '24px',
         width: '100%',
         height: '100%',
         position: 'relative',
         display: 'flex',
         '& .cellValue': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
         },
      },
   }),
);
