import { createMuiTheme, createStyles } from '@material-ui/core/styles';
import { idataDefaultColor, cardTitle, defaultFontColor, defaultSubCellColor, defaultText } from '../../assets/App';
import { orangeIdataBoxShadow } from '../../assets/App';

export const internalControl = () =>
   createStyles({
      root: {
         minHeight: 500,
         '& .MuiButton-textPrimary': {
            color: '#777',
         },
         '& .Mui-checked': {
            color: '#777',
         },
         '& .MuiBadge-colorPrimary': {
            backgroundColor: idataDefaultColor,
         },
         '& .MuiDataGrid-row *:focus': {
            boxShadow: '0 0 0 0',
            border: '0',
            outline: 0,
         }
      },
      cardIcon: {
         borderRadius: '3px',
         backgroundColor: idataDefaultColor,
         padding: '15px',
         marginTop: '-20px',
         marginRight: '15px',
         float: 'left'
      },
      cardTitle: {
         ...cardTitle,
         padding: '15px 0 0 5px',
         marginBottom: '0px',
      },
      cellColor: {
         backgroundColor: defaultSubCellColor
      },
      button: {
         border: 0,
         backgroundColor: 'transparent',
         cursor: 'pointer',
         right: 0,
         position: 'absolute',
         padding: '10px 30px 0 0',
         zIndex: 1,
      },

      inputFile: {
         cursor: 'pointer',
         display: 'block',
         height: '100%',
         left: 0,
         opacity: 0,
         position: 'absolute',
         top: 0,
         width: '100%',
      },
      BoxInputs: {
         float: 'right',
         display: 'flex'
      },
      buttonSubmit: {
         background: 'transparent',
         border: 'solid 0',
         cursor: 'pointer',
         color: '#000',
         padding: 0,
         '&:hover,&:focus': {
            color: idataDefaultColor,
            boxShadow: orangeIdataBoxShadow,
            cursor: 'pointer'
         }
      },
      container: {
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         minHeight: 300,
         width: '100%',
      },
      buttonContainer: {
         marginTop: 20,
         float: 'right',
         marginRight: 12,
         marginBottom: 8,
         color: idataDefaultColor,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
      },
      messageDelete: {
         marginTop: 20,
         marginRight: 12,
         marginBottom: 8,
         display: 'flex',
         justifyContent: 'right',
         alignItems: 'center',
         padding: '0 15px',
         fontSize: '.9rem'
      },
      buttonProgress: {
         color: idataDefaultColor,
         position: 'absolute',
      },
      infoModal: {
         marginTop: 20,
         display: 'block',
         color: '#000',
      },
      inputGroup: {
         display: 'block',
         width: '100%',
         padding: '5px 10px',
      },
      titleInputGroup: {
         fontSize: '16px',
         fontWeight: 'bolder'
      },
      separator: {
         width: '100%',
         height: '1px',
         background: '#ccc',
         margin: '20px 0'
      },
      inputs: {
         width: '100%',
         display: 'flex',
         gap: '32px',
         alignItems: 'center',
         marginTop: '10px'
      },
      inputBox: {
         width: '100%',
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'flex-start',
         justifyContent: 'flex-start',
         gap: '0.5rem',
         flex: '1 1 auto',
      },
      input: {
         fontSize: '14px',
         color: '#000',
         width: '100%',
         float: 'left',
         padding: '5px',
         '&:after': {
            borderBottomColor: `${idataDefaultColor} !important`
         }
      },
      cardGrid: {
         width: '100%',
         color: '#000',
         marginTop: '24px'
      },
      spanSubTitleInfo: {
         fontWeight: 400
      },
      link: {
         cursor: 'pointer',
         color: 'inherit',
         '&:hover': {
            color: idataDefaultColor,
         }
      },
   });

export const customTheme = createMuiTheme({
   overrides: {
      MuiSwitch: {
         colorPrimary: {
            '&$checked': {
               color: idataDefaultColor,
            },
            '&$checked + .MuiSwitch-track': {
               backgroundColor: idataDefaultColor,
            },
         },
         track: { backgroundColor: idataDefaultColor }
      },
      MuiFormLabel: {
         root: { '&$focused': { color: defaultFontColor } }
      },
      MuiInput: {
         underline: {
            '&:after': {
               borderBottomColor: idataDefaultColor
            }
         }
      },
      MuiButton: {
         textPrimary: {
            fontSize: 13,
            color: defaultFontColor,
         },
      },
      MuiCircularProgress: {
         colorPrimary: { color: idataDefaultColor }
      },
      MuiMenuItem: {
         root: {
            fontSize: 15
         }
      }
   },
});

