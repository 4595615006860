import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from './Header';
import Sidebar from 'components/Sidebar';
import { dashboardRoutes } from 'routes/dashboard';
import { isAuthenticated } from 'services';
import appStyle from './styles';


const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop: any, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;

      return <Route path={prop.path} component={prop.component} key={key} />
    })}
  </Switch>
);

function Dashboard(props: any) {

  const { classes, ...rest } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(true); 
  const location = useLocation();

  const mainPanel =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive
    });

  const resizeFunction = () => {
    if (window.innerWidth >= 960)
      setMobileOpen(false);
  }

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      // ps = new PerfectScrollbar(this.refs.mainPanel, {
      //   suppressScrollX: true,
      //   suppressScrollY: false
      //});
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);
    if (window.innerWidth <= 960)
      setMiniActive(false);

  }, []);

  useEffect(() => {
    return () => {
      if (navigator.platform.indexOf('Win') > -1)
        //ps.destroy();
        window.removeEventListener('resize', resizeFunction);
    }
  }, []);

  useEffect(() => {   
      setMobileOpen(false);    
  }, [location]);

  const handleDrawerToggle = () => {
    setMiniActive(false);

    setMobileOpen(!mobileOpen)
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  } 

  const inputRef = useRef<HTMLDivElement>(null);

  const application = process.env.REACT_APP_APPLICATION === undefined ? '' : process.env.REACT_APP_APPLICATION;

  
  if (!isAuthenticated()) {
    return <Redirect to={`${application}/auth/login`} />
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashboardRoutes}
        //image={backgroundImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color='defaultIlogColor'
        //bgColor='black' // caso houver imagem
        bgColor='idata' // caso não houver imagem, 
        miniActive={miniActive}
        {...rest}
      />
      <div id='dashboard'
        className={mainPanel}
        ref={inputRef}
      >
        <Header
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          routes={dashboardRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            {switchRoutes}
          </div>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  dashboardRoutes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(appStyle)(Dashboard)


