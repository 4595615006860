import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { ThemeProvider } from '@material-ui/core/styles';
import { GridContainer, GridItem, Card, CardBody, Snackbar, CardHeader } from 'components';
import GridCellExpand from './GridCellExpand';
import { getMessage } from 'services';
import { UserContext } from 'context';
import { messagesPanelStyle, customTheme } from './styles';
import { DataGrid, GridToolbar, GridColDef, GridCellParams } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import MessageIcon from '@material-ui/icons/Message';
import { formatDate } from 'utils/formatDate';
import { Link } from 'react-router-dom';


interface IMessages {
  id: number;
  CodigoDocumento: number;
  NumeroConhecimento: string;
  FromDate: string;
  Mensagem: string;
  Tipo: string;
  Status: string;
  Operacao: string;
  Protocolo: string;
  Cod_emp: number;
  messages?: [];
}

// interface IExpandMessages {
//   id: number | null;
//   open: boolean;
//   messageID: number;
// }

function MessagesPanel(props: any) {

  const { classes } = props;
  const { t } = useTranslation();
  const user = useContext(UserContext) as IUser;
  const [messages, setMessages] = useState<IMessages[]>([]);
  const [valueFlightNum, setValueFlightNum] = useState('');
  const [valueDepartureDate, setValueDepartureDate] = useState('');
  const [loading, setLoading] = useState(false);
  //const [expandMessages, setExpandMessage] = useState<Array<IExpandMessages>>([{ id: null, open: false, messageID: 0 }]);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });

  // const [hiddenConfirmModal, setHiddenConfirmModal] = useState(false);
  // const [confirmDeletion, setConfirmDeletion] = useState(false);
  // const handleCloseConfirmModal = () => setHiddenConfirmModal(false);

  const [limitFirst, setLimitFirst] = useState(0);

  const handleLoadMessages = useCallback(async () => {
    setLoading(true);
    setValueDepartureDate('');
    setValueFlightNum('');

    try {
      if (user) {
        const response = await getMessage(valueFlightNum, valueDepartureDate, '', 0, 1000);
        if (response && response.data) {
          setMessages(response.data);
        }
      }
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data ? error?.response?.data : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
    }
    setLoading(false);
  }, [user]);

  useEffect(() => {
    handleLoadMessages();
  }, [handleLoadMessages]);

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  // const findExpandedMessageByMessageLogID = (messageID: number) => {
  //   return expandMessages.find((message: IExpandMessages) => message.messageID === messageID)
  // }

  // filtro por numero de vôo
  // const filterMessagesByFlightAndDate = async (e: any) => {
  //   try {
  //     e.preventDefault();
  //     setLoading(true);
  //     //let userId = String(user?.usuarioID);
  //     const response = await getMessage(valueFlightNum, valueDepartureDate);

  //     if (response && response.data) {
  //       setMessages(response.data);
  //     }
  //     else {
  //       setMessages([]);
  //     }
  //   }
  //   catch (error) {
  //     if (error?.response?.status === 401) {
  //       const message = t('expiredToken');
  //       setOpen({ open: true, severity: 'error', message: String(message) });
  //     }
  //     else {
  //       const message = error?.response?.data ? error?.response?.data : error;
  //       setOpen({ open: true, severity: 'error', message: String(message) });
  //     }
  //   }
  //   setLoading(false);
  // }

  const application = process.env.REACT_APP_APPLICATION === undefined ? '' : process.env.REACT_APP_APPLICATION;


  // Colunas da tabela  
  const columns: GridColDef[] = [
    {
      field: 'NumeroConhecimento',
      headerName: t('number'),
      width: 160,
      renderCell: params => {
        if (params.row.Tipo.includes("FFM")) {
          return <Link to={`${application}/insertflightmanifest/${params.row.id}/${params.value}`} className={classes.link} >{params.value}</Link>
        }
        else if (params.row.Tipo.includes("FWB")) {
          return <Link to={`${application}/insertdocumentfreightwaybill/${params.row.id}/${params.value}`} className={classes.link} >{params.value}</Link>
        }
        else if (params.row.Tipo.includes("FZB")) {
          return <Link to={`${application}/insertdocumenthousewaybill/${params.row.id}/${params.value}`} className={classes.link} >{params.value}</Link>
        }
        else if (params.row.Tipo.includes("FHL")) {
          return <Link to={`${application}/inserthousemanifest/${params.row.id}/${params.value}`} className={classes.link} >{params.value}</Link>
        }
      },    
    },
    {
      field: 'Tipo',
      headerName: t('type'),
      width: 85,
      valueFormatter: (params: any) => {
        switch (true) {
          case params?.value.includes('FZB'):
            return 'FZB';
          case params?.value.includes('FHL'):
            return "FHL";
          case params?.value.includes('FWB'):
            return "FWB";
          case params?.value.includes('FFM'):
            return "FFM";
          default:
            return "";
        }
      }
    },
    {
      field: 'Mensagem',
      headerName: t('message'),
      renderCell: (params: GridCellParams) => <>
        <GridCellExpand
          value={params.value ? params.value.toString() : ''}
          width={params.colDef.width}
        />
      </>,
      width: 350,
    },
    {
      field: 'cgc_emp',
      headerName: 'CNPJ',
      width: 180,
    },
    {
      field: 'Status',
      headerName: t('status'),
      width: 120,
      valueFormatter: (params: any) => {
        switch (params?.value) {
          case 'Rejected':
            return t('rejected');
          case 'Processed':
            return t('processed');
          case 'Received':
            return t('received');
          case 'Error':
            return t('error');
          default:
            return '';
        }
      }
    },
    {
      field: 'Operacao',
      headerName: t('operation'),
      width: 115,
      valueFormatter: (params: any) => {
        switch (params?.value) {
          case 'Creation':
            return t('creation');
          case 'Update':
            return t('update');
          case 'Deletion':
            return t('deletion');
          default:
            return '';
        }
      }
    },
    {
      field: 'FromDate',
      headerName: t('date'),
      width: 110,
      valueFormatter: (params: any) => formatDate(params?.value)
    },
    {
      field: 'rucprotocolo',
      headerName: 'RUC/Protocolo',
      width: 320
    },
    // {
    //   field: '',
    //   headerName: 'Extrato',
    //   width: 100,
    //   renderCell: params => {
    //     if (params.row.Status === "Processed" && (params.row.Tipo.includes("FZB") || params.row.Tipo.includes("FWB"))) {
    //       return (
    //         <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    //           <CloudDownloadIcon style={{ cursor: loading ? 'default' : 'pointer', color: loading ? '#CCC' : "" }} className={classes.link} onClick={() => handleDownloadExtratoPDF(params.row.NumeroConhecimento, params.row.id, params.row.Cod_emp)} />
    //         </div>
    //       )
    //     }
    //   },
    // }
  ];

  // const [documentNumber, setDocumentNumber] = useState('');
  // const [id, setId] = useState('');
  // const [type, setType] = useState('');
  // const [cnpj, setCnpj] = useState('');

  // async function handleDownloadExtratoPDF(documentNumber: string, id: string, cod_emp: number) {

  //   setLoading(true);

  //   await downloadExtratoPDF(id, cod_emp)
  //     .then((response) => {
  //       const contentType = response.headers.get('content-type');
  //       if (contentType.includes('application/json')) {
  //         return response.json()
  //           .then(data => {
  //             setOpen({ open: true, severity: 'error', message: data.erro });
  //           })
  //           .catch(error => {
  //             console.error('Erro ao parsear JSON: ' + error);
  //           });
  //       } else if (contentType.includes('application/pdf')) {
  //         return response.blob()
  //           .then(blob => {
  //             const url = window.URL.createObjectURL(blob);

  //             const a = document.createElement('a');
  //             a.href = url;
  //             a.download = `extrato-conhecimento-${documentNumber}.pdf`;
  //             document.body.appendChild(a);
  //             a.click();

  //             window.URL.revokeObjectURL(url);
  //           })
  //           .catch(error => {
  //             console.error('Erro ao processar blob: ' + error);
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       setOpen({ open: true, severity: 'error', message: error });
  //     });

  //   setLoading(false);
  // }

  // function handleDeletionDocument(documentNumber: string, id: string, type: string) {

  //   setDocumentNumber(documentNumber);
  //   setId(id);
  //   setType(type);
  //   setCnpj(localStorage.getItem("company"));

  //   setHiddenConfirmModal(true);
  // }

  // async function deletionDocumentReq() {
  //   try {
  //     const response = await deleteDocument(documentNumber, id.toString(), type, cnpj);
  //   }
  //   catch { }
  //   handleLoadMessages();
  //   setHiddenConfirmModal(false);
  // }

  const handlePageChange = async (params) => {
    const { page } = params;
    if (page + 1 === Math.floor(messages.length / params.pageSize)) {

      let limitFirstAux = limitFirst + 1000;
      let limitLastAux = 1000;

      setLimitFirst(limitFirstAux);

      console.log({limitFirstAux, limitLastAux});

      const response = await getMessage(valueFlightNum, valueDepartureDate, '', limitFirstAux, limitLastAux);
      if (response && response.data) {
        const responseData = response.data;
        const margedMessages = messages.concat(responseData);
        setMessages(margedMessages);
      }
    }
  };

  return (
    <GridContainer>
      <GridItem xs={11}>
        <Card>
          <CardHeader color='primary' icon>
            <div className={classes.cardIcon}>
              <MessageIcon />
            </div>
            <h4 className={classes.cardTitle}>
              {t('messagesPanelTitle')}
            </h4>
          </CardHeader>
          <CardBody>
            <div className={classes.cardGrid}>
              <ThemeProvider theme={customTheme}>
                <button className={classes.button} onClick={handleLoadMessages} disabled={loading === false ? false : true}><RefreshIcon /></button>
                <DataGrid
                  autoHeight
                  loading={loading}
                  localeText={{
                    toolbarFilters: t('toolbarFilters'),
                    toolbarFiltersLabel: t('toolbarFiltersLabel'),
                    toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide'),
                    toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow'),
                    toolbarFiltersTooltipActive: (count) => count !== 1 ? `${count} ${t('toolbarFiltersTooltipActive')}s` : `${count} ${t('toolbarFiltersTooltipActive')}`,
                    rootGridLabel: t('rootGridLabel'),
                    noRowsLabel: t('noRowsLabel'),
                    errorOverlayDefaultLabel: t('errorOverlayDefaultLabel'),
                    toolbarDensity: t('toolbarDensity'),
                    toolbarDensityLabel: t('toolbarDensityLabel'),
                    toolbarDensityCompact: t('toolbarDensityCompact'),
                    toolbarDensityStandard: t('toolbarDensityStandard'),
                    toolbarDensityComfortable: t('toolbarDensityComfortable'),
                    toolbarColumns: t('toolbarColumns'),
                    toolbarColumnsLabel: t('toolbarColumnsLabel'),
                    toolbarExport: t('toolbarExport'),
                    toolbarExportLabel: t('toolbarExportLabel'),
                    toolbarExportCSV: t('toolbarExportCSV'),
                    columnsPanelTextFieldLabel: t('columnsPanelTextFieldLabel'),
                    columnsPanelTextFieldPlaceholder: t('columnsPanelTextFieldPlaceholder'),
                    columnsPanelDragIconLabel: t('columnsPanelDragIconLabel'),
                    columnsPanelShowAllButton: t('columnsPanelShowAllButton'),
                    columnsPanelHideAllButton: t('columnsPanelHideAllButton'),
                    filterPanelAddFilter: t('filterPanelAddFilter'),
                    filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
                    filterPanelOperators: t('filterPanelOperators'),
                    filterPanelOperatorAnd: t('filterPanelOperatorAnd'),
                    filterPanelOperatorOr: t('filterPanelOperatorOr'),
                    filterPanelColumns: t('filterPanelColumns'),
                    filterPanelInputLabel: t('filterPanelInputLabel'),
                    filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
                    filterOperatorContains: t('filterOperatorContains'),
                    filterOperatorEquals: t('filterOperatorEquals'),
                    filterOperatorStartsWith: t('filterOperatorStartsWith'),
                    filterOperatorEndsWith: t('filterOperatorEndsWith'),
                    filterOperatorIs: t('filterOperatorIs'),
                    filterOperatorNot: t('filterOperatorNot'),
                    filterOperatorAfter: t('filterOperatorAfter'),
                    filterOperatorOnOrAfter: t('filterOperatorOnOrAfter'),
                    filterOperatorBefore: t('filterOperatorBefore'),
                    filterOperatorOnOrBefore: t('filterOperatorOnOrBefore'),
                    columnMenuLabel: t('columnMenuLabel'),
                    columnMenuShowColumns: t('columnMenuShowColumns'),
                    columnMenuFilter: t('columnMenuFilter'),
                    columnMenuHideColumn: t('columnMenuHideColumn'),
                    columnMenuUnsort: t('columnMenuHideColumn'),
                    columnMenuSortAsc: t('columnMenuSortAsc'),
                    columnMenuSortDesc: t('columnMenuSortDesc'),
                    columnHeaderFiltersTooltipActive: (count) =>
                      count !== 1 ? `${count} ${t('toolbarFiltersTooltipActive')}s` : `${count} ${t('toolbarFiltersTooltipActive')}`,
                    columnHeaderFiltersLabel: t('columnHeaderFiltersLabel'),
                    columnHeaderSortIconLabel: t('columnHeaderSortIconLabel'),
                    footerRowSelected: (count) => count !== 1 ? `${count.toLocaleString()} ${t('footerRowSelecteds')}` : `${count.toLocaleString()} ${t('footerRowSelected')}`,
                    footerTotalRows: t('footerTotalRows'),

                  }}
                  className={classes.root}
                  rows={messages}
                  hideFooterRowCount={true}
                  hideFooterSelectedRowCount={true}
                  columns={columns}
                  rowsPerPageOptions={[]}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  onPageChange={handlePageChange}
                />
              </ThemeProvider>
            </div>
          </CardBody>

          {/* <Modal open={hiddenConfirmModal} onClose={handleCloseConfirmModal}>
            <Box className={classes.box}>
              <Box className={classes.boxTitle}>
                <Typography className={classes.titleModal}>
                  Excluir HAWB
                </Typography>
                <button className={classes.buttonExit} onClick={handleCloseConfirmModal}>
                  X
                </button>
              </Box>
              <Box className={classes.boxForm}>
              <p><b>Deseja excluir o conhecimento {documentNumber}: </b></p>
                <div className={classes.buttonContainer}>
                  <Button onClick={() => setHiddenConfirmModal(false)}>Cancelar</Button>
                  <Button onClick={() => deletionDocumentReq()}>
                    OK
                  </Button>
                </div>
              </Box>
            </Box>
          </Modal> */}
        </Card>
      </GridItem>
      {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
    </GridContainer>
  );
}

export default (withStyles(messagesPanelStyle)(MessagesPanel));
