import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import WarningSharpIcon   from '@material-ui/icons/WarningSharp';
import iconStyles from './styles';

function IconStatus({ ...props }) {
  const { classes } = props;

  let color = '';
  let text = '';
  
  if(props.status === 'Processed') {
    color = '#008C38'
    text = props.status;
  }
  else if(props.status === 'Rejected') {
    color = '#F52800'
    text = props.status;
  }
  else if(props.status === 'Not Sent') {
    color = '#F5AD00'
    text = props.status;
  }

  if(props.status === 'Processed') {
    
    return (
      <div>
        <div className={classes.container} style={{ borderColor: color, color:color }} >
        <CheckCircleOutlineIcon  className={classes.successIcon} style={{ color:color }}/>
        {text}
        </div>
      </div>
    );
  }
  else if(props.status === 'Rejected'){
    return (
      <div>
        <div className={classes.container} style={{ borderColor: color, color:color }} >
        <HighlightOffSharpIcon  className={classes.successIcon} style={{ color:color }}/>
        {text}
        </div>
      </div>
    );
  }
  else{
    return (
      <div>
        <div className={classes.container} style={{ borderColor: color, color:color }} >
          <WarningSharpIcon  className={classes.successIcon} style={{ color:color }}/>
          {text}
        </div>
      </div>
    );
  }
}

export default withStyles(iconStyles)(IconStatus);
