import { makeStyles } from '@material-ui/core/styles';
import { defaultFont, idataDefaultColor, defaultText } from 'assets/App';

const useStyles = makeStyles({
  container: {
    maxWidth: 350,
    marginTop: 5,
  },
  option: {
    ...defaultText,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  inputFocused: {
    ...defaultText
  },
  focused: {
    ...defaultFont,
    '& label.Mui-focused': {
      color: idataDefaultColor
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: idataDefaultColor
      },
    },
  },
  input: {
    ...defaultText
  }
});

export default useStyles;