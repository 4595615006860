import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { ThemeProvider } from '@material-ui/core/styles';
import { GridContainer, GridItem, Card, CardBody, Snackbar, CardHeader, CustomToolbar } from 'components';
import { GridColDef } from '@material-ui/data-grid';
import { internalControlDetail, customTheme } from './styles';
import { UserContext } from 'context';
import { getDocumenstByTypeId } from '../../services/api';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { formatDate } from '../../utils/formatDate';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import GridCellExpand from 'components/GridCellExpand/GridCellExpand';
import { GridCellParams } from '@material-ui/data-grid';

interface UrlParams {
  empresaId: string;
  usuarioId: string;
  dataInicial: string;
  dataFinal: string;
  aeroportodestino: string;
}

function InternalControlDetail(props: any) {

  const user = useContext(UserContext) as IUser;
  const { classes } = props;
  const { t } = useTranslation();
  //const [openModalInsert, setOpenModalInsert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });
  const [documentsList, setDocumentsList] = useState([]);
  const [initialDate, setInititalDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [totalDocuments, setTotalDocuments] = useState();
  const [userName, setUserName] = useState();
  const [companyName, setCompanyName] = useState();

  // useEffect(() => {
  //   setOpenModalInsert(true);
  // }, [])


  const { empresaId, usuarioId, dataInicial, dataFinal, aeroportodestino } = useParams<UrlParams>();

  const loadDocuments = useCallback(async () => {
    setLoading(true);

    setInititalDate(dataInicial);
    setFinalDate(dataFinal);

    try {
      if (user) {
        const response = await getDocumenstByTypeId(empresaId, usuarioId, dataInicial, dataFinal, aeroportodestino === undefined ? "" : aeroportodestino);
        if (response && response.data) {
          const data = response.data;

          setDocumentsList(data.Conhecimentos);
          setUserName(data.usuarioNome);
          setCompanyName(data.razaoSocial);
          setTotalDocuments(data.Total);
        }
      }
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data?.erro ? error?.response?.data?.erro : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
    }
    setLoading(false);

  }, [user]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  // const handleCloseModalInsert = () => {
  //   setOpenModalInsert(false);
  // };

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  const columns: GridColDef[] = [
    {
      field: 'fromdate',
      headerName: 'Data',
      flex: 1,
      valueFormatter: (params: any) => formatDate(params?.value)
    },
    {
      field: 'NumeroConhecimento',
      headerName: 'Número',
      flex: 1,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      valueFormatter: (params: any) => {
        switch (true) {
          case params?.value.includes('FZB'):
            return 'FZB';
          case params?.value.includes('FHL'):
            return "FHL";
          case params?.value.includes('FWB'):
            return "FWB";
          case params?.value.includes('FFM'):
            return "FFM";
          default:
            return "";
        }
      }
    },
    {
      field: 'aeroportoOrigem',
      headerName: 'Origem',
      flex: 1,
    },
    {
      field: 'aeroportoDestino',
      headerName: 'Destino',
      flex: 1,
    },
    {
      field: 'referencia',
      headerName: 'Referência',
      renderCell: (params: GridCellParams) => <>
        <GridCellExpand
          value={params.value ? params.value.toString() : ''}
          width={params.colDef.width}
        />
      </>,
      width: 150,
    },
    
  
    // },
    // {
    //   field: 'quantRetificacao',
    //   headerName: 'Quantidade Retifacados',
    //   flex: 1,
    // },
    // {
    //   field: 'quantTotal',
    //   headerName: 'Quantidade Total',
    //   flex: 1,
    // }
  ];


  return (

    <GridContainer>
      <GridItem xs={11}>
        <Card>

          <CardHeader color='primary' icon>
            <div className={classes.cardIcon}>
              <AssessmentIcon />
            </div>
            <h4 className={classes.cardTitle}>
              Envios
            </h4>

            <div style={{ display: 'flex' }}>
              <div className={classes.cardGrid}>
                {(initialDate !== '' && finalDate !== '') && (
                  <>
                    <div>
                      <span className={classes.spanSubTitleInfo}>Período: </span>
                      <span>{formatDate(initialDate)} a {formatDate(finalDate)}</span>
                    </div>
                  </>
                )}
                <div>
                  <span className={classes.spanSubTitleInfo}>Total de Envios: </span>
                  <span>{totalDocuments}</span>
                </div>
                <div>
                  <span className={classes.spanSubTitleInfo}>Usuário: </span>
                  <span>{userName}</span>
                </div>
                <div>
                  <span className={classes.spanSubTitleInfo}>Empresa: </span>
                  <span>{companyName}</span>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>

            <div className={classes.cardGrid}>
              <ThemeProvider theme={customTheme}>
                <DataGrid
                  columns={columns}
                  className={classes.root}
                  rows={documentsList}
                  loading={loading}
                  hideFooterSelectedRowCount={true}
                  pageSizeOptions={[]}
                  slots={{
                    toolbar: () => (
                      <CustomToolbar fileName={'historicoenvios'} />
                    ),
                  }}
                  localeText={{
                    toolbarFilters: t('toolbarFilters'),
                    toolbarFiltersLabel: t('toolbarFiltersLabel'),
                    toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide'),
                    toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow'),
                    toolbarFiltersTooltipActive: (count) => count !== 1 ? `${count} ${t('toolbarFiltersTooltipActive')}s` : `${count} ${t('toolbarFiltersTooltipActive')}`,
                    rootGridLabel: t('rootGridLabel'),
                    noRowsLabel: t('noRowsLabel'),
                    errorOverlayDefaultLabel: t('errorOverlayDefaultLabel'),
                    toolbarDensity: t('toolbarDensity'),
                    toolbarDensityLabel: t('toolbarDensityLabel'),
                    toolbarDensityCompact: t('toolbarDensityCompact'),
                    toolbarDensityStandard: t('toolbarDensityStandard'),
                    toolbarDensityComfortable: t('toolbarDensityComfortable'),
                    toolbarColumns: t('toolbarColumns'),
                    toolbarColumnsLabel: t('toolbarColumnsLabel'),
                    toolbarExport: t('toolbarExport'),
                    toolbarExportLabel: t('toolbarExportLabel'),
                    toolbarExportCSV: t('toolbarExportCSV'),
                    columnsPanelTextFieldLabel: t('columnsPanelTextFieldLabel'),
                    columnsPanelTextFieldPlaceholder: t('columnsPanelTextFieldPlaceholder'),
                    columnsPanelDragIconLabel: t('columnsPanelDragIconLabel'),
                    columnsPanelShowAllButton: t('columnsPanelShowAllButton'),
                    columnsPanelHideAllButton: t('columnsPanelHideAllButton'),
                    filterPanelAddFilter: t('filterPanelAddFilter'),
                    filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
                    filterPanelOperators: t('filterPanelOperators'),
                    filterPanelOperatorAnd: t('filterPanelOperatorAnd'),
                    filterPanelOperatorOr: t('filterPanelOperatorOr'),
                    filterPanelColumns: t('filterPanelColumns'),
                    filterPanelInputLabel: t('filterPanelInputLabel'),
                    filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
                    filterOperatorContains: t('filterOperatorContains'),
                    filterOperatorEquals: t('filterOperatorEquals'),
                    filterOperatorStartsWith: t('filterOperatorStartsWith'),
                    filterOperatorEndsWith: t('filterOperatorEndsWith'),
                    filterOperatorIs: t('filterOperatorIs'),
                    filterOperatorNot: t('filterOperatorNot'),
                    filterOperatorAfter: t('filterOperatorAfter'),
                    filterOperatorOnOrAfter: t('filterOperatorOnOrAfter'),
                    filterOperatorBefore: t('filterOperatorBefore'),
                    filterOperatorOnOrBefore: t('filterOperatorOnOrBefore'),
                    columnMenuLabel: t('columnMenuLabel'),
                    columnMenuShowColumns: t('columnMenuShowColumns'),
                    columnMenuFilter: t('columnMenuFilter'),
                    columnMenuHideColumn: t('columnMenuHideColumn'),
                    columnMenuUnsort: t('columnMenuHideColumn'),
                    columnMenuSortAsc: t('columnMenuSortAsc'),
                    columnMenuSortDesc: t('columnMenuSortDesc'),
                    columnHeaderFiltersTooltipActive: (count) =>
                      count !== 1 ? `${count} ${t('toolbarFiltersTooltipActive')}s` : `${count} ${t('toolbarFiltersTooltipActive')}`,
                    columnHeaderFiltersLabel: t('columnHeaderFiltersLabel'),
                    columnHeaderSortIconLabel: t('columnHeaderSortIconLabel'),
                    footerRowSelected: (count) => count !== 1 ? `${count.toLocaleString()} ${t('footerRowSelecteds')}` : `${count.toLocaleString()} ${t('footerRowSelected')}`,
                    footerTotalRows: t('footerTotalRows'),
                  }}
                />
              </ThemeProvider>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
    </GridContainer>
  )
}

export default (withStyles(internalControlDetail)(InternalControlDetail));
