import createStyles from '@material-ui/core/styles/createStyles';

import {
    defaultIlogIconColor,
} from 'assets/App';

const IconButtonStyle = (theme: any) => createStyles({
    iconButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            color: 'white',
            margin: '0px 15px 0',
            width: 'auto',
            height: 'auto',
            '& svg': {
                width: '30px',
                height: '24px',
                marginRight: '19px',
                marginLeft: '3px'
            },
            '& .fab,& .fas,& .far,& .fal,& .material-icons': {
                width: '30px',
                fontSize: '24px',
                lineHeight: '30px',
                marginRight: '19px',
                marginLeft: '3px'
            },
            '& > span': {
                justifyContent: 'flex-start',
                width: '100%'
            }
        },
        '&:hover': {
            outline: 'none',
            color: 'inherit',
            //backgroundColor: 'rgba(200, 200, 200, 0.2)',
            backgroundColor: 'none',
            boxShadow: 'none'
        }
    },
    Icon: {
        color: defaultIlogIconColor,     
        float: 'left',    
        opacity: '0.8',      
         
    },

});

export default IconButtonStyle;