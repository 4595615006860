import createStyles from '@material-ui/core/styles/createStyles';
import { title, idataDefaultColor, defaultText } from 'assets/App';

const userRegistration = () => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    minHeight: 300,
    width: '100%',
  },
  textAreaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 30px',
  },
  textAreaMargin: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 20,
    float: 'right',
    marginRight: 12,
    marginBottom: 8,
    color: idataDefaultColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textArea: {
    marginTop: 5,
    width: '100%',
    resize: 'none',
    padding: '7px',
    fontFamily: 'Arial'
  },
  textTitle: {
    ...title,
    textAlign: 'center'
  },
  buttonProgress: {
    color: idataDefaultColor,
    position: 'absolute',
  },
  // input: {
  //   color: '#000',
  //   width: '70%',
  //   float: 'left',
  //   padding: '0 10px',
  //   '&:after': {
  //     borderBottomColor: `${idataDefaultColor} !important`
  //   }
  // },
  inputTrue: {
    color: '#000',
    width: '70%',
    float: 'left',
    background: '#FC7D7D',
    padding: '0 10px',
    '&:after': {
      borderBottomColor: `${idataDefaultColor} !important`
    }
  },
  select: {
    color: '#000',
    width: '70%',
    float: 'left',
    '&:after': {
      borderBottomColor: `${idataDefaultColor} !important`
    }
  },
  messageContainer: {
    marginTop: 20,
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
  },
  hide: {
    overflow: 'hidden',
  },
  filename: {
    ...defaultText,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputFile: {
    cursor: 'pointer',
    display: 'block',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  text: {
    ...defaultText,
    display: 'flex',
    alignItems: 'center',
    // fontSize: '1rem',
  },
  check: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  icons: {
    cursor: 'pointer',
    fontSize: '2rem',
    marginLeft: '5px',
    "&:hover": {
      backgroundColor: 'rgb(204, 204, 204, 0.3)',
      borderRadius: '30px',
    },
  },

  inputGroup: {
    display: 'block',
    //justifyContent: 'space-between',
    width: '100%',
    padding: '10px',
    //marginTop: '20px'
  }, titleInputGroup: {
    fontSize: '16px',
    fontWeight: 'bolder'
  },
  separator: {
    width: '100%',
    height: '1px',
    background: '#ccc',
    margin: '20px 0'
  },
  inputs: {
    width: '100%',
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
    marginTop: '10px'
  },
  inputBox: {
    width: '100%',
    //maxWidth: '17rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '0.5rem',
    flex: '1 1 auto',
  },
  input: {
    fontSize: '14px',
    color: '#000',
    width: '100%',
    float: 'left',
    padding: '5px',
    '&:after': {
      borderBottomColor: `${idataDefaultColor} !important`
    }
  },
  inputSelect: {
    fontSize: '14px',
    color: '#000',
    width: '100%',
    float: 'left',
    '&:after': {
      borderBottomColor: `${idataDefaultColor} !important`
    }
  },

});

export default userRegistration;
