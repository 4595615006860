import React from 'react';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import textFieldStyle from './styles';


function RegularTextField({ ...props }) {
  const {
    classes,
    children,
    inputProps,
    ...rest
  } = props;
  
  return (
    <TextField {...rest}
      //focused={true}
      InputProps={{
        classes: {
          underline: classes.underline,
        },
        //props
      }}
      InputLabelProps={{
        classes: {
       root: classes.root
        }
      }}
    >
      {children}
    </TextField>
  );
}
export default withStyles(textFieldStyle)(RegularTextField)








