import { useState, useEffect, useCallback } from 'react';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import flightManifest from './styles';
import { getFlightManifest, listAirports, sendFlightManifest, getULD } from '../../services/api';
import { Input } from 'components/Input/Input';
import { formatNumber, formatNumberBR } from 'utils/formatNumber';
import { NumericFormat } from 'react-number-format';
import { formatDocumentNumber } from 'utils/formatDocumentNumber';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';

interface UrlParams {
  id: string;
  documentnumber: string;
}

interface Airports {
  id: number;
  Abreviatura: string;
}

interface ULD {
  id: number;
  uld: string;
}

interface ArrivalEventInterface {
  ArrivalDateTime: string;
  DepartureDateTime: string;
  AirportCode: string;
  AssociatedTransportCargo: Array<AssociatedTransportCargoInterface>;
}

interface AssociatedTransportCargoInterface {
  TypeCode: string;
  UtilizedUnitLoadTransportEquipment: UtilizedUnitLoadTransportEquipment;
  IncludedMasterConsignment: Array<IncludedMasterConsignmentInterface>;
};

interface UtilizedUnitLoadTransportEquipment {
  Identification: string;
  GrossWeightMeasure: number;
  GrossVolumeMeasure: number;
  PieceQuantity: number;
  CharacteristicCode: string;
  OperatingParty: string;
};

interface IncludedMasterConsignmentInterface {
  GrossWeightMeasure: number;
  GrossVolumeMeasure: number;
  TotalPieceQuantity: number;
  SummaryDescription: string;
  TransportSplitDescription: string;
  MasterNumber: string;
  OriginLocation: string;
  FinalDestinationLocation: string;
}

function FlightManifest(props: any) {
  const location = useLocation();
  const { classes } = props;
  const { t } = useTranslation();

  const [airportsList, setAirportsList] = useState();
  const [airportsListWithUTC, setAirportsListWithUTC] = useState<Airports[]>([]);
  const [uldList, setultList] = useState<ULD[]>([]);

  const [flightNumber, setFlightNumber] = useState('');
  const [aircraftIdentification, setAircraftIdentification] = useState('');

  const [departureFlightDate, setDepartureFlightDate] = useState('');

  const [weightCode, setWeightCode] = useState('');
  const [volumeCode, setVolumeCode] = useState('');

  const [departureAirport, setDepartureAirport] = useState('');
  const [departureDateTypeCode, setDepartureDateTypeCode] = useState('A');

  const [grossWeightManifestTotal, setGrossWeightManifestTotal] = useState('');
  const [grossVolumeManifestTotal, setGrossVolumeManifestTotal] = useState('');
  const [totalPieceManifestTotal, setTotalPieceManifestTotal] = useState('');


  const [arrivalEvents, setArrivalEvents] = useState<ArrivalEventInterface[]>([]);
  // const [associatedTransportCargo, setAssociatedTransportCargo] = useState<ArrivalEventInterface[]>([]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });

  const loadAirports = useCallback(async () => {
    const response = await listAirports();
    const airports = response?.data.filter((airport) => airport.UTC != null);
    setAirportsListWithUTC(airports);
    //setAirportsList(response?.data);


    const newArray = response.data.map((objeto) => {
      objeto.label = `${objeto.Abreviatura} ${objeto.Nome === null ? '' : ' - ' + objeto.Nome}`;
      objeto.value = objeto.Abreviatura;
      return objeto;
    });

    setAirportsList(newArray);
  }, []);

  const loadULDs = useCallback(async () => {
    const response = await getULD();
    setultList(response?.data);
  }, []);

  const { id, documentnumber } = useParams<UrlParams>();


  useEffect(() => {
    if (id) {
      loadDocument(id, documentnumber);
    }

    loadAirports();
    loadULDs();
    handleAddArrivalEvents();
  }, [location]);


  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      setOpen({ open: false, severity: '', message: '' });
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    const newArrivalEvents = arrivalEvents.map((arrival) => {
      const newTransportCargo = arrival.AssociatedTransportCargo.map((transportCargo: AssociatedTransportCargoInterface) => {

        const newMasterConsignment = transportCargo.IncludedMasterConsignment.map((masterConsignmet: IncludedMasterConsignmentInterface) => {
          return {
            ...masterConsignmet,
            GrossWeightMeasure: formatNumber(masterConsignmet.GrossWeightMeasure),
            GrossVolumeMeasure: formatNumber(masterConsignmet.GrossVolumeMeasure),
            TotalPieceQuantity: formatNumber(masterConsignmet.TotalPieceQuantity),
            FinalDestinationLocation: masterConsignmet.FinalDestinationLocation.value,
            OriginLocation: masterConsignmet.OriginLocation.value,
          }
        });

        return {
          ...transportCargo,

          UtilizedUnitLoadTransportEquipment: transportCargo.TypeCode === "BLK" ? {} :
            {
              ...transportCargo.UtilizedUnitLoadTransportEquipment,
              GrossWeightMeasure: formatNumber(transportCargo.UtilizedUnitLoadTransportEquipment.GrossWeightMeasure),
              GrossVolumeMeasure: formatNumber(transportCargo.UtilizedUnitLoadTransportEquipment.GrossVolumeMeasure),
              PieceQuantity: formatNumber(transportCargo.UtilizedUnitLoadTransportEquipment.PieceQuantity),
            },
          IncludedMasterConsignment: newMasterConsignment,
        }
      });

      return {
        ...arrival,
        ArrivalDateTime: arrival.ArrivalDateTime.length === 19 ? arrival.ArrivalDateTime : arrival.ArrivalDateTime + ":00",
        DepartureDateTime: arrival.DepartureDateTime.length === 19 ? arrival.DepartureDateTime : arrival.DepartureDateTime + ":00",
        AssociatedTransportCargo: newTransportCargo
      };
    })

    const flightManifest = {
      IssuerCNPJ: localStorage.getItem("company"),
      LogisticsTransportMovement: {
        FlightNumber: flightNumber,
        TotalGrossWeightMeasure: formatNumber(grossWeightManifestTotal),
        WeightCode: weightCode === "KG" ? "KGM" : "LBR",
        TotalGrossVolumeMeasure: formatNumber(grossVolumeManifestTotal),
        VolumeCode: volumeCode === "M3" ? "MTQ" : "CMQ",
        TotalPieceQuantity: formatNumber(totalPieceManifestTotal),
        AircraftRegistration: aircraftIdentification,
        DepartureEvent: {
          OccurrenceDateTime: departureFlightDate.length === 19 ? departureFlightDate : departureFlightDate + ":00",
          AirportCode: departureAirport,
          DateTypeCode: departureDateTypeCode
        },
      },
      ArrivalEvent: newArrivalEvents
    };


    try {
      const response = await sendFlightManifest(flightManifest);
      setOpen({ open: true, severity: 'success', message: response?.data.Mensagem });
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else if (error?.name === 'Error') {
        const message = error?.response?.data?.erro ? error?.response?.data?.erro : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = t('networkError');
        setOpen({ open: true, severity: 'error', message: message });
      }
    }

    setLoading(false);
  }

  const loadDocument = async (id: string, documentnumber: string) => {
    const response = await getFlightManifest(id, documentnumber);

    const data = response?.data;

    const logisticsTransportMovement = data?.LogisticsTransportMovement;

    setFlightNumber(logisticsTransportMovement?.FlightNumber);
    setAircraftIdentification(logisticsTransportMovement?.AircraftRegistration);
    setGrossWeightManifestTotal(formatNumberBR(logisticsTransportMovement?.TotalGrossWeightMeasure));
    setGrossVolumeManifestTotal(formatNumberBR(logisticsTransportMovement?.TotalGrossVolumeMeasure));
    setTotalPieceManifestTotal(formatNumberBR(logisticsTransportMovement?.TotalPieceQuantity));
    setWeightCode(logisticsTransportMovement?.WeightCode === "KGM" ? "KG" : "LB");
    setVolumeCode(logisticsTransportMovement?.VolumeCode === "MTQ" ? "M3" : "CM3");
    setDepartureFlightDate(logisticsTransportMovement?.DepartureEvent?.OccurrenceDateTime);

    setDepartureDateTypeCode(logisticsTransportMovement?.DepartureEvent?.DateTypeCode);
    setDepartureAirport(logisticsTransportMovement?.DepartureEvent?.AirportCode);

    //Formatar campos de número
    const arrivalEventList = data?.ArrivalEvent.map((arrival: ArrivalEventInterface) => {
      const newTransportCargo = arrival.AssociatedTransportCargo.map((transportCargo: AssociatedTransportCargoInterface) => {
        const newMasterConsignment = transportCargo.IncludedMasterConsignment.map((masterConsignmet: IncludedMasterConsignmentInterface) => {
          return {
            ...masterConsignmet,
            GrossWeightMeasure: formatNumberBR(masterConsignmet.GrossWeightMeasure),
            GrossVolumeMeasure: formatNumberBR(masterConsignmet.GrossVolumeMeasure),
            TotalPieceQuantity: formatNumberBR(masterConsignmet.TotalPieceQuantity),
            OriginLocation: { label: masterConsignmet.OriginLocation, value: masterConsignmet.OriginLocation },
            FinalDestinationLocation: { label: masterConsignmet.FinalDestinationLocation, value: masterConsignmet.FinalDestinationLocation },
          }
        });


        return {
          ...transportCargo,
          UtilizedUnitLoadTransportEquipment: transportCargo.TypeCode === "BLK" || transportCargo.TypeCode === "NIL" ? {} :
            {
              ...transportCargo.UtilizedUnitLoadTransportEquipment,
              GrossWeightMeasure: formatNumberBR(transportCargo.UtilizedUnitLoadTransportEquipment.GrossWeightMeasure),
              GrossVolumeMeasure: formatNumberBR(transportCargo.UtilizedUnitLoadTransportEquipment.GrossVolumeMeasure),
              PieceQuantity: formatNumberBR(transportCargo.UtilizedUnitLoadTransportEquipment.PieceQuantity),
            },
          IncludedMasterConsignment: newMasterConsignment,
        }
      });


      return {
        ...arrival,
        ArrivalDateTime: arrival.ArrivalDateTime.length === 19 ? arrival.ArrivalDateTime : arrival.ArrivalDateTime + ":00",
        DepartureDateTime: arrival.DepartureDateTime.length === 19 ? arrival.DepartureDateTime : arrival.DepartureDateTime + ":00",
        AssociatedTransportCargo: newTransportCargo
      };
    })

    setArrivalEvents(arrivalEventList);
  }

  function handleAddArrivalEvents() {
    let newfield = {
      ArrivalDateTime: '',
      DepartureDateTime: '',
      AirportCode: '',
      AssociatedTransportCargo: [],
    }
    setArrivalEvents([...arrivalEvents, newfield]);
  }

  function handleAddSetAssociatedTransportCargo(indexArrival: number) {

    const arrival = arrivalEvents;
    arrival[indexArrival]['AssociatedTransportCargo'].push({
      TypeCode: '',
      UtilizedUnitLoadTransportEquipment: {
        Identification: '',
        GrossWeightMeasure: 0,
        GrossVolumeMeasure: 0,
        PieceQuantity: 0,
        CharacteristicCode: '',
        OperatingParty: '',
      },
      IncludedMasterConsignment: []
    });

    const transportCargo = arrival[indexArrival]['AssociatedTransportCargo'];

    const novaLista = arrivalEvents.map((event, index) => {
      if (index === indexArrival) {
        return { ...event, AssociatedTransportCargo: transportCargo };
      }
      return event;
    });
    setArrivalEvents(novaLista);
  }

  function handleAddIncludedMasterConsignment(indexArrival: number, indexTransportCargo: number) {

    const arrival = arrivalEvents;
    const transportCargoList = arrival[indexArrival]['AssociatedTransportCargo'];

    transportCargoList[indexTransportCargo]['IncludedMasterConsignment'].push(
      {
        GrossWeightMeasure: 0,
        GrossVolumeMeasure: 0,
        TotalPieceQuantity: 0,
        SummaryDescription: '',
        TransportSplitDescription: '',
        MasterNumber: '',
        OriginLocation: '',
        FinalDestinationLocation: '',
      }
    );


    const transportCargo = transportCargoList.map((event, index) => {
      if (index === indexArrival) {
        return { ...event, IncludedMasterConsignment: event['IncludedMasterConsignment'] };
      }
      return event;
    });

    const arrivalList = arrivalEvents.map((event, index) => {
      if (index === indexArrival) {
        return { ...event, AssociatedTransportCargo: transportCargo };
      }
      return event;
    });
    setArrivalEvents(arrivalList);
  }

  const handleFormChange = (index: number, event: any) => {
    let data = [...arrivalEvents];
    // @ts-ignore: Unreachable code error
    data[index][event.target.name] = event.target.value;
    setArrivalEvents(data);
  }

  const handleFormChangeAssociatedTransportCargo = (index: number, indexTransportCargo: number, event: any) => {
    let data = [...arrivalEvents];
    const arrival = data[index]['AssociatedTransportCargo'];
    if (event.target.name === "TypeCode") {

      if (arrival[indexTransportCargo]['UtilizedUnitLoadTransportEquipment'] === null) {
        arrival[indexTransportCargo]['UtilizedUnitLoadTransportEquipment'] = {
          Identification: '',
          GrossWeightMeasure: 0,
          GrossVolumeMeasure: 0,
          PieceQuantity: 0,
          CharacteristicCode: '',
          OperatingParty: '',
        }
      }
      // @ts-ignore: Unreachable code error
      arrival[indexTransportCargo][event.target.name] = event.target.value;
    } else {
      const tranportEquipament = arrival[indexTransportCargo]['UtilizedUnitLoadTransportEquipment'];
      // @ts-ignore: Unreachable code error
      tranportEquipament[event.target.name] = event.target.value;
    }

    setArrivalEvents(data);
  }

  const handleFormChangeIncludedMasterConsignment = (index: number, indexTransportCargo: number, indexMasterConsignment: number, event: any) => {
    let data = [...arrivalEvents];
    const associatedTransportCargo = data[index]['AssociatedTransportCargo'];
    const includedMasterConsignment = associatedTransportCargo[indexTransportCargo]['IncludedMasterConsignment'];


    let inputValue = event.target.value;
    if (event.target.name === "MasterNumber") {
      inputValue = formatDocumentNumber(inputValue);
    }
    // @ts-ignore: Unreachable code error
    includedMasterConsignment[indexMasterConsignment][event.target.name] = inputValue;
    setArrivalEvents(data);
  }

  function handleRemoveArrivalEvents(index: number) {
    let data = [...arrivalEvents];
    data.splice(index, 1);
    setArrivalEvents(data);
  }

  function handleRemoveAssociatedTransportCargo(indexArrival: number, indexTransportCargo: number) {
    const data = [...arrivalEvents];
    const associatedTransportCargoList = data[indexArrival]['AssociatedTransportCargo'];
    associatedTransportCargoList.splice(indexTransportCargo, 1);
    data[indexArrival]['AssociatedTransportCargo'] = associatedTransportCargoList;
    setArrivalEvents(data);
  }

  function handleRemoveIncludedMasterConsignment(indexArrival: number, indexTransportCargo: number, indexMasterConsignment: number) {
    const data = [...arrivalEvents];
    const associatedTransportCargoList = data[indexArrival]['AssociatedTransportCargo'];
    const includedMasterConsignmentList = associatedTransportCargoList[indexTransportCargo]['IncludedMasterConsignment'];
    includedMasterConsignmentList.splice(indexMasterConsignment, 1);

    associatedTransportCargoList[indexTransportCargo]['IncludedMasterConsignment'] = includedMasterConsignmentList;
    data[indexArrival]['AssociatedTransportCargo'] = associatedTransportCargoList;

    setArrivalEvents(data);
  }


  const createOption2 = (label: string, value: string) => ({
    label,
    value: value.replace(/\W/g, ''),
  });

  const createOption = (label: string) => ({
    label,
    value: label.replace(/\W/g, ''),
  });

  // const [valueDestinationAirport, setValueDestinationAirport] = useState();
  // const [destinationAirport, setDestinationAirport] = useState('');


  // function handleChangeDestinationAirport(event) {
  //   setValueDestinationAirport(event);
  //   setDestinationAirport(event?.value);
  // }

  function handleChangeAirport(index: number, indexTransportCargo: number, indexMasterConsignment: number, event: any, name: string) {
    let data = [...arrivalEvents];
    const newOption = createOption2(event.label, event.value);
    data[index]["AssociatedTransportCargo"][indexTransportCargo]["IncludedMasterConsignment"][indexMasterConsignment][name] = newOption;
    setArrivalEvents(data);
  }

  const handleCreateAirport = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setAirportsList((prev) => [...prev, newOption]);
    //setValueOriginAirport(newOption);
    //setOriginAirport(newOption?.value);
  };


  return (
    <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={9}>
          <Card>

            <span className={classes.textTitle}>Cadastro de FFM</span>
            <form onSubmit={handleSubmit}>
              <CardBody className={classes.container}>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Voo</span>
                  <div className={classes.inputs}>
                    <Input title="Voo" required={true} classes={classes} value={flightNumber} onchange={setFlightNumber} maxlength={6} />

                    <Input title="Identificação da Aeronave" required={true} classes={classes} value={aircraftIdentification} onchange={setAircraftIdentification} />
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Quantidades</span>
                  <div className={classes.inputs}>
                    <Input numeric title="Peso Bruto Total (KG)" required={true} classes={classes} value={grossWeightManifestTotal} onchange={setGrossWeightManifestTotal} decimalScale={3} />
                    <Input numeric title="Volume Total (m3)" required={true} classes={classes} value={grossVolumeManifestTotal} onchange={setGrossVolumeManifestTotal} decimalScale={3} />
                    <Input numeric title="Quantidade de Peças" required={true} classes={classes} value={totalPieceManifestTotal} onchange={setTotalPieceManifestTotal} decimalScale={0} />
                  </div>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <span>Unidade Peso</span>
                      <select name="OriginLocation" className={classes.input} value={weightCode} onChange={e => setWeightCode(e.target.value)} required >
                        <option></option>
                        <option key="KGM" value="KG">KG</option>
                        <option key="LBR" value="LB">LB</option>
                      </select>
                    </div>
                    <div className={classes.inputBox}>
                      <span>Unidade Volume</span>
                      <select name="OriginLocation" className={classes.input} value={volumeCode} onChange={e => setVolumeCode(e.target.value)} required >
                        <option></option>
                        <option value="M3">M3</option>
                        <option value="CM3">CM3</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Partida</span>
                  <div className={classes.inputs}>
                    <Input title="Data da Partida" required={true} classes={classes} value={departureFlightDate} onchange={setDepartureFlightDate} type="datetime-local" />

                    <div className={classes.inputBox}>
                      <span>Data Efetiva/Prevista</span>
                      <select name="DepartureDateTypeCode" className={classes.input} value={departureDateTypeCode} onChange={e => setDepartureDateTypeCode(e.target.value)} required >
                        <option key="A" value="A">Efetiva</option>
                        <option key="S" value="S">Prevista</option>
                      </select>
                    </div>

                    <div className={classes.inputBox}>
                      <span>Aeroporto de Partida</span>
                      <select name="OriginLocation" className={classes.input} value={departureAirport} onChange={e => setDepartureAirport(e.target.value)} required >
                        <option></option>
                        {airportsListWithUTC.map((airport) => (
                          <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Chegadas</span>
                  <span className={classes.spanLink} onClick={handleAddArrivalEvents}>
                    <Tooltip title="Incluir Evento Chegada">
                      <AddIcon />
                    </Tooltip>
                  </span>
                </div>

                {arrivalEvents.map((input: any, index: any) => {
                  return (
                    <div key={index} className={classes.inputGroup}>
                      <span className={classes.titleInputGroup}>Chegada {index + 1}</span>
                      <span className={classes.trash} onClick={() => handleRemoveArrivalEvents(index)} >
                        <Tooltip title="Excluir Evento Chega">
                          <DeleteIcon />
                        </Tooltip>
                      </span>
                      <div className={classes.inputs}>

                        <div className={classes.inputBox}>
                          <span>Data de Chegada</span>
                          <input name="ArrivalDateTime" value={input.ArrivalDateTime} className={classes.input} placeholder="0.00" onChange={event => handleFormChange(index, event)} type="datetime-local" />
                        </div>

                        <div className={classes.inputBox}>
                          <span>Data de Partida</span>
                          <input name="DepartureDateTime" value={input.DepartureDateTime} className={classes.input} onChange={event => handleFormChange(index, event)} type="datetime-local" />
                        </div>

                        <div className={classes.inputBox}>
                          <span>Aeroporto</span>
                          <select name="AirportCode" className={classes.input} value={input.AirportCode} onChange={event => handleFormChange(index, event)} required >
                            <option></option>
                            {airportsListWithUTC.map((airport) => (
                              <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div style={{ borderLeft: '1px solid #ccc' }}>
                        <div className={classes.inputGroup}>
                          <span className={classes.titleInputGroup}>Equipamentos</span>
                          <span className={classes.spanLink} onClick={() => handleAddSetAssociatedTransportCargo(index)}>
                            <Tooltip title="Incluir Equipamento">
                              <AddIcon />
                            </Tooltip>
                          </span>
                        </div>

                        {input['AssociatedTransportCargo'].map((transportCargo: any, indexTransportCargo: any) => (
                          <>
                            <div key={indexTransportCargo} className={classes.inputGroup} style={{ marginTop: '20px' }}>
                              <span className={classes.titleInputGroup}>Equipamento {indexTransportCargo + 1}</span>

                              {transportCargo['IncludedMasterConsignment'].length === 0 && (
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                  <span style={{ fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FF5151', padding: '3px 5px', borderRadius: '10px' }}><WarningIcon /> Equipamento {indexTransportCargo + 1} não possui conhecimentos</span>
                                </div>
                              )}

                              <span className={classes.trash} onClick={() => handleRemoveAssociatedTransportCargo(index, indexTransportCargo)}>
                                <Tooltip title="Excluir Equipamento">
                                  <DeleteIcon />
                                </Tooltip>
                              </span>

                              <div className={classes.inputs}>
                                <div className={classes.inputBox}>
                                  <span>Tipo Equipamento</span>
                                  <select name="TypeCode" className={classes.input} value={transportCargo.TypeCode} onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)} required >
                                    <option></option>
                                    <option value="BLK">BLK</option>
                                    <option value="ULD">ULD</option>
                                  </select>
                                </div>

                                {transportCargo.TypeCode === "ULD" && <>
                                  <div className={classes.inputBox}>
                                    <span>Identificação</span>
                                    <input name="Identification" value={transportCargo.UtilizedUnitLoadTransportEquipment.Identification} className={classes.input} onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)} type='text' />
                                  </div>
                                  <div className={classes.inputBox}>
                                    <span>Tipo da ULD</span>
                                    <select name="CharacteristicCode" className={classes.input} value={transportCargo.UtilizedUnitLoadTransportEquipment.CharacteristicCode} onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)} required >
                                      <option></option>
                                      {uldList.map((uld) => (
                                        <option key={uld?.id} value={uld?.ULD}>{uld?.ULD}</option>
                                      ))}
                                    </select>
                                  </div>

                                </>}
                              </div>

                              {transportCargo.TypeCode === "ULD" && <>
                                <div className={classes.inputs}>

                                  <div className={classes.inputBox}>
                                    <span>{`Peso Bruto (${weightCode})`}</span>
                                    {/* <input name="GrossWeightMeasure" value={transportCargo.UtilizedUnitLoadTransportEquipment.GrossWeightMeasure} className={classes.input} placeholder="0.00" onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)} type='text' /> */}

                                    <NumericFormat
                                      name="GrossWeightMeasure"
                                      value={transportCargo.UtilizedUnitLoadTransportEquipment.GrossWeightMeasure}
                                      className={classes.inputNumericFormat}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={3}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)}
                                      placeholder='0,000'
                                    />
                                  </div>

                                  <div className={classes.inputBox}>
                                    <span>{`Volume (${volumeCode})`}</span>
                                    <NumericFormat
                                      name="GrossVolumeMeasure"
                                      value={transportCargo.UtilizedUnitLoadTransportEquipment.GrossVolumeMeasure}
                                      className={classes.inputNumericFormat}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={3}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)}
                                      placeholder='0,000'
                                    />
                                    {/* <input name="GrossVolumeMeasure" value={transportCargo.UtilizedUnitLoadTransportEquipment.GrossVolumeMeasure} className={classes.input} placeholder="0.00" onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)} type='text' /> */}
                                  </div>

                                  <div className={classes.inputBox}>
                                    <span>Total de Peças</span>
                                    <NumericFormat
                                      name="PieceQuantity"
                                      value={transportCargo.UtilizedUnitLoadTransportEquipment.PieceQuantity}
                                      className={classes.inputNumericFormat}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={0}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)}
                                      placeholder='0'
                                    />
                                    {/* <input name="PieceQuantity" value={transportCargo.UtilizedUnitLoadTransportEquipment.PieceQuantity} className={classes.input} onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)} type='text' /> */}
                                  </div>

                                  <div className={classes.inputBox}>
                                    <span>Companhia Responsável</span>
                                    <input name="OperatingParty" value={transportCargo.UtilizedUnitLoadTransportEquipment.OperatingParty} className={classes.input} onChange={event => handleFormChangeAssociatedTransportCargo(index, indexTransportCargo, event)} type='text' />
                                  </div>
                                </div>
                              </>}

                            </div>


                            <div style={{ paddingLeft: '15px' }}>
                              <div className={classes.inputGroup}>
                                <span className={classes.titleInputGroup}>Conhecimentos</span>

                                {transportCargo['IncludedMasterConsignment'].length === 0 && (
                                  <>
                                    <span className={classes.spanLink} onClick={() => handleAddIncludedMasterConsignment(index, indexTransportCargo)}>
                                      <Tooltip title="Incluir Conhecimento">
                                        <AddIcon />
                                      </Tooltip>
                                    </span>
                                  </>
                                )}
                              </div>

                              {transportCargo['IncludedMasterConsignment'].map((masterConsignment: any, indexMasterConsignment: any) => (
                                <div key={indexMasterConsignment} className={classes.inputGroup} style={{ borderLeft: '1px solid #ccc' }}>
                                  <span className={classes.titleInputGroup}>Conhecimento {indexMasterConsignment + 1}</span>
                                  <span className={classes.trash} onClick={() => handleRemoveIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment)} >
                                    <Tooltip title="Excluir Conhecimento">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </span>

                                  <div className={classes.inputs}>
                                    <div className={classes.inputBox}>
                                      <span>Número do Master</span>
                                      <input name="MasterNumber" value={masterConsignment.MasterNumber} className={classes.input} maxLength={12} onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)} type='text' required />
                                    </div>
                                  </div>
                                  <div className={classes.inputs}>
                                    <div className={classes.inputBox}>
                                      <span>{`Peso Bruto (${weightCode})`}</span>
                                      {/* <input name="GrossWeightMeasure" value={masterConsignment.GrossWeightMeasure} className={classes.input} placeholder="0.00" onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)} type='text' /> */}
                                      <NumericFormat
                                        name="GrossWeightMeasure"
                                        value={masterConsignment.GrossWeightMeasure}
                                        className={classes.input}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={3}
                                        fixedDecimalScale
                                        style={{ textAlign: 'right' }}
                                        allowNegative={false}
                                        onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)}
                                        placeholder='0,000'
                                      />
                                    </div>

                                    <div className={classes.inputBox}>
                                      <span>{`Volume (${volumeCode})`}</span>
                                      <NumericFormat
                                        name="GrossVolumeMeasure"
                                        value={masterConsignment.GrossVolumeMeasure}
                                        className={classes.input}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={3}
                                        fixedDecimalScale
                                        style={{ textAlign: 'right' }}
                                        allowNegative={false}
                                        onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)}
                                        placeholder='0,000'
                                      />
                                      {/* <input name="GrossVolumeMeasure" value={masterConsignment.GrossVolumeMeasure} className={classes.input} placeholder="0.00" onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)} type='text' /> */}
                                    </div>
                                    <div className={classes.inputBox}>
                                      <span>Total de Peças</span>
                                      <NumericFormat
                                        name="TotalPieceQuantity"
                                        value={masterConsignment.TotalPieceQuantity}
                                        className={classes.input}
                                        decimalScale={0}
                                        fixedDecimalScale
                                        style={{ textAlign: 'right' }}
                                        allowNegative={false}
                                        onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)}
                                        placeholder='0'
                                      />
                                      {/* <input name="TotalPieceQuantity" value={masterConsignment.TotalPieceQuantity} className={classes.input} placeholder="0.00" onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)} type='text' /> */}
                                    </div>
                                  </div>
                                  <div className={classes.inputs}>
                                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                                      <span>Descição</span>
                                      <input name="SummaryDescription" value={masterConsignment.SummaryDescription} className={classes.input} onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)} type='text' required />
                                    </div>
                                  </div>

                                  <div className={classes.inputs}>

                                    <div className={classes.inputBox}>
                                      <span>Parcialidade</span>
                                      <select name="TransportSplitDescription" className={classes.input} value={masterConsignment.TransportSplitDescription} onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)} required >
                                        <option></option>
                                        <option key="S" value="S">Remessa Completa diferentes ULDs (S)</option>
                                        <option key="T" value="T">Remessa Completa (T)</option>
                                        <option key="D" value="D">Remessa Parcial em diferentes ULDs (D)</option>
                                        <option key="P" value="P">Remessa Parcial (P)</option>
                                        <option key="M" value="M">Remessa em vôos diferentes (M)</option>=
                                      </select>
                                    </div>

                                    <div className={classes.inputBox}>
                                      <span>Aeroporto Origem</span>

                                      {/* <select name="OriginLocation" className={classes.input} value={masterConsignment.OriginLocation} onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)} required >
                                        <option></option>
                                        {airportsList.map((airport) => (
                                          <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                                        ))}
                                      </select> */}

                                      <CreatableSelect
                                        placeholder={t('selectAirport')}
                                        name="OriginLocation"
                                        isClearable
                                        onCreateOption={handleCreateAirport}
                                        onChange={event => handleChangeAirport(index, indexTransportCargo, indexMasterConsignment, event, "OriginLocation")}
                                        options={airportsList}
                                        value={masterConsignment.OriginLocation}
                                        className={classes.inputSelect}
                                        styles={customStyles}
                                      />
                                    </div>

                                    <div className={classes.inputBox}>
                                      <span>Aeroporto Destino</span>

                                      <CreatableSelect
                                        placeholder={t('selectAirport')}
                                        name="FinalDestinationLocation"
                                        isClearable
                                        onCreateOption={handleCreateAirport}
                                        onChange={event => handleChangeAirport(index, indexTransportCargo, indexMasterConsignment, event, "FinalDestinationLocation")}
                                        options={airportsList}
                                        value={masterConsignment.FinalDestinationLocation}
                                        className={classes.inputSelect}
                                        styles={customStyles}
                                      />
                                      {/* <select name="FinalDestinationLocation" className={classes.input} value={masterConsignment.FinalDestinationLocation} onChange={event => handleFormChangeIncludedMasterConsignment(index, indexTransportCargo, indexMasterConsignment, event)} required >
                                        <option></option>
                                        {airportsList.map((airport) => (
                                          <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                                        ))}
                                      </select> */}
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <div className={classes.inputGroup}>
                                {transportCargo['IncludedMasterConsignment'].length !== 0 && (
                                  <span className={classes.spanLink} onClick={() => handleAddIncludedMasterConsignment(index, indexTransportCargo)}>
                                    <Tooltip title="Incluir Conhecimento">
                                      <AddIcon />
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  )
                })}

                <div className={classes.buttonContainer}>
                  <Button
                    type='submit'
                    disabled={loading}
                  >
                    {t('send')}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </CardBody>
            </form >
          </Card>
        </GridItem>
        {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
      </GridContainer >
    </>
  );
}


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    border: '1px solid gray',
    borderRadius: '4px',
    minHeight: '1px',
    height: '32px',
    boxShadow: state.isFocused ? '0 0 0 2px lightblue' : 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'lightblue' : 'white',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  }),
  menu: (provided) => ({
    ...provided,
  }),
};


export default (withStyles(flightManifest)(FlightManifest));
