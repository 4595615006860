
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  idataBackground,
  whiteColor,
  grayColor,
  orangeIdataBoxShadow,
  defaultIlogColor,
} from 'assets/App';

import IconButtonStyle from 'components/Icon/styles';

const headerStyle = makeStyles((theme: Theme) => createStyles({
  ...IconButtonStyle(theme),
  appBar: {
    //backgroundColor: 'transparent',
    background: idataBackground,
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    //position: 'absolute', leva o header para a direita
    width: '100%',
    //paddingTop: '10px',
    zIndex: 1000, //1029
    color: '#555555',
    border: '0',
    //borderRadius: '3px',
    //padding: '10px 0',
    transition: 'all 150ms ease 0s',
    maxHeight: '60px',
    display: 'block'//deixa o search na esquerda
  },
  container: {
    ...containerFluid,
    minHeight: '60px',
  },
  flex: {
    flex: 1
  },

  selectInput: {
    fontSize: '14px',
    color: '#000',
    float: 'left',
    padding: '5px',
    borderRadius: '5px'
  },

  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    marginLeft: '20px',
    marginRight: '20px',
    //margin: '0 !important',
    '&:hover,&:focus': {
      background: 'transparent'
    }
  },
  logo: {
    padding: '10px 0px',
    margin: '0',
    display:'flex',
   // position: 'relative',
    zIndex: 4,
  },

  logoMini: {
    transition: 'all 300ms linear',
    opacity: 1,
    float: 'left',
    textAlign: 'center',
    width: '40px',
    display: 'inline-block',
    maxHeight: '30px',
    // marginLeft: '12px',
    marginRight: '2px',
    marginTop: '7px',
    color: 'inherit'
  },
  logoNormal: {   
    fontFamily: 'Righteous',
    lineHeight: '1.5em',
    fontSize: '17px',
    paddingLeft: '10px',
    color:defaultIlogColor
    //display: 'block',
    //width: '60px',
    //overflow: 'hidden',
    //maxHeight: '38px',
    //paddingLeft: '5px',
  },
  logoNormalSidebarMini: {
    opacity: '0',
    transform: 'translate3d(-25px, 0, 0)'
  },
  img: {
    width: '35px',
    height: '24px',
    verticalAlign: 'middle',
    border: '0'
  },
  primary: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: '#555555'
  },
  sidebarMiniIcon: {
    width: '20px',
    height: '17px'
  },

  //links  
  managerClasses: {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      marginLeft: '-6px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  dividerRoot: {
    margin: '5px !important'
  },
  //Profile
  customIconButton: {
    padding: '7px'
  },
  '@media (max-width:  959.95px)': {
    customIconButton: {
      padding: '12px'
    },
  },
  linkText: {
    zIndex: 4,
    ...defaultFont,
    fontSize: '14px',
    margin: '0 0 0 6px !important'
  },
  links: {
    width: '24px',
    height: '24px',
    zIndex: 4,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '30px',
      height: '30px',
      color: 'inherit',
      marginRight: '16px',
      marginLeft: '-5px'
    }
  },
  ...customDropdownStyle(theme)
}),
);

const customDropdownStyle = (theme: Theme) => createStyles({
  popperClose: {
    pointerEvents: 'none'
  },
  dropdown: {
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    top: '100%',
    zIndex: 1000,
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box'
  },
  menuList: {
    padding: '0'
  },
  pooperResponsive: {
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      zIndex: '1640',
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black'
    }
  },
  popperNav: {
    [theme.breakpoints.down('sm')]: {
      position: 'static !important',
      left: 'unset !important',
      top: 'unset !important',
      transform: 'none !important',
      willChange: 'unset !important',
      '& > div': {
        boxShadow: 'none !important',
        marginLeft: '56px',
        marginRight: '0rem',
        transition: 'none !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',
        padding: '0px !important',
        backgroundColor: 'transparent !important',
        '& ul li': {
          color: whiteColor + ' !important',
          margin: '10px 15px 0!important',
          padding: '10px 15px !important',
          '&:hover': {
            backgroundColor: 'hsla(0,0%,78%,.2)',
            boxShadow: 'none'
          }
        }
      }
    }
  },
  dropdownItem: {
    ...defaultFont,
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '3px',
    WebkitTransition: 'all 150ms linear',
    MozTransition: 'all 150ms linear',
    OTransition: 'all 150ms linear',
    MsTransition: 'all 150ms linear',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    //fontWeight: '400',
    lineHeight: '1.42857143',
    color: grayColor[8] + '!important',
    whiteSpace: 'nowrap',
    height: 'unset',
    minHeight: 'unset',
    '&:hover': {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...orangeIdataBoxShadow
    }
  },
  dropdownItemRTL: {
    textAlign: 'right'
  },
  blackHover: {
    '&:hover': {
      boxShadow:
        '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4)',
      backgroundColor: '#212121',
      color: '#fff'
    }
  },
  primaryHover: {
    '&:hover': {
      backgroundColor: defaultIlogColor,
      color: '#FFFFFF',
      ...orangeIdataBoxShadow
    }
  },
  dropdownDividerItem: {
    margin: '5px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '1px',
    overflow: 'hidden'
  },
  buttonIcon: {
    width: '20px',
    height: '20px'
  },
  caret: {
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '4px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent'
  },
  caretActive: {
    transform: 'rotate(180deg)'
  },
  caretDropup: {
    transform: 'rotate(180deg)'
  },
  dropdownHeader: {
    display: 'block',
    padding: '0.1875rem 1.25rem',
    fontSize: '0.75rem',
    lineHeight: '1.428571',
    color: '#777',
    whiteSpace: 'nowrap',
    fontWeight: 'inherit',
    marginTop: '10px',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      cursor: 'auto'
    }
  },
  link: {
    color: 'inherit'
  }
});


export default headerStyle;



































