import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/styles';
import SidebarStyle from './styles';


function AppDrawerNavItem(props: any) {

  const {
    children,
    depth,
    ItemIcon,
    href,
    activeRoute,
    invisible,
    miniName,
    miniActiveProp,
    miniActive,
    mobileOpen,
    color,
    classes,
    menuFontSize,
    name,     
  } = props;

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(oldOpen => !oldOpen);
  };

  const useStyles = makeStyles({
    menufontSize: {
      fontSize: menuFontSize + 'px',
    },
    buttonPaddingLeft: {
      paddingLeft: 6 * (3 + 2 * depth)
    },
    buttonTextNoIcon: {
      marginLeft: (30 - menuFontSize) + 'px',
    },
  });

  const localClasses = useStyles();

  if (invisible) { return null }

  function modalManifest() {
    if (href === '/manifastview') { 
      let modal = localStorage.getItem("modalManifest");
      modal = modal === '0' ? '1' : '0';
      localStorage.setItem("modalManifest", modal);
    }
    if (href === '/messagespanel') { 
      let modal = localStorage.getItem("modalMessagesPanel");
      modal = modal === '0' ? '1' : '0';
      localStorage.setItem("modalMessagesPanel", modal);
    }
      
  }

  if (href) {
    return (
      <ListItem button className={classes.item} disableGutters>
        <NavLink
          className={clsx(classes.itemLink,
            { [classes[color]]: activeRoute },
            { [classes.miniActiveWidth]: miniActive && miniActiveProp && !mobileOpen },
            {
              [localClasses.buttonPaddingLeft]: depth > 0 && !miniActiveProp ? localClasses.buttonPaddingLeft :
                depth > 0 && !miniActive ? localClasses.buttonPaddingLeft : //identar sub menus 
                  null
            })}
          to={href}
          onClick={modalManifest}
        >
          {/* Ícones */}
          {depth === 0 && ItemIcon &&
            <ListItemIcon
              className={
                clsx(
                  classes.leftIcon,
                  classes.Icon                         
                )
              }>
              {typeof ItemIcon === "string" ? (
                <Icon>{ItemIcon}</Icon>
              ) : (
                  <ItemIcon className={
                    clsx(
                      classes.leftIcon,
                      classes.Icon                  
                    )
                  } />
                )}
            </ListItemIcon>
          }
          {/* Textos */}
          <ListItemText
            // primary={!miniActiveProp ? name : miniActive ? miniName : name}

            primary={!miniActiveProp ? name : //se mobile true sempre mostrará o nome completo
              depth === 0 && miniActive ? name : //caso primeiro nivel do menu e miniActive true, mostra o nome completo para não bugar o sidebar
                miniActive ? miniName :  // se somente miniActive true, mostra o mini name
                  name
            }
            disableTypography={true}
            className={clsx(//localClasses.menufontSize,
              classes.itemText, {
              // [localClasses.buttonTextNoIcon]: !ItemIcon,
              [classes.itemTextMini]: miniActiveProp && miniActive,
            })}
          />
        </NavLink>
      </ListItem>
    );
  }

  const caret = classes.caret + ' ' + (open ? classes.caretActive : '');

  return (
    <ListItem button className={classes.item} //disableGutters

    >
      <NavLink
        className={classes.itemLink}
        // className={

        //   clsx(classes.button,
        //     {
        //       [localClasses.buttonPaddingLeft]: depth > 0 && !miniActive ? localClasses.buttonPaddingLeft :
        //         depth > 0 && !miniActiveProp ? localClasses.buttonPaddingLeft :    /*identar sub menus*/
        //           null
        //     })
        // }
        onClick={handleClick}
        to={'#'}
      >

        {/* label: clsx(localClasses.menufontSize, classes.buttonText, {
            [localClasses.buttonTextNoIcon]: !ItemIcon,
          }), */}
        {/*colocar icon no item do menu*/}
        {depth === 0 && ItemIcon &&
          <ListItemIcon
            className={classes.Icon}
          >
            {typeof ItemIcon === "string" ? (
              <Icon>{ItemIcon}</Icon>
            ) : (
                <ItemIcon />
              )}
          </ListItemIcon>
        }

        <ListItemText
          primary={!miniActiveProp ? name : //se mobile true sempre mostrará o nome completo
            depth === 0 && miniActive ? name : //caso primeiro nivel do menu e miniActive true, mostra o nome completo para não bugar o sidebar
              miniActive ? miniName :  // se somente miniActive true, mostra o mini name
                name
          }
          disableTypography={true}
          className={classes.itemText}
          // className={clsx(localClasses.menufontSize, classes.buttonText, {
          //   [localClasses.buttonTextNoIcon]: !ItemIcon,
          // })}

          secondary={
            <b
              className={!miniActiveProp ? caret :
                miniActive && !ItemIcon ? caret : //mini active true e opção do menu sem ícones mostra caret.
                  !miniActive ? caret : //miniActive false sempre mostra caret
                    undefined}
            />
          }
        />


      </NavLink>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {children}
      </Collapse>
    </ListItem >
  );
}

AppDrawerNavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number,
  href: PropTypes.string,
  activeRoute: PropTypes.bool,
  miniActiveProp: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string,
  ItemIcon: PropTypes.object,
  miniName: PropTypes.string,
  miniActive: PropTypes.bool,
  mobileOpen: PropTypes.bool,
  invisible: PropTypes.bool,
  color: PropTypes.string,
};

// const mapStateToProps = (state) => ({
//   menuFontSize: state.menu.menuFontSize,
// });

//export default withStyles(sidebarStyle)(connect(mapStateToProps)(AppDrawerNavItem))
export default withStyles(SidebarStyle)(AppDrawerNavItem)

