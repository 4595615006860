import { useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { history } from 'utils';
import { indexRoutes } from 'routes';
import { getPath } from 'utils';
import { getUserStorage, removeStorage } from 'services';
import { UserContext } from 'context';
import './app.css';

interface IToken {
  value: string;
  exp: number;
}

function App() {
  
  const [user, setUser] = useState(null);
  const checkToken = () => {
    let user = getUserStorage();
    if (user) {
      let token = JSON.parse(user);

      try {
        if (token && token['Value']) {
          const value = token['Value'];
          if (jwtDecode<IToken>(value).exp < Date.now().valueOf() / 1000) {
            removeStorage();
            history.push(getPath('Login'));
          }
          else {
            setUser(token);
          }
        }
      } catch (error) {
        history.push(getPath('Login'));
      }
    }
  }

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <UserContext.Provider value={user}>
      <Router history={history}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route path={prop.path} component={prop.component} key={key} />
          })}

        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
