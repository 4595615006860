import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import createStyles from '@material-ui/core/styles/createStyles';

const style = createStyles({
  grid: {
    margin: "0 -15px",
    width: "calc(100% + 30px)",
    margiTop: "20px",
    overflow: 'auto',
    maxHeight: '90vh',
    display: 'flex',
    justifyContent: 'center',
  }
});

function GridContainer(props: any) {
  const { classes, children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
