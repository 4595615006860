import { useState, useEffect, useContext, useCallback } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { GridContainer, GridItem, Card, CardBody, Snackbar, CardHeader } from 'components';
import { getUsers } from 'services';
import { UserContext } from 'context';
import { usersPanel } from './styles';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar, GridColDef } from '@material-ui/data-grid';
import PeopleIcon from '@material-ui/icons/People';

interface Users {
  usuarioid: number;
  name: string;
  login: string;
  token: string;
}

function UsersPanel(props: any) {
  const { classes } = props;
  const user = useContext(UserContext) as IUser;
  const [usersList, setUsersList] = useState<Users[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });

  const handleLoaduser = useCallback(async () => {

    setLoading(true);
    try {

      const response = await getUsers();
      if (response && response.data) {
        setUsersList(response.data);
      }
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data ? error?.response?.data : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
    }
    setLoading(false);
  }, [user]);

  useEffect(() => {
    handleLoaduser();
  }, [handleLoaduser]);

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };


  const columns: GridColDef[] = [

    {
      field: 'apelido_usu',
      headerName: 'Nome',
      flex: 2,
      renderCell: params => {
        return <Link to={`/user/${params.row.usuarioid}`} className={classes.link} >{params.value}</Link>
      },
    },
    {
      field: 'razaosocial_emp',
      headerName: 'Razão Social',
      flex: 3
    },
    {
      field: 'tokenHash',
      headerName: 'Token',
      flex: 2
    }

  ];

  // Os campos são completados pela string field, que são o mesmo do obj que contém as informações
  return (
    <GridContainer>
      <GridItem xs={11}>
        <Card>
          <CardHeader color='primary' icon>
            <div className={classes.cardIcon}>
              <PeopleIcon />
            </div>
            <h4 className={classes.cardTitle}>
              Usuários
            </h4>
          </CardHeader>
          <CardBody>

            <div className={classes.cardGrid}>
              <DataGrid
                pageSize={30}
                getRowId={(row) => row.usuarioid}
                autoHeight
                loading={loading}
                className={classes.root}
                rows={usersList}
                hideFooterRowCount={true}
                hideFooterSelectedRowCount={true}
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>

          </CardBody>

        </Card>
      </GridItem>
      {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
    </GridContainer>
  );
}

export default (withStyles(usersPanel)(UsersPanel));
