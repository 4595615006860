import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import pagesHeaderStyle from './styles';

function AuthHeader(props:any) {

  const { classes, color } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color
  });

  return (
    <AppBar position='static' className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <div className={classes.title} color='transparent'>
              iDATA Software
                </div>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <div  className={classes.title} color='transparent'>
              iDATA Software
              </div>
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger'])
};

export default withStyles(pagesHeaderStyle)(AuthHeader);
