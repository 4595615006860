
export const TOKEN_KEY = 'user';
export const COMPANY_KEY = 'company';

export const getUserStorage = () => localStorage.getItem(TOKEN_KEY);

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const saveStorage = (id: any) => {
  localStorage.setItem(TOKEN_KEY, id);
};

export function removeStorage() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(COMPANY_KEY);
};
