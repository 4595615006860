import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

interface MessageType {
  code: string;
  label: string;
}

const messagesTypesOrigin = [
  { code: 'IMPXFFM', label: 'Cargo-IMP Flight Manifest Message' },
  { code: 'IMPXFWB', label: 'Cargo-IMP Waybill Message' },
  // { code: 'CSVXFHL', label: 'CSV House Manifest' },
  // { code: 'CSVXFZB', label: 'CSV House Waybill Message' },
];

const messagesTypesDestination = [
  { code: 'XFFM', label: 'XML Flight Manifest Message (XFFM)' },
  { code: 'XFWB', label: 'XML Waybill Message (XFWB)' },
  { code: 'XFHL', label: 'XML House Manifest (XFHL)' },
  { code: 'XFZB', label: 'XML House Waybill Message (XFZB)' },
];

export default function MessagesSelect(props: any) {
  const { selectType, clear, choose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  let messagesTypes = choose === "origin" ? messagesTypesOrigin : messagesTypesDestination
  return (
    <Autocomplete
      key={clear}
      size='small'
      options={messagesTypes as MessageType[]}
      classes={{
        option: classes.option,
        root: classes.container,
        focused: classes.focused,
        inputFocused: classes.inputFocused
      }}
      onChange={(event, value) => selectType(value?.code)}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(option) => ( 
        <>
          {option.label}
        </>
      )}

      renderInput={(params) => (
        <TextField
          classes={{
            root: classes.root,
          }}
          {...params}
          label={t('selectMessage')}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: '', // disable autocomplete and autofill 
          }}
          InputLabelProps={{
            classes: {
              root: classes.input
            }
          }}
        />
      )}
    />
  );
}
