import { useState, useEffect, useCallback } from 'react';
import { Typography, Modal } from '@material-ui/core';
import { Button } from 'components';
import withStyles from '@material-ui/core/styles/withStyles';
import { consultManifest } from './styles';
import { customTheme } from './styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getConsultManifest, listAirports } from 'services';
import { ThemeProvider } from '@material-ui/core/styles';
import { GridContainer, GridItem, Card, CardBody, CardHeader, Snackbar } from 'components';
import FlightIcon from '@material-ui/icons/Flight';
import IconStatus from './icons/IconStatus';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import { DataGrid, GridCellParams, GridToolbar } from '@material-ui/data-grid';
import { formatDecimalToBrFormat } from 'utils/formatNumber';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formatDate';
import GridCellExpand from './../../components/GridCellExpand/GridCellExpand';

interface IMessages {
  ID: number;
  ConhecimentoNumero: string;
  GrossVolumeMeasure: string;
  GrossWeightMeasure: string;
  Status: string;
}

interface Airports {
  id: number;
  Abreviatura: string;
}

function ConsultManifest(props: any) {

  const { classes } = props;
  const [airportsList, setAirportsList] = useState<Airports[]>([]);
  const [messages, setMessages] = useState<IMessages[]>([]);
  const [valueFlightNum, setValueFlightNum] = useState('');
  const [valueDepartureDate, setValueDepartureDate] = useState('');
  const [finalDestination, setFinalDestination] = useState('');
  const [formState, setformState] = useState(true);

  //const [numAWB, setValueNumAWB] = useState('');
  const [totalVolume, setValueTotalVolume] = useState('');
  const [totalPeso, setValueTotalPeso] = useState('');

  const [numAWBFFM, setValueNumAWBFFM] = useState('');
  const [totalVolumeFFM, setValueTotalVolumeFFM] = useState('');
  const [totalPesoFFM, setValueTotalPesoFFM] = useState('');

  const [notSentCount, setNotSentCount] = useState('');
  const [RejectedCountFFM, setRejectedCount] = useState('');
  const [processedCount, setProcessedCount] = useState('');

  const [flightNumber, setFlightNumber] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [manifestNumber, setManifestNumber] = useState('');


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);


  const [openErroMessage, setOpenErroMessage] = useState({ openErroMessage: false, severity: '', message: '' });

  let openString = localStorage.getItem("modalManifest");

  const handleCloseErro = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErroMessage({ openErroMessage: false, severity: '', message: '' });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await getConsultManifest(valueFlightNum, valueDepartureDate, finalDestination);

      if (response.data.length !== 1) {

        setValueNumAWBFFM(response.data.DadosConhecimentos.length);
        setValueTotalVolumeFFM(formatDecimalToBrFormat(response.data.DadosManifesto.TotalPieceQuantity));
        setValueTotalPesoFFM(formatDecimalToBrFormat(response.data.DadosManifesto.TotalGrossWeightMeasure));

        setNotSentCount(response.data.DadosGeraisEnviados.NotSentCount);
        setProcessedCount(response.data.DadosGeraisEnviados.ProcessedCount);
        setRejectedCount(response.data.DadosGeraisEnviados.RejectedCount);

        //setValueNumAWB(response.data.DadosGeraisEnviados.NumAWBFFM);
        setValueTotalVolume(formatDecimalToBrFormat(response.data.DadosGeraisEnviados.Quantidade));
        setValueTotalPeso(formatDecimalToBrFormat(response.data.DadosGeraisEnviados.Weight));

        setFlightNumber(response.data.DadosManifesto.FlightNumber);
        setDepartureDate(formatDate(response.data.DadosManifesto.DepartureDate));
        setManifestNumber(response.data.DadosManifesto.ManifestNumber);

        if (response && response.data.DadosConhecimentos) {
          setMessages(response.data.DadosConhecimentos);
        }

        response.status === 200 ? setformState(false) : setformState(true);


        if (response && response.data) {
          setValueDepartureDate('');
          setValueFlightNum('');
        }
      }
      else {
        if (response && response.data) {
          setMessages(response.data);
        }
        const message = response?.data;

        setOpenErroMessage({ openErroMessage: true, severity: 'error', message: String(message) });
      }
    }
    catch (error) {
      if (error?.name === 'Error') {
        const message = t('networkError');
        setOpenErroMessage({ openErroMessage: true, severity: 'error', message: String(message) });
      }
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpenErroMessage({ openErroMessage: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data ? error?.response?.data : error;
        setOpenErroMessage({ openErroMessage: true, severity: 'error', message: String(message?.erro) });
      }
    }
    setLoading(false);
  }

  const setListAirports = useCallback(async () => {
    const response = await listAirports();
    setAirportsList(response?.data);
  }, []);

  useEffect(() => {
    setListAirports();
  }, []);


  useEffect(() => {
    handleOpen();
  }, [openString]);

  const columns = [
    {
      field: 'ConhecimentoNumero',
      headerName: 'Conhecimento',
      width: 150,
      renderCell: params => {
        return <Link to={`/insertdocumentfreightwaybill/${params.row.DocumentId}/${params.value}`} className={classes.link} >{params.value}</Link>
      }
      //renderCell: (params: any) => <Link to={`/insertdocumentfreightwaybill/${params.row.id}/${params.value}`} className={classes.link} >{params.value}</Link>,
    },
    {
      field: 'GrossWeightMeasure',
      headerName: 'Peso Bruto',
      type: 'string',
      //flex: 1,
      width: 130,
      valueFormatter: (params: any) => formatDecimalToBrFormat(params?.value),
    },
    {
      field: 'PieceQuantity',
      headerName: 'Volume',
      type: 'string',
      //flex: 1,
      width: 130,
      valueFormatter: (params: any) => formatDecimalToBrFormat(params?.value),
    },
    {
      field: 'Status',
      headerName: 'Status',
      type: 'string',
      width: 150,
      renderCell: (params: any) => {
        return (
          <IconStatus status={params.row.Status} />
        );
      }
    },
    {
      field: 'Message',
      headerName: 'Mensagem',
      type: 'string',
      renderCell: (params: GridCellParams) => <>
        <GridCellExpand
          value={params.value ? params.value.toString() : ''}
          width={params.colDef.width}
        />
      </>,
      flex: 1,
    }
  ];

  return (
    <>
      <div>
        {formState ? (
          <div>
            <Button onClick={handleOpen}></Button>
            <Modal open={open} onClose={handleClose}>
              <Box className={classes.box}>
                <Box className={classes.boxTitle}>
                  <Typography className={classes.titleModal}>
                    {t('consultManifest')}
                  </Typography>
                  <button className={classes.buttonExit} onClick={handleClose}>
                    X
                  </button>
                </Box>
                <form onSubmit={handleSubmit}>
                  <Box className={classes.boxForm}>
                    {/* <div className={classes.textAreaContainer} >
                      <span className={classes.text}>{t('flightNumber')}</span>
                      <Input value={valueFlightNum} className={classes.input} placeholder="AB1234" onChange={e => { setValueFlightNum(e.target.value.toUpperCase()) }} required />
                    </div>

                    <div className={classes.textAreaContainer} >
                      <span className={classes.text}>{t('departureDate')}</span>
                      <Input value={valueDepartureDate} className={classes.input} type='date' onChange={e => { setValueDepartureDate(e.target.value) }} required />
                    </div> */}

                    <div className={classes.inputGroup}>
                      <div className={classes.inputs}>
                        <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                          <span>Número do Vôo</span>
                          <input value={valueFlightNum} onChange={e => { setValueFlightNum(e.target.value.toUpperCase()) }} className={classes.input} placeholder="XX1234" type='text' required />
                        </div>
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <div className={classes.inputs}>
                        <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                          <span>Data de Partida</span>
                          <input value={valueDepartureDate} type='date' onChange={e => { setValueDepartureDate(e.target.value) }} className={classes.input} placeholder="" required />
                        </div>
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <div className={classes.inputs}>
                        <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                          <span>Aeroporto de Destino</span>
                          <select className={classes.input} value={finalDestination} onChange={e => { setFinalDestination(e.target.value) }} required>
                            <option></option>
                            {airportsList.map((airport) => (
                              <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className={classes.buttonContainer}>
                      <Button type='submit'>{t('ok')}</Button>
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  </Box>
                </form>
              </Box>
            </Modal>
            {openErroMessage.openErroMessage && <Snackbar open={openErroMessage.openErroMessage} place="bc" icon severity={openErroMessage.severity} message={openErroMessage.message} close={handleCloseErro} />}
          </div>)
          : (
            <GridContainer>
              <GridItem xs={11}>
                <Card>

                  <CardHeader color='primary' icon>
                    <div className={classes.cardIcon}>
                      <FlightIcon />
                    </div>
                    <h4 className={classes.cardTitle} >
                      {t('consultManifest')}
                    </h4>
                  </CardHeader>

                  <CardBody>

                    <div style={{ display: 'flex' }}>
                      <div className={classes.cardGrid}>
                        <span className={classes.spanTotals}><b>Paramêtros da consulta:</b></span>
                        <div>
                          <span className={classes.spanSubTitleInfo}>Número do manifesto: </span>
                          <span>{manifestNumber}</span>
                        </div>
                        <div>
                          <span className={classes.spanSubTitleInfo}>Voo: </span>
                          <span>{flightNumber}</span>
                        </div>
                        <div>
                          <span className={classes.spanSubTitleInfo}>Data: </span>
                          <span>{departureDate}</span>
                        </div>
                      </div>

                      <div className={classes.cardGrid}>
                        <span className={classes.spanTitleInfo}><b>Valores totais:</b></span>
                        <div>
                          <span className={classes.spanSubTitleInfo}>{t('grossWeight')}: </span>
                          <span>{totalPesoFFM}</span>
                        </div>
                        <div>
                          <span className={classes.spanSubTitleInfo}>{t('volumes')}: </span>
                          <span>{totalVolumeFFM}</span>
                        </div>
                        <div>
                          <span className={classes.spanSubTitleInfo}>{t('awb')}: </span>
                          <span>{numAWBFFM}</span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.cardGrid}>
                      <ThemeProvider theme={customTheme}>
                        <DataGrid
                          autoHeight
                          loading={loading}
                          localeText={{
                            toolbarFilters: t('toolbarFilters'),
                            toolbarFiltersLabel: t('toolbarFiltersLabel'),
                            toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide'),
                            toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow'),
                            toolbarFiltersTooltipActive: (count) => count !== 1 ? `${count} ${t('toolbarFiltersTooltipActive')}s` : `${count} ${t('toolbarFiltersTooltipActive')}`,
                            noRowsLabel: t('noRowsLabel'),
                            errorOverlayDefaultLabel: t('errorOverlayDefaultLabel'),
                            toolbarDensity: t('toolbarDensity'),
                            toolbarDensityLabel: t('toolbarDensityLabel'),
                            toolbarDensityCompact: t('toolbarDensityCompact'),
                            toolbarDensityStandard: t('toolbarDensityStandard'),
                            toolbarDensityComfortable: t('toolbarDensityComfortable'),
                            toolbarColumns: t('toolbarColumns'),
                            toolbarColumnsLabel: t('toolbarColumnsLabel'),
                            toolbarExport: t('toolbarExport'),
                            toolbarExportLabel: t('toolbarExportLabel'),
                            toolbarExportCSV: t('toolbarExportCSV'),
                            columnsPanelTextFieldLabel: t('columnsPanelTextFieldLabel'),
                            columnsPanelTextFieldPlaceholder: t('columnsPanelTextFieldPlaceholder'),
                            columnsPanelDragIconLabel: t('columnsPanelDragIconLabel'),
                            columnsPanelShowAllButton: t('columnsPanelShowAllButton'),
                            columnsPanelHideAllButton: t('columnsPanelHideAllButton'),
                            filterPanelAddFilter: t('filterPanelAddFilter'),
                            filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
                            filterPanelOperators: t('filterPanelOperators'),
                            filterPanelOperatorAnd: t('filterPanelOperatorAnd'),
                            filterPanelOperatorOr: t('filterPanelOperatorOr'),
                            filterPanelColumns: t('filterPanelColumns'),
                            filterPanelInputLabel: t('filterPanelInputLabel'),
                            filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
                            filterOperatorContains: t('filterOperatorContains'),
                            filterOperatorEquals: t('filterOperatorEquals'),
                            filterOperatorStartsWith: t('filterOperatorStartsWith'),
                            filterOperatorEndsWith: t('filterOperatorEndsWith'),
                            filterOperatorIs: t('filterOperatorIs'),
                            filterOperatorNot: t('filterOperatorNot'),
                            filterOperatorAfter: t('filterOperatorAfter'),
                            filterOperatorOnOrAfter: t('filterOperatorOnOrAfter'),
                            filterOperatorBefore: t('filterOperatorBefore'),
                            filterOperatorOnOrBefore: t('filterOperatorOnOrBefore'),
                            columnMenuLabel: t('columnMenuLabel'),
                            columnMenuShowColumns: t('columnMenuShowColumns'),
                            columnMenuFilter: t('columnMenuFilter'),
                            columnMenuHideColumn: t('columnMenuHideColumn'),
                            columnMenuUnsort: t('columnMenuHideColumn'),
                            columnMenuSortAsc: t('columnMenuSortAsc'),
                            columnMenuSortDesc: t('columnMenuSortDesc'),
                            columnHeaderFiltersTooltipActive: (count) =>
                              count !== 1 ? `${count} ${t('toolbarFiltersTooltipActive')}s` : `${count} ${t('toolbarFiltersTooltipActive')}`,
                            columnHeaderFiltersLabel: t('columnHeaderFiltersLabel'),
                            columnHeaderSortIconLabel: t('columnHeaderSortIconLabel'),
                          }}
                          hideFooter={true}
                          hideFooterSelectedRowCount={true}
                          hideFooterPagination={true}
                          className={classes.root}
                          getRowId={(row) => row.ID}
                          rows={messages}
                          columns={columns}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                        />
                      </ThemeProvider>
                    </div>

                    <div className={classes.cardGrid}>
                      <ThemeProvider theme={customTheme}>
                        <div style={{ width: '100%', display: 'flex', fontWeight: 'bold', textAlign: 'left' }}>
                          <div style={{ width: '150px', padding: '16px' }}>TOTAL</div>
                          <div style={{ width: '130px', padding: '16px', color: totalPeso !== totalPesoFFM ? '#f00' : '#000' }}>{totalPeso}</div>
                          <div style={{ width: '130px', padding: '16px', color: totalPeso !== totalPesoFFM ? '#f00' : '#000' }}>{totalVolume}</div>
                          <div style={{ width: '150px', padding: '16px' }}></div>
                          <div style={{ width: '200px', padding: '16px' }}></div>

                        </div>
                      </ThemeProvider>
                    </div>

                    <ThemeProvider theme={customTheme}>
                      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar style={{ background: 'transparent' }}>
                              <CheckCircleOutlineIcon style={{ color: '#008C38' }} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={t('sentWithSuccess')} secondary={processedCount} />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar style={{ background: 'transparent' }}>
                              <HighlightOffSharpIcon style={{ color: '#F52800' }} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={t('reject')} secondary={RejectedCountFFM} />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar style={{ background: 'transparent' }}>
                              <WarningSharpIcon style={{ color: '#F5AD00' }} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={t('notSent')} secondary={notSentCount} />
                        </ListItem>
                      </List>

                    </ThemeProvider>

                  </CardBody>
                </Card>
              </GridItem>

            </GridContainer>
          )
        }
      </div>
    </>
  );
}
export default (withStyles(consultManifest)(ConsultManifest));