export function formatNumber(value: string) {
  value = value.toString();
  if (!value)
    return "0";

  var valorSemSimbolo = value.replaceAll('.', '').replace(',', '.');
  var valorDecimal = parseFloat(valorSemSimbolo);
  return valorDecimal;

  // const n = Number(valorDecimal.toLocaleString('en-US', { style: 'decimal' }));
  // if (n) {
  //   console.log("TRUE", n);
  // }
  // else {
  //   console.log("FALSE -", valorDecimal.toLocaleString('en-US', { style: 'decimal' }));
  //   console.log("FALSE", Number(valorDecimal.toLocaleString('en-US', { style: 'decimal' })));
  // }


  // return valorDecimal.toLocaleString('en-US', { style: 'decimal' });
}


export function formatNumberBR(value: number) {
  return value.toLocaleString('pt-BR', { style: 'decimal' });
}

export function formatDecimalToBrFormat(value: number) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
  }).format(value);

  return formatter;
}