import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextareaAutosize } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import clsx from 'clsx'; 
import MessagesSelect from './MessagesType';
import messagesConverterStyle from './styles';

function MessagesConverter(props: any) {
  const { classes } = props;
  const { t } = useTranslation();  
  const [messageTypesOrigin, setMessageTypeOrigin] = useState('');
  const [messageTypesDestination, setMessageTypeDestination] = useState('');
  const [valueTextOrigin, setValueTextOrigin] = useState('');
  const [valueTextDestination, setValueTextDestination] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      setOpen({ open: false, severity: '', message: '' });
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  const handleSubmit = async (e: any) => {
    setLoading(true)
    e.preventDefault();
    if(messageTypesOrigin === undefined || messageTypesDestination === undefined || messageTypesOrigin === '' || messageTypesDestination === '') {
      setOpen({ open: true, severity: 'error', message: t('warningMessageConvert') });
    } else {
      setValueTextDestination(valueTextOrigin);
    }
    setLoading(false)
  }
 
  return (
    <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <span className={classes.textTitle}>{t('messageConverterTitle')}</span>
            <form onSubmit={handleSubmit}>
              <CardBody className={classes.container}>
                <div className={classes.textAreaContainer} >
                  <span className={classes.text}>{t('origin')}</span>
                  <MessagesSelect clear={loading} choose='origin' selectType={(value: string) => setMessageTypeOrigin(value)} />

                  <TextareaAutosize aria-label="minimum height" rowsMin={10} className={classes.textArea} value={valueTextOrigin} onChange={e => { setValueTextOrigin(e.target.value) }} />
                </div>
                <div className={clsx([classes.textAreaContainer, classes.textAreaMargin])} >
                  <span className={classes.text}>{t('destination')} </span>
                  <MessagesSelect clear={loading} choose='destination' selectType={(value: string) => setMessageTypeDestination(value)} />

                  <TextareaAutosize aria-label="minimum height" rowsMin={10} className={classes.textArea} value={valueTextDestination} />
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    type='submit'
                    disabled={loading}
                  >
                    {t('converter')}
                  </Button>
                </div>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </CardBody>
            </form >
          </Card>
        </GridItem>
        {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
      </GridContainer>

    </>
  );
}

export default (withStyles(messagesConverterStyle)(MessagesConverter));

