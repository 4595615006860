import { createStyles } from '@material-ui/core/styles';


export const iconStyles = () => createStyles({  
  container:{
    background:'transparent',
    height:'30px',
    width:'100px',
    alignItems:'center',
    display: 'flex',
    verticalAlign: 'middle',
    gap: '10px'

  }
});


export default iconStyles;