import { useState, useEffect, useCallback } from 'react';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import houseManifest from './styles';
import { listAirports, listCountries, sendHouseManifest, getHouses, getHouseManifest } from '../../services/api';
import { formatDocumentNumber } from '../../utils/formatDocumentNumber';
import { formatNumber, formatNumberBR } from 'utils/formatNumber';
import { NumericFormat } from 'react-number-format';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@mui/material/Tooltip';
import { useParams, useLocation } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Typography, Modal } from '@material-ui/core';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';

interface UrlParams {
  id: string;
  documentnumber: string;
}

interface Airports {
  id: number;
  Abreviatura: string;
}

// interface Countries {
//   id: number;
//   Nome: string;
//   Sigla: string;
//   Nomeing: string;
// }

interface IncludedHouseConsignment {
  GrossWeightMeasure: number;
  TotalPieceQuantity: number;
  SummaryDescription: string;
  HouseNumber: string;
  OriginLocation: string;
  FinalDestinationLocation: string;
  DateOfIssueGreaterCurrent: boolean;
  //Agent: Agent;
}

// interface Agent {
//   CNPJ: string;
//   CountryId: string;
// }


function HouseManifest(props: any) {
  const location = useLocation();
  const { classes } = props;
  const { t } = useTranslation();


  //const [options, setOptions] = useState();

  const [openModal, setOpenModal] = useState(false);
  //const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseWarningModal = () => setHiddenWarningModal(false);
  const [trashHidden, setTrashHidden] = useState(false);


  const [airportsList, setAirportsList] = useState<Airports[]>([]);
  //const [countriesList, setCountriesList] = useState<Countries[]>([]);

  const [masterNumber, setMasterNumber] = useState('');
  const [includedTareGrossWeightMeasureMaster, setIncludedTareGrossWeightMeasureMaster] = useState('');
  const [totalPieceQuantityMaster, setTotalPieceQuantityMaster] = useState('');

  const [weightCode, setWeightCode] = useState('KG');

  const [departureAirport, setDepartureAirport] = useState('');
  const [arrivalAirport, setArrivalAirport] = useState('');

  const [includedHouseConsignment, setIncludedHouseConsignment] = useState<IncludedHouseConsignment[]>([]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });

  const loadAirports = useCallback(async () => {
    const response = await listAirports();

    const newArray = response.data.map((objeto) => {
      objeto.label = `${objeto.Abreviatura} ${objeto.Nome === null ? '' : ' - ' + objeto.Nome}`;
      objeto.value = objeto.Abreviatura;
      return objeto;
    });
    setAirportsList(newArray);
    //setOptions(newArray);
  }, []);

  // const loadCountries = useCallback(async () => {
  //   const response = await listCountries();
  //   //setCountriesList(response?.data);
  // }, []);


  const { id, documentnumber } = useParams<UrlParams>();

  useEffect(() => {
    if (id) {
      setTrashHidden(true);
      loadDocument(id, documentnumber);
    }
    loadAirports();
    //loadCountries();
    handleAddHouseConsignment();
  }, [location]);


  const loadDocument = async (id: string, documentnumber: string) => {
    const response = await getHouseManifest(id, documentnumber);

    const houseManifest = response.data;

    setMasterNumber(houseManifest.MasterConsignment.MasterNumber);
    setIncludedTareGrossWeightMeasureMaster(formatNumberBR(houseManifest.MasterConsignment.IncludedTareGrossWeightMeasure));
    setTotalPieceQuantityMaster(formatNumberBR(houseManifest.MasterConsignment.TotalPieceQuantity));

    const origin = houseManifest.MasterConsignment.OriginLocation;
    const destination = houseManifest.MasterConsignment.FinalDestinationLocation;

    setValueOriginAirport({ label: origin, value: origin });
    setValueDestinationAirport({ label: destination, value: destination });

    setDepartureAirport(origin);
    setArrivalAirport(destination);

    const consignmentItemsList = houseManifest.MasterConsignment.IncludedHouseConsignment.map((item) => {
      const origin = item.OriginLocation;
      const destination = item.FinalDestinationLocation;
      return {
        ...item,
        OriginLocation: { label: origin, value: origin },
        FinalDestinationLocation: { label: destination, value: destination },
        TotalPieceQuantity: formatNumberBR(item.TotalPieceQuantity),
        GrossWeightMeasure: formatNumberBR(item.GrossWeightMeasure),
      }
    });

    setIncludedHouseConsignment(consignmentItemsList);
  }


  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      setOpen({ open: false, severity: '', message: '' });
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };


  const [hiddenWarningModal, setHiddenWarningModal] = useState(false);
  const [textWarningModal, setTextWarningModal] = useState([]);

  const handleLoadHouses = async () => {

    try {
      const response = await getHouses(masterNumber);
      const houseList = response.data;

      setIncludedTareGrossWeightMeasureMaster(formatNumberBR(houseList.IncludedTareGrossWeightMeasure));
      setTotalPieceQuantityMaster(formatNumberBR(houseList.TotalPieceQuantity));

      const consignmentItemsList = houseList.HouseConsignmentItem.map((item) => {
        const origin = item.OriginLocation;
        const destination = item.FinalDestinationLocation;

        return {
          ...item,
          OriginLocation: { label: origin, value: origin },
          FinalDestinationLocation: { label: destination, value: destination },
          TotalPieceQuantity: formatNumberBR(item.TotalPieceQuantity),
          GrossWeightMeasure: formatNumberBR(item.GrossWeightMeasure),
        }
      });


      consignmentItemsList.forEach(element => {
        if (element.DateOfIssueGreaterCurrent && element.DateOfIssue) {
          setTextWarningModal(prevState => {
            if (!prevState.includes(element.HouseNumber)) {

              if (prevState.length > 0) {
                setHiddenWarningModal(true);
              }

              return [...prevState, element.HouseNumber]
            } else {
              if (prevState.length > 0) {
                setHiddenWarningModal(true);
              }
              return prevState

            }
          });
        }
      });

      setIncludedHouseConsignment(consignmentItemsList);
      //setIncludedHouseConsignment(houseList.HouseConsignmentItem);

      setOpen({ open: true, severity: 'success', message: "Houses incluídos" });

    } catch (error) {
      const message = error?.response?.data ? error?.response?.data : error;
      setOpen({ open: true, severity: 'error', message: message });
    }
  };

  const handleSubmit = async (e: any, oper: string) => {
    e.preventDefault();
    setLoading(true);

    const includedHouseConsignmentList = includedHouseConsignment.map((house: IncludedHouseConsignment) => {
      return {
        ...house,
        OriginLocation: house.OriginLocation?.value,
        FinalDestinationLocation: house.FinalDestinationLocation?.value,
        GrossWeightMeasure: formatNumber(house.GrossWeightMeasure),
        TotalPieceQuantity: formatNumber(house.TotalPieceQuantity),
      }
    });

    if (!departureAirport) {
      setOpen({ open: true, severity: 'warning', message: "Porto de origem do MAWB deve ser preenchido" });
      setLoading(false);
      return;
    }
    else if (!arrivalAirport) {
      setOpen({ open: true, severity: 'warning', message: "Porto de destino do MAWB deve ser preenchido" });
      setLoading(false);
      return;
    }

    const houseManifest = {
      IssuerCNPJ: localStorage.getItem("company"),
      Operation: oper === '' ? 'Creation' : oper,
      MasterConsignment: {
        IncludedTareGrossWeightMeasure: formatNumber(includedTareGrossWeightMeasureMaster),
        WeightCode: weightCode === "KG" ? "KGM" : "LBR",
        TotalPieceQuantity: formatNumber(totalPieceQuantityMaster),
        MasterNumber: masterNumber,
        OriginLocation: departureAirport,
        FinalDestinationLocation: arrivalAirport,
        IncludedHouseConsignment: includedHouseConsignmentList
      },
    };

    try {
      const response = await sendHouseManifest(houseManifest);

      setTrashHidden(false);
      setIncludedTareGrossWeightMeasureMaster('');
      setWeightCode('KG');
      setTotalPieceQuantityMaster('');
      setMasterNumber('');
      setDepartureAirport('');
      setArrivalAirport('');
      setIncludedHouseConsignment([]);

      setOpen({ open: true, severity: 'success', message: response?.data.Mensagem });
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else if (error?.name === 'Error') {
        const message = error?.response?.data?.erro ? error?.response?.data?.erro : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = t('networkError');
        setOpen({ open: true, severity: 'error', message: message });
      }
    }
    setLoading(false);
  }

  function handleAddHouseConsignment() {
    let newfield = {
      GrossWeightMeasure: 0,
      TotalPieceQuantity: 0,
      SummaryDescription: '',
      HouseNumber: '',
      OriginLocation: '',
      FinalDestinationLocation: '',
      // Agent: {
      //   CNPJ: '',
      //   CountryId: '',
      // },
    }
    setIncludedHouseConsignment([...includedHouseConsignment, newfield]);
  }


  const handleFormChange = (index: number, event: any) => {
    let data = [...includedHouseConsignment];
    data[index][event.target.name] = event.target.value;
    setIncludedHouseConsignment(data);
  }

  function handleRemoveHouseConsignment(index: number) {
    let data = [...includedHouseConsignment];
    data.splice(index, 1);
    setIncludedHouseConsignment(data);
  }

  const disabledConsultHouse = masterNumber.length === 12 ? false : true;

  const createOption = (label: string) => ({
    label,
    value: label?.replace(/\W/g, ''),
  });

  const createOption2 = (label: string, value: string) => ({
    label,
    value: value?.replace(/\W/g, ''),
  });


  const [valueDestinationAirport, setValueDestinationAirport] = useState();

  const handleCreateDestination = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setAirportsList((prev) => [...prev, newOption]);
    setValueDestinationAirport(newOption);
    setArrivalAirport(newOption?.value);
  };

  function handleChangeDestinationAirport(event) {
    setValueDestinationAirport(event);
    setArrivalAirport(event?.value);
  }

  const [valueOriginAirport, setValueOriginAirport] = useState();

  const handleCreateOrigin = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setAirportsList((prev) => [...prev, newOption]);
    setValueOriginAirport(newOption);
    setDepartureAirport(newOption?.value);
  };

  function handleChangeOriginAirport(event) {
    setValueOriginAirport(event);
    setDepartureAirport(event?.value);
  }

  function handleChangeAirportHouse(index: number, event: any, name: string) {
    let data = [...includedHouseConsignment];
    const newOption = createOption2(event?.label, event?.value);
    data[index][name] = newOption;
    setIncludedHouseConsignment(data);
  }

  const iconSize = 15;

  function handleDeleteDocument(e: any) {
    setOpenModal(false);
    handleSubmit(e, 'Deletion');
  }

  return (
    <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <span className={classes.textTitle}>MAWB/HAWB Associados - FHL</span>
            <form onSubmit={handleSubmit}>
              <CardBody className={classes.container}>
                <div className={classes.inputGroup}>
                  <div className={classes.inputs} style={{ alignItems: 'end' }}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>MAWB</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 12 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={masterNumber} className={classes.input} onChange={e => setMasterNumber(formatDocumentNumber(e.target.value))} type='text' required maxLength={12} />
                      {/* <Input title="MAWB" required={true} classes={classes} value={masterNumber} onchange={setMasterNumber} funcaoFormatacao={formatDocumentNumber} maxlength={12} /> */}
                    </div>
                    <Button disabled={disabledConsultHouse} onClick={handleLoadHouses}>ASSOCIAR HAWB’<span style={{ textTransform: 'lowercase' }}>s</span></Button>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>MAWB</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Origem</span>
                        <Tooltip title={<span>Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <CreatableSelect
                        placeholder={t('selectAirport')}
                        isClearable
                        onChange={handleChangeOriginAirport}
                        onCreateOption={handleCreateOrigin}
                        options={airportsList}
                        value={valueOriginAirport}
                        className={classes.inputSelect}
                        styles={customStyles}
                      />

                      {/* <select name="OriginLocation" className={classes.input} value={departureAirport} onChange={e => setDepartureAirport(e.target.value)} required >
                        <option></option>
                        {airportsList.map((airport) => (
                          <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                        ))}
                      </select> */}
                    </div>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Destino</span>
                        <Tooltip title={<span>Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <CreatableSelect
                        placeholder={t('selectAirport')}
                        isClearable
                        onChange={handleChangeDestinationAirport}
                        onCreateOption={handleCreateDestination}
                        options={airportsList}
                        value={valueDestinationAirport}
                        className={classes.inputSelect}
                        styles={customStyles}
                      />

                      {/* <select name="OriginLocation" className={classes.input} value={arrivalAirport} onChange={e => setArrivalAirport(e.target.value)} required >
                        <option></option>
                        {airportsList.map((airport) => (
                          <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Quantidades Totais</span>
                  <div className={classes.inputs}>

                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Peso Bruto</span>
                        <Tooltip title={<span>Obrigatório <br /> 3 casas decimais</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <NumericFormat
                        name="GrossWeightMeasure"
                        value={includedTareGrossWeightMeasureMaster}
                        className={classes.input}
                        fixedDecimalScale
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={3}
                        allowNegative={false}
                        onChange={event => setIncludedTareGrossWeightMeasureMaster(event.target.value)}
                        placeholder='0,000'
                        style={{ textAlign: 'right' }}
                        required
                      />
                    </div>
                    {/* <Input numeric title={`Peso Bruto`} required={true} classes={classes} value={includedTareGrossWeightMeasureMaster} onchange={setIncludedTareGrossWeightMeasureMaster} placeholder={'0,000'}/> */}

                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Quantidade de Volumes</span>
                        <Tooltip title={<span>Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <NumericFormat
                        name="GrossWeightMeasure"
                        value={totalPieceQuantityMaster}
                        className={classes.input}
                        fixedDecimalScale
                        decimalScale={0}
                        allowNegative={false}
                        onChange={event => setTotalPieceQuantityMaster(event.target.value)}
                        placeholder='0'
                        style={{ textAlign: 'right' }}
                        required
                      />
                    </div>

                    {/* <Input numeric title="Quantidade de Volumes" required={true} classes={classes} value={totalPieceQuantityMaster} onchange={setTotalPieceQuantityMaster} decimalScale={0} placeholder={'0'} /> */}

                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>HAWB's</span>
                  <span className={classes.spanLink} onClick={handleAddHouseConsignment}>
                    <Tooltip title="Incluir House">
                      <AddIcon />
                    </Tooltip>
                  </span>
                </div>

                {includedHouseConsignment.map((input: any, index: any) => {
                  return (
                    <div key={index} className={classes.inputGroup}>
                      <span className={classes.trash} onClick={() => handleRemoveHouseConsignment(index)}>
                        <Tooltip title="Deletar House">
                          <DeleteIcon />
                        </Tooltip>
                      </span>

                      <div className={classes.inputs}>
                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>HAWB {index + 1}</span>
                            <Tooltip title={<span>Obrigatório <br /> Limite 11 caracteres</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                            {(input.DateOfIssueGreaterCurrent && input.DateOfIssue !== '') && (
                              <Tooltip title="Data de emissão do HAWB é maior que a data atual">
                                <WarningIcon style={{ color: 'red' }} />
                              </Tooltip>
                            )}
                          </div>
                          <input name="HouseNumber" value={input.HouseNumber} className={classes.input} onChange={event => handleFormChange(index, event)} type="text" maxLength={11} required />
                        </div>

                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>Peso Bruto</span>
                            <Tooltip title={<span>Obrigatório <br /> 3 casas decimais</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          {/* <input name="GrossWeightMeasure" value={input.GrossWeightMeasure} className={classes.input} onChange={event => handleFormChange(index, event)} type="text" /> */}
                          <NumericFormat
                            name="GrossWeightMeasure"
                            value={input.GrossWeightMeasure}
                            className={classes.input}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                            decimalScale={3}
                            allowNegative={false}
                            onChange={event => handleFormChange(index, event)}
                            placeholder='0,000'
                            style={{ textAlign: 'right' }}
                            required
                          />
                        </div>

                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>Quantidade de Volumes</span>
                            <Tooltip title={<span>Obrigatório</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          <NumericFormat
                            name="TotalPieceQuantity"
                            value={input.TotalPieceQuantity}
                            className={classes.input}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={0}
                            allowNegative={false}
                            onChange={event => handleFormChange(index, event)}
                            placeholder='0'
                            style={{ textAlign: 'right' }}
                            required
                          />
                          {/* <input name="TotalPieceQuantity" value={input.TotalPieceQuantity} className={classes.input} onChange={event => handleFormChange(index, event)} type="text" /> */}
                        </div>
                      </div>

                      <div className={classes.inputs}>
                        <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                          <div className={classes.iconWithTitle}>
                            <span>Descrição Resumida das Mercadorias</span>
                            <Tooltip title={<span>Obrigatório <br /> Limite 70 caracteres</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          <input name="SummaryDescription" value={input.SummaryDescription} className={classes.input} onChange={event => handleFormChange(index, event)} type="text" required />
                        </div>
                      </div>

                      <div className={classes.inputs}>
                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>Origem</span>
                            <Tooltip title={<span>Obrigatório</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          <CreatableSelect
                            placeholder={t('selectAirport')}
                            name="OriginLocation"
                            isClearable
                            onChange={event => handleChangeAirportHouse(index, event, "OriginLocation")}
                            options={airportsList}
                            value={input.OriginLocation}
                            className={classes.inputSelect}
                            styles={customStyles}
                          />


                          {/* <select name="OriginLocation" className={classes.input} value={input.OriginLocation} onChange={event => handleFormChange(index, event)} required >
                            <option></option>
                            {airportsList.map((airport) => (
                              <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                            ))}
                          </select> */}
                        </div>

                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>Destino</span>
                            <Tooltip title={<span>Obrigatório</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>

                          <CreatableSelect
                            placeholder={t('selectAirport')}
                            name="FinalDestinationLocation"
                            isClearable
                            onChange={event => handleChangeAirportHouse(index, event, "FinalDestinationLocation")}
                            options={airportsList}
                            value={input.FinalDestinationLocation}
                            className={classes.inputSelect}
                            styles={customStyles}
                          />

                          {/* <select name="FinalDestinationLocation" className={classes.input} value={input.FinalDestinationLocation} onChange={event => handleFormChange(index, event)} required >
                            <option></option>
                            {airportsList.map((airport) => (
                              <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                            ))}
                          </select> */}
                        </div>
                      </div>
                      <div className={classes.separatorList}></div>
                    </div>

                  )
                })}

                <div style={{ display: 'flex', width: '100%' }}>
                  {
                    (id !== undefined && documentnumber !== undefined && trashHidden) ?
                      <>
                        <div className={classes.trashContainer}>
                          <div>
                            <span className={classes.trash} onClick={() => setOpenModal(true)} >
                              <Tooltip title="Excluir Manifesto-FHL">
                                <DeleteIcon />
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </> : <></>
                  }

                  <div className={classes.buttonContainer}>
                    <Button
                      type='submit'
                      disabled={loading}
                    >
                      {t('send')}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>

                <Modal open={openModal} onClose={handleCloseModal}>
                  <Box className={classes.box}>
                    <Box className={classes.boxTitle}>
                      <Typography className={classes.titleModal}>
                        Excluir Manifesto-FHL
                      </Typography>
                      <button className={classes.buttonExit} onClick={handleCloseModal}>
                        X
                      </button>
                    </Box>
                    <Box className={classes.boxForm}>
                      <p style={{ padding: '10px' }}>Deseja excluir o Manifesto-FHL <b>{documentnumber}</b> do Portal Único?</p>
                      <div className={classes.buttonContainer}>
                        <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
                        <Button onClick={(e) => handleDeleteDocument(e)}>
                          OK
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </Box>
                  </Box>
                </Modal>

                <Modal open={hiddenWarningModal} onClose={handleCloseWarningModal}>
                  <Box className={classes.box}>
                    <Box className={classes.boxTitle}>
                      <Typography className={classes.titleModal}>
                        Aviso
                      </Typography>
                      <button className={classes.buttonExit} onClick={handleCloseWarningModal}>
                        X
                      </button>
                    </Box>
                    <Box className={classes.boxForm} style={{ overflowY: 'auto', maxHeight: '250px', width: '100%' }}>
                      <p><b>HAWB's com data de emissão maior que a data atual: </b></p>
                      {textWarningModal.map((text) => (
                        <span style={{ display: 'block' }}>{text}</span>
                      ))}
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                        <Button onClick={handleCloseWarningModal} style={{ position: 'absolute', bottom: 0 }}>OK</Button>
                      </div>

                      {/* <div className={classes.buttonContainer} style={{ bottom: '0px', position: 'absolute', justifyContent: 'right' }}>
<Button onClick={handleCloseWarningModal}>OK</Button>
                    </div> */}
                    </Box>
                  </Box>
                </Modal>
              </CardBody>
            </form >
          </Card>
        </GridItem>
        {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
      </GridContainer >
    </>
  );
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    border: '1px solid gray',
    borderRadius: '4px',
    minHeight: '1px',
    height: '32px',
    boxShadow: state.isFocused ? '0 0 0 2px lightblue' : 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'lightblue' : 'white',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  }),
};


export default (withStyles(houseManifest)(HouseManifest));
