import { Dashboard, MessagesUpload, MessagesPanel, MessagesConverter, MessagesInclude, UserRegistration,
    ConsultManifest, ManageCertificate, FreightWayBill, HouseWayBill, CompanyRegistration,
    FlightManifest, HouseManifest, InternalControl, User, UsersPanel, InternalControlDetail, CompanyPanel } from 'pages';
import GridOnIcon from '@material-ui/icons/GridOn';
import CloudUpload from '@material-ui/icons/CloudUpload';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RepeatIcon from '@material-ui/icons/Repeat';
import MessageIcon from '@material-ui/icons/Message';
import PersonAdd from '@material-ui/icons/PersonAdd';
import BusinessIcon from '@material-ui/icons/Business';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightIcon from '@material-ui/icons/Flight';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import { getUserStorage } from 'services';
import CompanyEdit from 'pages/CompanyEdit';

export const getMenu = () => {
   let menu = [];
   let user = getUserStorage();
   if (user) {
      let parseUser = JSON.parse(user);
      menu = parseMenu(parseUser?.menu, user);
   }
   return menu;
}

const application = process.env.REACT_APP_APPLICATION === undefined ? '' : process.env.REACT_APP_APPLICATION;

export const parseMenu = (menuContent: any, user: any) => {

   menuContent.map((menu: any) => {
      if (menu['Path'] !== '#') {
         dashboardRoutesTemp.push({
            path: menu['Path'].charAt(0) === '/' ? application + menu['Path'] : application + menu['Path'],
            name: menu['Caption'],
            ...configRoute(menu)
         });
      }
      return dashboardRoutesTemp;
   });
   return dashboardRoutesTemp;
}

const configRoute = (menu: any) => {
   if (menu['Path'] === '/uploadfiles') {
      return {
         icon: CloudUpload,
         mini: getMini(menu['Name']),
         component: MessagesUpload,
      }
   }
   else if (menu['Path'] === '/messagespanel') {
      return {
         icon: MessageIcon,
         mini: getMini(menu['Name']),
         component: MessagesPanel,
      }
   }
   else if (menu['Path'] === '/messagesconverter') {
      return {
         icon: RepeatIcon,
         mini: getMini(menu['Name']),
         component: MessagesConverter,
      }
   }
   else if (menu['Path'] === '/messagesinclude') {
      return {
         icon: AddCircleOutlineIcon,
         mini: getMini(menu['Name']),
         component: MessagesInclude,
      }
   }
   else if (menu['Path'] === '/userresgistration') {   
      return {
         icon: PersonAdd,
         mini: getMini(menu['Name']),
         component: UserRegistration,
      }
   }
   else if (menu['Path'] === '/manifastview') {   
      return {
         icon: FlightIcon,
         mini: getMini(menu['Name']),
         component: ConsultManifest,
      }
   }
   else if (menu['Path'] === '/managecertificates') {   
      return {
         icon: CardMembershipIcon,
         mini: getMini(menu['Name']),
         component: ManageCertificate,
      }
   }
   else if (menu['Path'] === '/insertdocumentfreightwaybill') {   
      return {
         icon: GridOnIcon,
         mini: getMini(menu['Name']),
         component: FreightWayBill,
      }
   }
   else if (menu['Path'] === '/insertdocumenthousewaybill') {   
      return {
         icon: GridOnIcon,
         mini: getMini(menu['Name']),
         component: HouseWayBill,
      }
   } 
   else if (menu['Path'] === '/companyregistration') {   
      return {
         icon: BusinessIcon,
         mini: getMini(menu['Name']),
         component: CompanyRegistration,
      }
   }   
   else if (menu['Path'] === '/insertflightmanifest') {   
      return {
         icon: FlightLandIcon,
         mini: getMini(menu['Name']),
         component: FlightManifest,
      }
   }       
   else if (menu['Path'] === '/inserthousemanifest') {   
      return {
         icon: BusinessIcon,
         mini: getMini(menu['Name']),
         component: HouseManifest,
      }
   }       
   else if (menu['Path'] === '/internalcontrol') {   
      return {
         icon: AssessmentIcon,
         mini: getMini(menu['Name']),
         component: InternalControl,
      }
   }       
   else if (menu['Path'] === '/users') {   
      return {
         icon: PeopleIcon,
         mini: getMini(menu['Name']),
         component: UsersPanel,
      }
   }       
   else if (menu['Path'] === '/companys') {   
      return {
         icon: BusinessIcon,
         mini: getMini(menu['Name']),
         component: CompanyPanel,
      }
   }       
}

const getMini = (name: string) => {
   const wordLength = name.split(' ').filter(function (n) {
      return n !== ''
   }).length; //contar palavras na string
   if (wordLength === 1) {
      const miniName = name.substr(0, 2); //caso houver 1 palavra, pega primeiros 2 char
      return miniName.toUpperCase();
   }
   return name.charAt(0) + name.charAt(name.lastIndexOf(' ') + 1); //retorna primeiro char da primeira palavra e primeiro char da ultima palavre
}

const dashboardRoutesTemp: any = [];

export const dashboardRoutes = [
   {
      path: `${application}/home`,
      name: 'Dashboard',
      icon: GridOnIcon,
      mini: 'DASH',
      component: Dashboard,
      invisible: true,
   },
   {
      path: `${application}/InsertDocumentFreightWayBill/:id/:documentnumber`,
      name: 'InsertDocumentFreightWayBill',
      icon: GridOnIcon,
      mini: 'DASH',
      component: FreightWayBill,
      invisible: true,
   },
   {
      path: `${application}/insertdocumenthousewaybill/:id/:documentnumber`,
      name: 'insertdocumenthousewaybill',
      icon: GridOnIcon,
      mini: 'DASH',
      component: HouseWayBill,
      invisible: true,
   },
   {
      path: `${application}/InsertFlightManifest/:id/:documentnumber`,
      name: 'InsertFlightManifest',
      icon: GridOnIcon,
      mini: 'DASH',
      component: FlightManifest,
      invisible: true,
   },
   {
      path: `${application}/inserthousemanifest/:id/:documentnumber`,
      name: 'InsertHouseManifest',
      icon: BusinessIcon,
      mini: 'DASH',
      component: HouseManifest,
      invisible: true,
   },
   {
      path: `${application}/user/:id`,
      name: 'User',
      icon: PersonIcon,
      mini: 'DASH',
      component: User,
      invisible: true,
   },   
   {
      path: `${application}/internalcontroldetail/:empresaId/:usuarioId/:dataInicial/:dataFinal/:aeroportodestino?`,
      name: 'InternalControlDetail',
      icon: PersonIcon,
      mini: 'DASH',
      component: InternalControlDetail,
      invisible: true,
   },   
   {
      path: `${application}/companys/:companyId`,
      name: 'CompanyEdit',
      icon: PersonIcon,
      mini: 'DASH',
      component: CompanyEdit,
      invisible: true,
   }, 
   ...getMenu(),
   { redirect: true, path: '/', pathTo: `${application}/home`, name: 'Dash', invisible: true },
];