import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { manageCertificate } from './styles';
import { Box, Modal, Typography } from '@material-ui/core';

function customModal({ ...props }) {
   const {
      classes,
      children,
      handleClose,
      openModal,
      title

   } = props;

   return (
      <Modal open={openModal} onClose={handleClose}>
         <Box className={classes.box}>
            <Box className={classes.boxTitle}>
               <Typography className={classes.titleModal}>
                  {title}
               </Typography>
               <button className={classes.buttonExit} onClick={handleClose}>
                  X
               </button>
            </Box>

            {children}

         </Box>
      </Modal>
   );
}

customModal.propTypes = {
   classes: PropTypes.object.isRequired,
   color: PropTypes.oneOf([
      'idataColor',
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'rose',
      'white',
      'transparent'
   ]),
   size: PropTypes.oneOf(['sm', 'md', 'lg']),
   simple: PropTypes.bool,
   round: PropTypes.bool,
   fullWidth: PropTypes.bool,
   disabled: PropTypes.bool,
   block: PropTypes.bool,
   link: PropTypes.bool,
   justIcon: PropTypes.bool,
   className: PropTypes.string,
   muiClasses: PropTypes.object
};

customModal.defaultProps = {
   color: 'idataColor',
   useButton: false,
   size: 'md',
};

export default withStyles(manageCertificate)(customModal);
