import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'context';
import { removeStorage } from 'services';
import { getPath } from 'utils';
import useStyles from './styles';

import './styles.css';

function Profile() {
    const classes = useStyles();
    const users = useContext(UserContext) as IUser;
    const { t } = useTranslation();
    const { push } = useHistory();
    const [openProfile, setOpenProfile] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    function handleClickPerfil(event: any) {
        setAnchorEl(event.currentTarget);
        setOpenProfile(true);
    }

    function handleClosePerfil() {
        setOpenProfile(false);
    }

    function handleLogout() {
        handleClosePerfil();
        removeStorage();
        push(getPath('Login'));
    }

    const dropdownItem = classNames(
        classes.dropdownItem,
        classes.primaryHover,
    );

    return (
        <>
            <IconButton
                className={classes.iconButton + ' ' + classes.customIconButton}
                onClick={handleClickPerfil}
            >
                <PersonIcon
                    classes={{
                        root: classes.Icon,
                    }}
                />
                <Hidden mdUp implementation='css' >
                    <p className={classes.linkText}>
                        Perfil
                    </p>
                </Hidden>
            </IconButton>
            <Popper
                open={openProfile}
                anchorEl={anchorEl}
                transition
                disablePortal
                className={
                    classNames({ [classes.popperClose]: !openProfile }) +
                    ' ' +
                    classes.popperNav
                }
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        //id='profile-menu-list-grow'
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePerfil}>
                                <MenuList role='menu'>
                                    <Link to={`/user/${users.usuarioID}`} style={{ textDecoration: 'none' }}>
                                        <MenuItem
                                            className={dropdownItem}
                                        >
                                            {users?.nome ? users.nome : 'Usuário Desconhecido'}
                                        </MenuItem>
                                    </Link>

                                    <Divider className={classes.dividerRoot} />
                                    <MenuItem onClick={handleLogout}
                                        className={dropdownItem}
                                    >
                                        {t('signout')}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}



export default Profile;






