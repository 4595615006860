import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import translationEN from 'locales/en.json';
import translationPT from 'locales/pt.json';


const options = {
  // order and from where user language should be detected
  order: ['navigator',
    //'querystring','path'
    // 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'
  ],
}

i18next
  //.use(reactI18nextModule) // passes i18n down to react-i18next
  .use(detector)
  // .use(Fetch)
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      pt: {
        translation:translationPT
      }
    },
    initImmediate: false,
    detection: options,
    //ns: 'dashboard',
    whitelist: ['pt', 'en'],
    fallbackLng: false,
    nonExplicitWhitelist: true,
    load: 'languageOnly',
    //debug: true,
    react: {
      wait: true
    },
    nsSeparator: '.'
  }, (err, t) => {

  })


export default i18next;

