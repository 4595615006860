//export const linkAPI = 'http://localhost:5010'
// export const linkAPI = 'http://104.210.59.74'

//export const linkAPI = 'http://ilogabove.idata.com.br'

// const apiUrl = 'http://104.210.59.74';

//let apiUrlLogin = 'http://ilog-ws-win.brazilsouth.cloudapp.azure.com:8000';

//let apiUrlLogin = 'http://ilogapp.idata.com.br:8000';
//let apiUrlLogin = process.env.REACT_APP_API_LOGIN;
//let apiUrlLogin = process.env.REACT_APP_LOGIN_API_DESENV;

//let apiUrl = 'http://ilog-ws-win.brazilsouth.cloudapp.azure.com:82/APIiLOG1';
//let apiUrl = 'https://localhost:5015'; // LOCAL
//let apiUrl = process.env.REACT_APP_API_CCT_DESENV; // LOCAL

let apiUrl = process.env.REACT_APP_API_CCT;

// let apiUrlLogin = '192.198.100.5';
// let apiUrl = '192.168.100.5';

if (process.env.NODE_ENV !== 'production') {
   //apiUrl = process.env.REACT_APP_API_CCT_DESENV;
   apiUrl = process.env.REACT_APP_API_CCT_DESENV;
}
else {
   apiUrl = process.env.REACT_APP_API_CCT_PRODUCAO; 
}

export const getRowMessagesPath = `${apiUrl}/Messages/GetMessagesRows`;
export const getColumnsMessagesPath = `${apiUrl}/Messages/GetColumns`;
export const uploadColumnsMessagesPath = `${apiUrl}/Messages/UploadColumns`;

export const uploadMessagesPath = `${apiUrl}/Messages/UploadMessage`; 
export const getMessagesPath = `${apiUrl}/Messages/GetMessages`;
export const getSetorPath = `${apiUrl}/Messages/GetSetor`;
export const loginPath = `${apiUrl}/Auth/Login`; 
export const emailRecoveryPath = `${apiUrl}/Pwd/ValidationMail`;
export const resetPasswordPath = `${apiUrl}/Pwd/ChangePwd`;
export const confirmPasswordPath = `${apiUrl}/Pwd/ConfirmPassword`;

export const registerNewUserPath = `${apiUrl}/Register/RegisterUser`;
export const registerNewCompanyPath = `${apiUrl}/Register/RegisterCompany`;
export const getMenuPath = `${apiUrl}/Menu/GetMenu`;

export const messagePath = `${apiUrl}/Messages`;

export const consultManifestPath = `${apiUrl}/Messages/ConsultManifest`;

export const messagesByFlightNumPath = `${apiUrl}/Messages/GetMessagesByFlightNum`;
export const sendFreightWaybillPath = `${apiUrl}/Messages/SendFreightWaybill`;
export const sendHouseWaybillPath = `${apiUrl}/Messages/SendHouseAirWaybill`;
export const sendHouseManifestPath = `${apiUrl}/Messages/SendHouseManifest`;
export const sendFlightManifestpath = `${apiUrl}/Messages/SendFlightManifest`;
export const getFreightWaybillPath = `${apiUrl}/Messages/GetFreightWaybill`;
export const getFlightManifestPath = `${apiUrl}/Messages/GetFlightManifest`;
export const getHouseWaybillPath = `${apiUrl}/Messages/GetHouseWaybill`;
export const getHouseManifestPath = `${apiUrl}/Messages/GetHouseManifest`;


export const certificatePath = `${apiUrl}/Certificate`;
export const insertCertificatePath = `${apiUrl}/Certificates/InsertNewCertificate`;
export const updateCertificatesPath = `${apiUrl}/Certificates/UpdateCertificates`;

export const aiportPath = `${apiUrl}/Airport`;
export const countryPath = `${apiUrl}/Country`;
export const currencyPath = `${apiUrl}/Currency`;
export const companyPath = `${apiUrl}/Company`;

export const interControlPath = `${apiUrl}/InternalControl`;
export const consultHousesPath = `${apiUrl}/Messages/ConsultHouses`;

export const consultUserPath = `${apiUrl}/User/GetUser`;
export const uldPath = `${apiUrl}/ULD/GetULD`;
export const recintoPath = `${apiUrl}/RecintoAduaneiro/GetRecintos`;
export const manuseioPath = `${apiUrl}/ManuseioCarga/GetSpecialHandling`;

export const downloadPath = `${apiUrl}/Download`;
export const userPath = `${apiUrl}/User`;

