//routes
import {authRoutes} from 'routes';

export function getPath(routeName:string) {

    
    let path = '';
    authRoutes.map((prop) => {
        if (prop.name === routeName) {
            path = prop.path;
        }
        return path;
    });

    return path;
}
