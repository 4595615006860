import { forwardRef } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import checkboxStyle from './styles';

const RegularCheckbox = forwardRef((props: any, ref: any) => {
  const {
    classes,
    size,
    children,
    ...rest
  } = props;

  return (
    <Checkbox {...rest} classes={{
      root: classes.root,
      checked: classes.checked,
      colorPrimary: classes.checked,
    }}
    size={size}
    >
      {children}
    </Checkbox>
  );
})

export default withStyles(checkboxStyle)(RegularCheckbox);
