import { useEffect, useState } from 'react';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import companyEdit from './styles';
import { updateCompany, getCertificates, getCompanyById } from './../../services/api';
import { Input } from './../../components/Input/Input';
import { useParams } from 'react-router-dom';
import { history } from 'utils';

interface UrlParams {
	companyId: number;
}

function CompanyEdit(props: any) {
	const { classes } = props;
	const { t } = useTranslation();

	const { companyId } = useParams<UrlParams>();

	const [companyName, setCompanyName] = useState('');
	const [companyCNPJ, setCompanyCNPJ] = useState('');
	const [companyProfile, setCompanyProfile] = useState('');
	const [fantasyName, setFantasyName] = useState('');
	const [certificatesList, setCertificatesList] = useState([]);
	const [certificate, setCertificate] = useState();

	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState({ open: false, severity: '', message: '' });

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen({ open: false, severity: '', message: '' });
	};

	async function loadCompanyById() {
		const response = await getCompanyById(companyId);

		const company = response.data[0];

		setCompanyName(company.razaosocial_emp);
		setCompanyCNPJ(company.cgc_emp);
		setFantasyName(company.fantasia_emp);
		setCertificate(company.certificadoId);

		if (company.nome_ramo === "TRANSPORTADORA") {
			setCompanyProfile("Transportadora");
		}
		else {
			setCompanyProfile("Agente de Carga");
		}
	}

	async function loadCertificates() {
		const response = await getCertificates();
		setCertificatesList(response.data);
	}

	useEffect(() => {
		loadCompanyById();
		loadCertificates();
	}, []);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);

		if (companyName === undefined || companyCNPJ === undefined) {
			setOpen({ open: true, severity: 'error', message: t('warningRegister') });
		} else {

			try {
				const company = {
					razaoSocial: companyName,
					nomeFantasia: fantasyName,
					cnpj: companyCNPJ,
					perfil: companyProfile,
					certificadoId: certificate
				}

				const response = await updateCompany(company, companyId);
				if (response && response.data) {
					const result = response.data;

					setCompanyName('');
					setCompanyCNPJ('');
					setCompanyProfile('');
					setFantasyName('');
					setCertificate('');

					setOpen({ open: true, severity: 'success', message: result });
				}
			}
			catch (error) {
				if (error?.response?.status === 401) {
					const message = t('expiredToken');
					setOpen({ open: true, severity: 'error', message: String(message) });
				}
				else {
					const message = error?.response?.data ? error?.response?.data : error;
					setOpen({ open: true, severity: 'error', message: String(message) });
				}
			}
		}
		setLoading(false);

		history.push(`/companys`);
	}

	const profileList = [
		{
			profile: "Transportadora"
		},
		{
			profile: "Agente de Carga"
		}
	];

	return (
		<>
			<GridContainer justify='center'>
				<GridItem xs={12} sm={12} md={7}>
					<Card>
						<span className={classes.textTitle}>Atualizar Empresa</span>
						<form onSubmit={handleSubmit}>
							<CardBody className={classes.container}>
								<div className={classes.inputGroup}>
									<div className={classes.inputs}>
										<Input title="Razao Social" required={true} classes={classes} value={companyName} onchange={setCompanyName} />
									</div>
									<div className={classes.inputs}>
										<Input title="Nome Fantasia" required={true} classes={classes} value={fantasyName} onchange={setFantasyName} />
									</div>
									<div className={classes.inputs}>
										<Input title="CNPJ" required={true} classes={classes} value={companyCNPJ} onchange={setCompanyCNPJ} />

										<div className={classes.inputBox} >
											<span>Perfil</span>
											<select className={classes.input} value={companyProfile} onChange={(e) => setCompanyProfile(e.target.value)}>
												<option></option>
												{profileList.map((profile) => (
													<option key={profile?.profile} value={profile?.profile}>{profile?.profile}</option>
												))}
											</select>
										</div>
									</div>

									<div className={classes.inputs}>
										<div className={classes.inputBox}>
											<span>Certificado</span>
											<select className={classes.input} value={certificate} onChange={(e) => setCertificate(e.target.value)}>
												<option></option>
												{certificatesList.map((certificate) => (
													<option key={certificate?.certificateId} value={certificate?.certificateId}>{certificate?.label}</option>
												))}
											</select>
										</div>
									</div>
								</div>

								<div className={classes.buttonContainer}>
									<Button
										type='submit'
										disabled={loading}
									>
										Atualizar
									</Button>
									{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
								</div>
							</CardBody>
						</form >
					</Card>
				</GridItem>
				{open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
			</GridContainer>
		</>
	);
}

export default (withStyles(companyEdit)(CompanyEdit));