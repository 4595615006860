export function authenticationHeader() {
    let token = JSON.parse(localStorage.getItem('user'))
    if (token && token['Value']) {
        const config = {
            headers: { Authorization: `Bearer ${token['Value']}`,
                        AppChamada: 'painel',
                        userId: token['usuarioID']
                        }
        };
        return config;
    } else {
        return {}
    }
}

export default authenticationHeader

