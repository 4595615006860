import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import buttonStyle from './styles';

function customButton({ ...props }) {
    const {
        classes,
        color,
        round,
        children,
        fullWidth,
        disabled,
        simple,
        size,
        block,
        link,
        justIcon,
        className,
        muiClasses,
        useButton,
        ...rest
    } = props;

    const btnClasses = classNames({
        [classes.button]: useButton,
        [classes[size]]: size,
        [classes[color]]: color,
        [classes.round]: round,
        [classes.fullWidth]: fullWidth,
        [classes.disabled]: disabled,
        [classes.simple]: simple,
        [classes.block]: block,
        [classes.link]: link,
        [classes.justIcon]: justIcon,
        [className]: className
    });

    return (
        <Button {...rest} classes={muiClasses} className={btnClasses}>
            <Typography variant='button' className={btnClasses} display='block' >
                {children}
            </Typography>
        </Button>
    );
}

customButton.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        'idataColor',
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'rose',
        'white',
        'transparent',
        'gray'
    ]),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    simple: PropTypes.bool,
    round: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.bool,
    justIcon: PropTypes.bool,
    className: PropTypes.string,
    muiClasses: PropTypes.object
};

customButton.defaultProps = {
    color: 'idataColor',
    useButton: false,
    size: 'md',
};

export default withStyles(buttonStyle)(customButton);
