import PropTypes from 'prop-types';
//import cx from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
// import ErrorIcon from '@material-ui/icons/ErrorOutline';
// import CheckIcon from '@material-ui/icons/Check';
// import WarningIcon from '@material-ui/icons/Warning';
import snackbarContentStyle from './styles';

function SnackbarContent({ ...props }) {
  const { classes, open, message, severity, /*icon,*/ close } = props;

  // const messageClasses = cx({
  //   [classes.iconMessage]: icon !== undefined
  // });

  // const getIconType = () => {
  //   switch (severity.toLowerCase()) {
  //     case 'error':
  //       return ErrorIcon;
  //     case 'success':
  //       return CheckIcon;
  //     case 'warning':
  //       return WarningIcon;
  //     default:
  //       return WarningIcon;
  //   }
  // }

  //const IconInstance = getIconType();

  function contarQuebrasDeLinha(str) {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) === "\n") {
        count++;
      }
    }
    return count;
  }

  let hasMoreLines = false;
  if (contarQuebrasDeLinha(message) > 2) {
    hasMoreLines = true;
  }

  // if (message.includes('\n'))
  //   hasMoreLines = false;
  // else
  //   hasMoreLines = true;

  return (
    <Snackbar
      autoHideDuration={5000}
      onClose={close}
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Snack
        message={
          <div className={classes.containerMessageIcon}>
            <span className={classes.messageClasses}>{message}</span>
          </div>
        }

        classes={{
          root: classes.default + ' ' + classes[severity],
          message: hasMoreLines ? classes.messageScroll : classes.message
        }}

        action={close && <IconButton
          className={classes.iconButton}
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={close}
        >
          <Close className={classes.close} />
        </IconButton>}

      />
    </Snackbar>
  );
}

SnackbarContent.defaultProps = {
  severity: 'info'
};

SnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  severity: PropTypes.oneOf([
    'info',
    'success',
    'warning',
    'error',
    'primary',
    'rose'
  ]),
  close: PropTypes.func,
  icon: PropTypes.bool,
};

export default withStyles(snackbarContentStyle)(SnackbarContent);
