import createStyles from '@material-ui/core/styles/createStyles';

const linearProgressStyle = createStyles({
    container: {
        zIndex: 500,      
        top:"-17px",
      // marginTop: "-5px",
        borderTopLeftRadius: "25px",
        borderTopRightRadius: "25px",      
    },
    color: {
        backgroundColor: 'rgb(0, 0, 0, 0.87)!important',
    },
    absolute:{
        position:"absolute",
    }
});

export default linearProgressStyle;
