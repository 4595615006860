import React, { useState } from 'react';
import { Checkbox } from 'components';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';

const messagesTypes = [
  { code: 'XFFM', label: 'XML Flight Manifest Message (XFFM)' },
  { code: 'IMPXFFM', label: 'Cargo-IMP Flight Manifest Message (FFM)' },
  { code: 'XFWB', label: 'XML Waybill Message (XFWB)' },
  { code: 'IMPXFWB', label: 'Cargo-IMP Waybill Message (FWB)' },
  //{ code: 'XFHL', label: 'XML House Manifest (XFHL)' },
  // { code: 'CSVXFHL', label: 'CSV House Manifest' },
  //{ code: 'XFZB', label: 'XML House Waybill Message (XFZB)' },
   { code: 'CSVXFZB', label: 'CSV House Waybill Message' },
];

export default function MessagesSelect(props: any) {
  const { selectType } = props;
  const { t } = useTranslation();
  const [selected, setSelected] = useState('');

  const handleChange = (event: any) => {
    setSelected(event.target.id);
    selectType(event.target.id);
  };

  return (
    <FormControl component="fieldset" >
      <FormLabel focused={false} component="legend">{t('selectMessage')}</FormLabel>
      <FormGroup>
        {messagesTypes.map((message: any) =>
          <FormControlLabel
            key={message.code}
            control={
              <Checkbox
                checked={selected === message.code}
                onChange={handleChange} id={message.code}
              />
            }
            label={message.label}
          />
        )}
      </FormGroup>
    </FormControl>

  );
}
