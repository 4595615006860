import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
  container,
  cardTitle
} from 'assets/App';

const authPageStyle = makeStyles((theme: Theme) => createStyles({
  container: {
    ...container,
    zIndex: 4,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px'
    },
  },
  cardTitle: {
    ...cardTitle,
    color: '#FFFFFF'
  },
  textCenter: {
    textAlign: 'center'
  },
  justifyContentSpace: {
    justifyContent: 'space-between',
    display: 'flex',
  },

  justifyContentCenter: {
    justifyContent: 'space-around'
  },
  right: {
    justifyContent: 'flex-end',
    float: 'right'
  },
  left: {
    justifyContent: 'flex-start',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: '#FFFFFF'
    },
    marginLeft: '5px',
    marginRight: '5px'
  },
  inputAdornment: {
    marginRight: '18px'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)'
  },
  cardHeader: {
    marginBottom: '20px'
  },
  socialLine: {
    padding: '0.9375rem 0'
  },
  linearBarColorPrimary: {
    backgroundColor: '#f39224',
  }
}));

export default authPageStyle;
