import { useState, useContext, useEffect, useCallback } from 'react';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { UserContext } from 'context';
import userRegistration from './styles';
import { createNewUser } from 'services';
import { getCompany, listAirports } from './../../services/api';
import Select from "react-select";

interface UserInterface {
	Nome: string;
	Apelido: string;
	Email: string;
	Senha: string;
	ConfirmacaoSenha: string;
	EmpresaList: [];
	AeroportoList: [];
}

interface CompanyInterface {
	cod_emp: number;
	razaosocial_emp: string;
	nome_ramo: string;
}

interface Airports {
  id: number;
  Abreviatura: string;
}

function UserRegistration(props: any) {
	const { classes } = props;
	const { t } = useTranslation();

	const user = useContext(UserContext) as IUser;

	const [companysList, setCompanysList] = useState<CompanyInterface[]>([]);
	const [airportsList, setAirportsList] = useState<Airports[]>([]);
	const [selectedCompanys, setSelectedCompanys] = useState([]);
	const [selectedAirports, setSelectedAirports] = useState([]);


	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState({ open: false, severity: '', message: '' });

	const [userData, setUserData] = useState<UserInterface>({
		Nome: '',
		Apelido: '',
		Email: '',
		Senha: '',
		ConfirmacaoSenha: '',
		EmpresaList: [],
		AeroportoList: [],
	});


	const getCompanysAsync = async () => {
		try {
			const response = await getCompany();
			if (response && response.data) {

				const newArray = response.data.map((objeto) => {
					objeto.label = objeto.razaosocial_emp;
					objeto.value = objeto.cod_emp;
					return objeto;
				});

				setCompanysList(newArray);
			}
		}
		catch (error) {
			if (error?.response?.status === 401) {
				const message = t('expiredToken');
				setOpen({ open: true, severity: 'error', message: String(message) });
			}
			else {
				const message = error?.response?.data ? error?.response?.data : error;
				setOpen({ open: true, severity: 'error', message: String(message) });
			}
		}
	};

	
  const loadAirports = useCallback(async () => {
    const response = await listAirports();

		const newArray = response.data.map((objeto) => {
			objeto.label = objeto.Abreviatura;
			objeto.value = objeto.id;
			return objeto;
		});

    setAirportsList(newArray);
  }, []);


	useEffect(() => {
		getCompanysAsync();
		loadAirports();
	}, []);

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen({ open: false, severity: '', message: '' });
	};

	const handleChangeUserData = (event: any) => {
		let data = userData;
		// @ts-ignore: Unreachable code error
		data[event.target.name] = event.target.value;

		setUserData((prevState) => {
			return { ...prevState, [event.target.name]: event.target.value };
		});
	}


	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);

		if (userData.Senha !== userData.ConfirmacaoSenha) {
			setOpen({ open: true, severity: 'error', message: t('passwordMatch') });
		}
		else {
			if (userData.Nome === '' || userData.Apelido === '' || userData.Email === '' || userData.Senha === '' || userData.ConfirmacaoSenha === '') {
				setOpen({ open: true, severity: 'error', message: t('warningRegister') });
			} else {

				try {
					
					const companysId = selectedCompanys.map((e) => (e?.cod_emp));
					userData.EmpresaList = companysId;

					const airportsId = selectedAirports.map((e) => (e?.id));
					userData.AeroportoList = airportsId;

					const response = await createNewUser(userData);

					if (response && response.data) {
						const result = response.data;
						setUserData({
							Nome: '',
							Apelido: '',
							Email: '',
							Senha: '',
							ConfirmacaoSenha: '',
							EmpresaList: [],
							AeroportoList: [],
						});

						setOpen({ open: true, severity: 'success', message: result });
					}
				}
				catch (error) {
					if (error?.response?.status === 401) {
						const message = t('expiredToken');
						setOpen({ open: true, severity: 'error', message: String(message) });
					}
					else if (error?.name === 'Error') {
						const message = error?.response?.data?.erro ? error?.response?.data?.erro : error;
						setOpen({ open: true, severity: 'error', message: String(message) });
					}
					else {
						const message = error?.response?.data ? error?.response?.data : error;
						setOpen({ open: true, severity: 'error', message: String(message) });
					}
				}
			}
		}
		setLoading(false);
	}

	function handleSelectCompanys(data: any) {
		setSelectedCompanys(data);
	}

	function handleSelectAirports(data: any) {
		setSelectedAirports(data);
	}

	return (
		<>
			<GridContainer justify='center'>
				<GridItem xs={12} sm={12} md={7}>
					<Card>
						<span className={classes.textTitle}>Cadastro de Usuario</span>
						<form onSubmit={handleSubmit}>
							<CardBody className={classes.container}>
								<div className={classes.inputGroup}>
									<div className={classes.inputs}>
										<div className={classes.inputBox}>
											<span>Nome</span>
											<input name="Nome" value={userData?.Nome} className={classes.input} onChange={e => handleChangeUserData(e)} type='text' />
										</div>
										<div className={classes.inputBox}>
											<span>Apelido</span>
											<input name="Apelido" value={userData?.Apelido} className={classes.input} onChange={e => handleChangeUserData(e)} type='text' />
										</div>
									</div>
									<div className={classes.inputs}>
										<div className={classes.inputBox}>
											<span>Email</span>
											<input name="Email" value={userData?.Email} className={classes.input} onChange={e => handleChangeUserData(e)} type='text' />
										</div>
									</div>
									<div className={classes.inputs}>
										<div className={classes.inputBox}>
											<span>Senha</span>
											<input name="Senha" value={userData?.Senha} className={classes.input} onChange={e => handleChangeUserData(e)} type='password' />
										</div>
										<div className={classes.inputBox}>
											<span>Confirmar Senha</span>
											<input name="ConfirmacaoSenha" value={userData?.ConfirmacaoSenha} className={classes.input} onChange={e => handleChangeUserData(e)} type='password' />
										</div>
									</div>
									<div className={classes.inputs}>
										<div className={classes.inputBox}>
											{user?.eAdministrador > 0 && <>
												<div className={classes.inputBox}>
													<span>Empresa</span>
													<Select
														options={companysList}
														placeholder="Empresas"
														value={selectedCompanys}
														onChange={handleSelectCompanys}
														isSearchable={true}
														isMulti
														className={classes.inputSelect}
														styles={customStyles}
														isRequired
													/>
												</div>
											</>}
										</div>
									</div>
									<div className={classes.inputs}>
										<div className={classes.inputBox}>
											{user?.eAdministrador > 0 && <>
												<div className={classes.inputBox}>
													<span>Aeroporto</span>
													<Select
														options={airportsList}
														placeholder="Aeroportos"
														value={selectedAirports}
														onChange={handleSelectAirports}
														isSearchable={true}
														isMulti
														className={classes.inputSelect}
														styles={customStyles}
													/>
												</div>
											</>}
										</div>
									</div>
								</div>

								<div className={classes.buttonContainer}>
									<Button
										type='submit'
										disabled={loading}
									>
										{t('register')}
									</Button>
									{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
								</div>
							</CardBody>
						</form >
					</Card>
				</GridItem>
				{open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
			</GridContainer>
		</>
	);
}


const customStyles = {
	control: (provided, state) => ({
		...provided,
		width: '100%',
		padding: '0',
		border: '1px solid gray',
		borderRadius: '4px',
		boxShadow: state.isFocused ? '0 0 0 2px lightblue' : 'none',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? 'lightblue' : 'white',
		'&:hover': {
			backgroundColor: 'lightgray',
		},
	}),
};






export default (withStyles(userRegistration)(UserRegistration));