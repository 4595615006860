import { useState, useEffect, useCallback } from 'react';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import houseWayBill from './styles';
import { listAirports, sendHouseWaybill, listCountries, listCurrency, getHouseWaybill, getRecintos, getSpecialHandling, getCompanyWithCNPJ } from '../../services/api';
import { formatDocumentNumber } from '../../utils/formatDocumentNumber';
import { Input } from 'components/Input/Input';
import { formatNumber, formatNumberBR } from 'utils/formatNumber';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import CreatableSelect from 'react-select/creatable';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Typography, Modal } from '@material-ui/core';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';


interface Airports {
  id: number;
  Abreviatura: string;
}

interface ConsignmentItemsInterface {
  GrossWeightMeasure: number;
  GrossVolumeMeasure: number;
  PieceQuantity: number;
  Identification: string;
  ChargeableWeightMeasure: number;
  NCM: string;
}

interface PostalAddressInterface {
  StreetName: string;
  CityName: string;
  PostCode: string;
  CountryID: string;
};

// interface Countries {
//   id: number;
//   Nome: string;
//   Sigla: string;
//   Nomeing: string;
// }

interface Currency {
  moedaid: number;
  simbolo_moeda: string;
}

interface Recinto {
  RecintoAlfandegadoId: number;
  Descricao: string;
  URFCodigo: string;
}

interface UrlParams {
  id: string;
  documentnumber: string
}

interface SpecialHandling {
  id: number;
  Description: string;
  DescriptionCode: string
}

interface SpecialHandling {
  DescriptionCode: string;
}

interface HandlingSSROSI {
  DescriptionCode: string;
  Description: string;
}

interface Countries {
  id: number;
  Nome: string;
  Sigla: string;
  Nomeing: string;
}

function HouseWayBill(props: any) {
  const location = useLocation();
  const { classes } = props;
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);

  //const user = useContext(UserContext) as IUser;

  const [airportsList, setAirportsList] = useState<Airports[]>([]);
  const [countriesList, setCountriesList] = useState<Countries[]>([]);
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);
  const [recintosList, setRecintosList] = useState<Recinto[]>([]);
  const [specialHandlingList, setSpecialHandlingList] = useState<SpecialHandling[]>([]);

  const [trashHidden, setTrashHidden] = useState(false);

  //const [selectedConsigneeCountry, setSelectedConsigneeCountry] = useState('');
  //const [selectedDestinationAirport, setSelectedDestinationAirport] = useState('');

  const [masterNumber, setMasterNumber] = useState('');
  const [dateOfIssue, setDateOfIssue] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [signatoryCarrier, setSignatoryCarrier] = useState('');
  //const [signatoryLocation, setSignatoryLocation] = useState('');
  const [signatoryConsignor, setSignatoryConsignor] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [weightTotalAmount, setWeightTotalAmount] = useState('');
  const [valuationChargeAmount, setValuationChargeAmount] = useState('');
  const [totalChargeAmount, setTotalChargeAmount] = useState('');
  const [carrierTotalAmount, setCarrierTotalAmount] = useState('');
  const [agentTotalAmount, setAgentTotalAmount] = useState('');
  const [taxTotalAmount, setTaxTotalAmount] = useState('');
  const [woodParts, setWoodParts] = useState(false);

  // const [includedTareGrossWeightMeasureHouse, setIncludedTareGrossWeightMeasureHouse] = useState('');
  // const [totalPieceQuantityHouse, setTotalPieceQuantityHouse] = useState('');
  //const [grossVolume, setGrossVolume] = useState('');
  const [summaryDescription, setSummaryDescription] = useState('');

  const [consignorName, setConsignorName] = useState('');

  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeCNPJ, setConsigneeCNPJ] = useState('');

  const [recinto, setRecinto] = useState('');

  const [freightForwarderName, setFreightForwarderName] = useState('');

  const [postalAddressConsignor, setPostalAddressConsignor] = useState<PostalAddressInterface>({
    StreetName: '',
    CityName: '',
    CountryID: '',
    PostCode: '',
  });

  const [postalAddressConsignee, setPostalAddressConsignee] = useState<PostalAddressInterface>({
    StreetName: '',
    CityName: '',
    CountryID: 'BR',
    PostCode: '',
  });

  const [postalAddressFreightForwarder, setPostalAddressFreightForwarder] = useState<PostalAddressInterface>({
    StreetName: '',
    CityName: '',
    CountryID: '',
    PostCode: '',
  });

  const [originAirport, setOriginAirport] = useState('');
  const [destinationAirport, setDestinationAirport] = useState('');

  const [paymentMethodCode, setPaymentMethodCode] = useState('');
  const [weightCode, setWeightCode] = useState('KG');

  const [consignmentItems, setConsignmentItems] = useState<ConsignmentItemsInterface[]>([]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });

  //const [options, setOptions] = useState();

  const [checkedSpecialHandling, setCheckedSpecialHandling] = useState<SpecialHandling[]>([]);
  const [checkedHandlingSSROSI, setCheckedHandlingSSROSI] = useState<HandlingSSROSI[]>([]);
  //const [checkedHandlingOSI, setCheckedHandlingOSI] = useState([]);
  const iconSize = 15;

  const setListAirports = useCallback(async () => {
    const response = await listAirports();

    const newArray = response.data.map((objeto) => {
      objeto.label = `${objeto.Abreviatura} ${objeto.Nome === null ? '' : ' - ' + objeto.Nome}`;
      objeto.value = objeto.Abreviatura;
      return objeto;
    });
    setAirportsList(newArray);
    //setOptions(newArray);
  }, []);

  const setListRecintos = useCallback(async () => {
    const response = await getRecintos();

    const newArray = response.data.map((objeto) => {
      objeto.label = `${objeto.RecintoAlfandegadoId} ${objeto.RecintoAlfandegadoId === null ? '' : ' - ' + objeto.Descricao}`;
      objeto.value = objeto.RecintoAlfandegadoId;
      return objeto;
    });

    setRecintosList(newArray);
  }, []);

  const setListCountries = useCallback(async () => {
    const response = await listCountries();
    setCountriesList(response?.data);
  }, []);


  const loadCurrency = useCallback(async () => {
    const response = await listCurrency();
    setCurrencyList(response?.data);
  }, []);


  const loadSpecialHandling = useCallback(async () => {
    const response = await getSpecialHandling();

    const newArray = response.data.map((objeto) => {
      objeto.DescriptionCode = objeto.sigla;
      objeto.Description = objeto?.descricao?.substring(0, 70);
      return objeto;
    });

    setSpecialHandlingList(newArray);
  }, []);

  const loadDocument = async (id: string, documentnumber: string) => {
    const response = await getHouseWaybill(id, documentnumber);

    const houseWayBill = response?.data;

    setMasterNumber(houseWayBill.MasterConsignment.MasterNumber);
    setHouseNumber(houseWayBill.MasterConsignment.IncludedHouseConsignment.HouseNumber);
    setDateOfIssue(houseWayBill.DateIssue.substring(0, 10));
    setConsignorName(houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsignorParty.Name);
    setPostalAddressConsignor({
      StreetName: houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsignorParty.PostalStructuredAddress.StreetName,
      CityName: houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsignorParty.PostalStructuredAddress.CityName,
      CountryID: houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsignorParty.PostalStructuredAddress.CountryID,
      PostCode: houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsignorParty.PostalStructuredAddress.PostCode,
    });

    setConsigneeName(houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsigneeParty.Name);
    setConsigneeCNPJ(houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsigneeParty.CNPJ);
    setPostalAddressConsignee({
      StreetName: houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsigneeParty.PostalStructuredAddress.StreetName,
      CityName: houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsigneeParty.PostalStructuredAddress.CityName,
      CountryID: houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsigneeParty.PostalStructuredAddress.CountryID,
      PostCode: houseWayBill.MasterConsignment.IncludedHouseConsignment.ConsigneeParty.PostalStructuredAddress.PostCode,
    });

    setFreightForwarderName(houseWayBill.MasterConsignment.IncludedHouseConsignment?.FreightForwarderParty?.Name);
    setPostalAddressFreightForwarder({
      StreetName: houseWayBill.MasterConsignment.IncludedHouseConsignment.FreightForwarderParty?.PostalStructuredAddress.StreetName,
      CityName: houseWayBill.MasterConsignment.IncludedHouseConsignment.FreightForwarderParty?.PostalStructuredAddress.CityName,
      CountryID: houseWayBill.MasterConsignment.IncludedHouseConsignment.FreightForwarderParty?.PostalStructuredAddress.CountryID,
      PostCode: houseWayBill.MasterConsignment.IncludedHouseConsignment.FreightForwarderParty?.PostalStructuredAddress.PostCode,
    });

    setCurrencyCode(houseWayBill.MasterConsignment.IncludedHouseConsignment.CurrencyCode);
    setWeightTotalAmount(formatNumberBR(houseWayBill.MasterConsignment.IncludedHouseConsignment.WeightTotalChargeAmount));
    setValuationChargeAmount(formatNumberBR(houseWayBill.MasterConsignment.IncludedHouseConsignment.ValuationChargeAmount));
    setTotalChargeAmount(formatNumberBR(houseWayBill.MasterConsignment.IncludedHouseConsignment.TotalChargeAmount));
    setAgentTotalAmount(formatNumberBR(houseWayBill.MasterConsignment.IncludedHouseConsignment.AgentTotalAmount));
    setCarrierTotalAmount(formatNumberBR(houseWayBill.MasterConsignment.IncludedHouseConsignment.CarrierTotalAmount));
    setTaxTotalAmount(formatNumberBR(houseWayBill.MasterConsignment.IncludedHouseConsignment.TaxTotalAmount));
    setSummaryDescription(houseWayBill.MasterConsignment.IncludedHouseConsignment.SummaryDescription);
    setPaymentMethodCode(houseWayBill.MasterConsignment.IncludedHouseConsignment.TotalChargeIndicator);
    setWeightCode(houseWayBill.MasterConsignment.IncludedHouseConsignment.WeightCode === "KGM" ? "KG" : "LB")

    setWoodParts(houseWayBill.MasterConsignment.IncludedHouseConsignment.WoodParts);

    setOriginAirport(houseWayBill.MasterConsignment.IncludedHouseConsignment.OriginLocation);
    setDestinationAirport(houseWayBill.MasterConsignment.IncludedHouseConsignment.FinalDestinationLocation);

    const origin = houseWayBill.MasterConsignment.IncludedHouseConsignment.OriginLocation;
    const destination = houseWayBill.MasterConsignment.IncludedHouseConsignment.FinalDestinationLocation;

    setValueOriginAirport({ label: origin, value: origin });
    setValueDestinationAirport({ label: destination, value: destination });

    setSignatoryConsignor(houseWayBill.BusinessHeaderDocument.SignatoryConsignor.Signatory);
    setSignatoryCarrier(houseWayBill.BusinessHeaderDocument.SignatoryCarrier.Signatory);

    const recinto = houseWayBill.MasterConsignment.IncludedHouseConsignment.RecintoAduaneiro;
    setRecinto(recinto);
    setValueRecinto({ label: recinto, value: recinto });

    let counter = 0;
    const checkedSpecialHandlingNew = houseWayBill.MasterConsignment.IncludedHouseConsignment.HandlingSPHInstructions.map((handling) => {
      handling.id = counter;
      counter++;
      return handling;
    });
    setCheckedSpecialHandling(checkedSpecialHandlingNew);

    const consignmentItemsList = houseWayBill.MasterConsignment.IncludedHouseConsignment.IncludedHouseConsignmentItem.map((item) => {
      return {
        ...item,
        PieceQuantity: formatNumberBR(item.PieceQuantity),
        GrossVolumeMeasure: formatNumberBR(item.GrossVolumeMeasure),
        GrossWeightMeasure: formatNumberBR(item.GrossWeightMeasure),
        ChargeableWeightMeasure: formatNumberBR(item.ChargeableWeightMeasure),
      }
    })
    setConsignmentItems(consignmentItemsList);

    counter = 0;
    const handlingSSROSIInstructionsNew = houseWayBill.MasterConsignment.IncludedHouseConsignment.HandlingSSROSIInstructions.map((handling) => {
      handling.id = counter;
      counter++;
      return handling;
    });
    setCheckedHandlingSSROSI(handlingSSROSIInstructionsNew);
  }

  const { id, documentnumber } = useParams<UrlParams>();

  useEffect(() => {

    if (id) {
      setTrashHidden(true);
      loadDocument(id, documentnumber);
    }

    setListRecintos();
    setListAirports();
    setListCountries();
    loadCurrency();
    loadSpecialHandling();
    handleAddConsignmentItems();
  }, [location]);

  useEffect(() => {
    loadCompanyWithCNPJ();
  }, [consigneeCNPJ])

  async function loadCompanyWithCNPJ() {
    try {
      const response = await getCompanyWithCNPJ(consigneeCNPJ);
      const consigneeParty = response?.data;

      if (response.status === 200) {
        setConsigneeName(consigneeParty.Name);
        setPostalAddressConsignee({
          StreetName: consigneeParty.PostalStructuredAddress.StreetName,
          CityName: consigneeParty.PostalStructuredAddress.CityName,
          CountryID: consigneeParty.PostalStructuredAddress.CountryID,
          PostCode: consigneeParty.PostalStructuredAddress.PostCode,
        });
      }
    }
    catch { }
  }


  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      setOpen({ open: false, severity: '', message: '' });
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  const handleChangePostalAddresConsignee = (event: any) => {
    let data = postalAddressConsignee;
    // @ts-ignore: Unreachable code error
    data[event.target.name] = event.target.value;

    setPostalAddressConsignee((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  }
  const handleChangePostalAddresFreightForwarder = (event: any) => {
    let data = postalAddressFreightForwarder;
    // @ts-ignore: Unreachable code error
    data[event.target.name] = event.target.value;

    setPostalAddressFreightForwarder((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  }

  const handleChangePostalAddresConsignor = (event: any) => {
    let data = postalAddressConsignor;
    // @ts-ignore: Unreachable code error
    data[event.target.name] = event.target.value;

    setPostalAddressConsignor((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  }

  const handleSubmit = async (e: any, oper: string) => {
    e.preventDefault();
    setLoading(true);

    const consignmentItemsList = consignmentItems.map((item) => {
      return {
        ...item,
        PieceQuantity: formatNumber(item.PieceQuantity),
        GrossVolumeMeasure: formatNumber(item.GrossVolumeMeasure),
        GrossWeightMeasure: formatNumber(item.GrossWeightMeasure),
        ChargeableWeightMeasure: formatNumber(item.ChargeableWeightMeasure),
      };
    })

    const waybill = {
      IssuerCNPJ: localStorage.getItem("company"),
      Operation: oper === '' ? 'Creation' : oper,
      BusinessHeaderDocument: {
        SignatoryConsignor: {
          Signatory: signatoryConsignor
        },
        SignatoryCarrier: {
          Signatory: signatoryCarrier,
        }
      },
      DateIssue: dateOfIssue,
      MasterConsignment: {
        MasterNumber: masterNumber,
        IncludedHouseConsignment: {
          HouseNumber: houseNumber.trim(),
          WeightTotalChargeAmount: formatNumber(weightTotalAmount),
          TotalChargeAmount: formatNumber(totalChargeAmount),
          ValuationChargeAmount: formatNumber(valuationChargeAmount),
          NilCarriageValueIndicator: "true",
          CurrencyCode: currencyCode,
          TotalChargeIndicator: paymentMethodCode,
          CarrierTotalAmount: formatNumber(carrierTotalAmount),
          AgentTotalAmount: formatNumber(agentTotalAmount),
          TaxTotalAmount: formatNumber(taxTotalAmount),
          SummaryDescription: summaryDescription,
          //GrossVolumeMeasure: formatNumber(grossVolume),
          WeightCode: weightCode === "KG" ? "KGM" : "LBR",
          WoodParts: woodParts,
          RecintoAduaneiro: recinto,
          ConsignorParty: {
            Name: consignorName,
            PostalStructuredAddress: postalAddressConsignor
          },
          ConsigneeParty: {
            Name: consigneeName,
            CNPJ: consigneeCNPJ,
            PostalStructuredAddress: postalAddressConsignee
          },
          FreightForwarderParty: {
            Name: freightForwarderName,
            PostalStructuredAddress: postalAddressFreightForwarder
          },
          OriginLocation: originAirport,
          FinalDestinationLocation: destinationAirport,
          IncludedHouseConsignmentItem: consignmentItemsList,
          HandlingSPHInstructions: checkedSpecialHandling,
          HandlingSSROSIInstructions: checkedHandlingSSROSI
        }
      }
    };

    try {
      const response = await sendHouseWaybill(waybill);

      setTrashHidden(false);
      setMasterNumber('');
      setDateOfIssue('');
      setHouseNumber('');
      setSignatoryCarrier('');
      setSignatoryConsignor('');
      setCurrencyCode('');
      setWeightTotalAmount('');
      setTotalChargeAmount('');
      setCarrierTotalAmount('');
      setTaxTotalAmount('');
      setAgentTotalAmount('');
      setConsignorName('');
      setConsigneeName('');
      setFreightForwarderName('');
      setConsigneeCNPJ('');
      // setIncludedTareGrossWeightMeasureHouse('');
      // setTotalPieceQuantityHouse('');
      setSummaryDescription('');
      setPostalAddressConsignor({
        StreetName: '',
        CityName: '',
        CountryID: '',
        PostCode: '',
      });
      setPostalAddressConsignee({
        StreetName: '',
        CityName: '',
        CountryID: 'BR',
        PostCode: '',
      });
      setPostalAddressFreightForwarder({
        StreetName: '',
        CityName: '',
        CountryID: '',
        PostCode: '',
      });
      setOriginAirport('');
      setDestinationAirport('');
      setPaymentMethodCode('');
      setValuationChargeAmount('');
      setWeightCode('KG');
      setConsignmentItems([{
        GrossWeightMeasure: 0,
        GrossVolumeMeasure: 0,
        PieceQuantity: 0,
        Identification: '',
        ChargeableWeightMeasure: 0,
        NCM: '',
      }]);
      setWoodParts(false);
      setValueRecinto({});
      setValueOriginAirport({});
      setValueDestinationAirport({});
      setCheckedSpecialHandling([]);
      setCheckedHandlingSSROSI([]);
      setRecinto('');

      setOpen({ open: true, severity: 'success', message: response?.data.Mensagem });
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(error?.response?.data?.erro) });
      }
      else if (error?.name === 'Error') {
        const message = error?.response?.data?.erro ? error?.response?.data?.erro : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = t('networkError');
        setOpen({ open: true, severity: 'error', message: message });
      }
    }

    setLoading(false);
  }


  function handleAddConsignmentItems() {
    let newfield = {
      GrossWeightMeasure: 0,
      GrossVolumeMeasure: 0,
      PieceQuantity: 0,
      Identification: '',
      ChargeableWeightMeasure: 0,
    }
    setConsignmentItems([...consignmentItems, newfield]);
  }

  const handleFormChange = (index: number, event: any) => {
    let data = [...consignmentItems];
    // @ts-ignore: Unreachable code error
    data[index][event.target.name] = event.target.value;
    setConsignmentItems(data);
  }

  function handleRemoveConsignmentItems(index: number) {
    let data = [...consignmentItems];
    data.splice(index, 1);
    setConsignmentItems(data);
  }

  // function handleSelectDestinationAirport(data: any) {
  //   setSelectedDestinationAirport(data);
  //   setDestinationAirport(data.Abreviatura);
  // }

  const createOption = (label: string) => ({
    label,
    value: label.replace(/\W/g, ''),
  });

  const createOptionSpecialHandling = (label: string, id: number) => ({
    id,
    label,
    value: label.replace(/\W/g, ''),
    DescriptionCode: label,
    description: label,
  });


  const [valueDestinationAirport, setValueDestinationAirport] = useState();

  const handleCreateDestination = (inputValue: string) => {
    const newOption = createOption(inputValue);
    //setOptions((prev) => [...prev, newOption]);
    setValueDestinationAirport(newOption);
    setDestinationAirport(newOption?.value);
  };

  function handleChangeDestinationAirport(event) {
    setValueDestinationAirport(event);
    setDestinationAirport(event?.value);
  }

  const [valueOriginAirport, setValueOriginAirport] = useState();

  const handleCreateOrigin = (inputValue: string) => {
    const newOption = createOption(inputValue);
    //setOptions((prev) => [...prev, newOption]);
    setValueOriginAirport(newOption);
    setOriginAirport(newOption?.value);
  };

  function handleChangeOriginAirport(event) {
    setValueOriginAirport(event);
    setOriginAirport(event?.value);
  }

  const [valueRecinto, setValueRecinto] = useState();

  const handleCreateRecinto = (inputValue: string) => {
    const newOption = createOption(inputValue);
    //setOptions((prev) => [...prev, newOption]);
    setValueRecinto(newOption);
    setRecinto(newOption?.value);
  };

  function handleChangeRecinto(event) {
    setValueRecinto(event);
    setRecinto(event?.value);
  }


  //const [valueConsigneeCountry, setValueConsigneeCountry] = useState();

  useEffect(() => {
    setTotalChargeAmount(formatNumberBR(Number(formatNumber(weightTotalAmount)) + Number(formatNumber(taxTotalAmount)) + Number(formatNumber(agentTotalAmount)) + Number(formatNumber(carrierTotalAmount)) + Number(formatNumber(valuationChargeAmount))));
  }, [weightTotalAmount, agentTotalAmount, carrierTotalAmount, valuationChargeAmount, taxTotalAmount]);

  //const forwarderRequired = freightForwarderName != '' ? true : false;

  function handleDeleteDocument(e: any) {
    setOpenModal(false);
    handleSubmit(e, 'Deletion');
  }

  const handleAddRowSpecialHandling = () => {
    setCheckedSpecialHandling([...checkedSpecialHandling, { id: checkedSpecialHandling.length + 1, DescriptionCode: '', Description: '' }]);
  };

  const handleSiglaSpecialHandling = (event, id) => {
    const value = event?.target.value;

    const descricaoSigla = specialHandlingList.filter((specialHandling) => specialHandling.DescriptionCode === value);
    const updatedRows = checkedSpecialHandling.map((row) =>
      row.id === id ? { ...row, Description: descricaoSigla[0]?.Description, DescriptionCode: value } : row
    );
    setCheckedSpecialHandling(updatedRows);
  }

  const handleAddRowHandlingSSROSI = () => {
    setCheckedHandlingSSROSI([...checkedHandlingSSROSI, { id: checkedHandlingSSROSI.length + 1, DescriptionCode: '', Description: '', Type: 'SSR' }]);
  };

  const handleSiglaHandlingSSROSI = (event, id, campo) => {
    const value = event?.target.value;
    if (campo === 'Type') {
      const updatedRows = checkedHandlingSSROSI.map((row) =>
        row.id === id ? { ...row, Type: value } : row
      );

      setCheckedHandlingSSROSI(updatedRows);
    }

    if (campo === 'DescriptionCode') {
      const descricaoSigla = specialHandlingList.filter((specialHandling) => specialHandling.DescriptionCode === value);
      const updatedRows = checkedHandlingSSROSI.map((row) =>
        row.id === id ? { ...row, Description: descricaoSigla[0]?.Description, DescriptionCode: value } : row
      );
      setCheckedHandlingSSROSI(updatedRows);
    }
  }


  const handleCellEdit = (id, field, value) => {
    if (field === 'code') {
      const descricaoSigla = specialHandlingList.filter((specialHandling) => specialHandling.DescriptionCode === value);

      const updatedRows = checkedSpecialHandling.map((row) =>
        row.id === id ? { ...row, Description: descricaoSigla[0]?.Description, DescriptionCode: value } : row
      );

      setCheckedSpecialHandling(updatedRows);
    }
    else {
      const updatedRows = checkedSpecialHandling.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      setCheckedSpecialHandling(updatedRows);
    }

  };


  const handleCellEditHandlingSSROSI = (id, field, value) => {
    if (field === 'code') {
      const descricaoSigla = specialHandlingList.filter((specialHandling) => specialHandling.DescriptionCode === value);

      const updatedRows = checkedHandlingSSROSI.map((row) =>
        row.id === id ? { ...row, Description: descricaoSigla[0]?.Description, DescriptionCode: value } : row
      );

      setCheckedHandlingSSROSI(updatedRows);
    }
    else {
      const updatedRows = checkedHandlingSSROSI.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      setCheckedHandlingSSROSI(updatedRows);
    }

  };

  const handleRemoveSpecialHandling = (id: number) => {
    const descricaoSigla = checkedSpecialHandling.filter((row) => row.id !== id);
    setCheckedSpecialHandling(descricaoSigla);
  }

  const handleRemoveHandlingSSROSI = (id: number) => {
    const descricaoSigla = checkedHandlingSSROSI.filter((row) => row.id !== id);
    setCheckedHandlingSSROSI(descricaoSigla);
  }



  const [inputValue, setInputValue] = useState('');
  const [inputId, setInputId] = useState();

  const handleCreateSpecialHandling = (inputValue, id) => {
    setInputValue(inputValue);
    setInputId(id);

    const newOption = createOptionSpecialHandling(inputValue, specialHandlingList.length + 1);
    setSpecialHandlingList((prev) => [...prev, newOption]);
  }

  useEffect(() => {
    const updatedRows = checkedSpecialHandling.map((row) =>
      row.id === inputId ? { ...row, Description: '', DescriptionCode: inputValue } : row
    );

    setCheckedSpecialHandling(updatedRows);
  }, [specialHandlingList]);

  return (
    <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={9}>
          <Card>

            <span className={classes.textTitle}>HAWB - FZB</span>
            <form onSubmit={handleSubmit}>
              <CardBody className={classes.container}>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Conhecimento</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>MAWB</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 12 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={masterNumber} className={classes.input} onChange={e => setMasterNumber(formatDocumentNumber(e.target.value))} type='text' required maxLength={12} />
                    </div>
                    {/* <Input title="MAWB" required={true} classes={classes} value={masterNumber} onchange={setMasterNumber} funcaoFormatacao={formatDocumentNumber} maxlength={12} /> */}

                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>HAWB</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 11 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={houseNumber} className={classes.input} onChange={e => setHouseNumber(e.target.value)} type='text' required maxLength={11} />
                    </div>

                    {/* warningDateOfIssue */}
                    {/* <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Data de Emissão</span>
                        {console.log(warningDateOfIssue)}
                        {warningDateOfIssue && (
                          <Tooltip title={<span>Data de emissão do conhecimento deve ser menor ou igual a data atual</span>}>
                            <WarningIcon sx={{ fontSize: 20, color: 'red' }} />
                          </Tooltip>

                        )}
                      </div>
                      <input value={dateOfIssue} className={classes.input} onChange={e => setDateOfIssue(e.target.value)} type="date" required />
                    </div> */}

                    <Input title="Data de Emissão" required={true} classes={classes} value={dateOfIssue} onchange={setDateOfIssue} type="date" />
                  </div>
                </div>


                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Embarcador Estrangeiro</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <div className={classes.iconWithTitle}>
                        <span>Nome</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={consignorName} className={classes.input} onChange={e => setConsignorName(e.target.value)} type='text' required maxLength={70} />
                    </div>
                  </div>

                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <div className={classes.iconWithTitle}>
                        <span>Endereço</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input name="StreetName" value={postalAddressConsignor?.StreetName} className={classes.input} onChange={event => handleChangePostalAddresConsignor(event)} type='text' required maxLength={70} />
                    </div>
                  </div>

                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Cidade</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input name="CityName" value={postalAddressConsignor?.CityName} className={classes.input} onChange={event => handleChangePostalAddresConsignor(event)} type='text' required maxLength={70} />
                    </div>

                    <div className={classes.inputBox}>
                      <span>CEP</span>
                      <input name="PostCode" value={postalAddressConsignor?.PostCode} className={classes.input} onChange={e => handleChangePostalAddresConsignor(e)} type='text' maxLength={70} />
                    </div>

                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>País</span>
                        <Tooltip title={<span>Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <select name="CountryID" className={classes.input} value={postalAddressConsignor?.CountryID} onChange={e => { handleChangePostalAddresConsignor(e) }} required >
                        <option></option>
                        {countriesList.map((country) => (
                          <option key={country?.id} value={country?.Sigla}>{country?.Nome}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Consignatário</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>CNPJ/CPF/Passaporte</span>
                        <Tooltip title={<span>Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={consigneeCNPJ} className={classes.input} onChange={e => setConsigneeCNPJ(e.target.value)} type='text' required />
                    </div>
                  </div>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <div className={classes.iconWithTitle}>
                        <span>Nome</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={consigneeName} className={classes.input} onChange={e => setConsigneeName(e.target.value)} type='text' maxLength={70} required />
                    </div>

                    {/* <Input title="Nome" required={true} classes={classes} value={consigneeName} onchange={setConsigneeName} styleWidth="100%" maxlength={70} /> */}
                  </div>

                  <div className={classes.inputs} >
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <div className={classes.iconWithTitle}>
                        <span>Endereço</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input name="StreetName" value={postalAddressConsignee?.StreetName} className={classes.input} onChange={e => handleChangePostalAddresConsignee(e)} type='text' required maxLength={70} />
                    </div>
                  </div>


                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Cidade</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input name="CityName" value={postalAddressConsignee?.CityName} className={classes.input} onChange={e => handleChangePostalAddresConsignee(e)} type='text' required maxLength={70} />
                    </div>

                    <div className={classes.inputBox}>
                      <span>CEP</span>
                      <input name="PostCode" value={postalAddressConsignee?.PostCode} className={classes.input} onChange={e => handleChangePostalAddresConsignee(e)} type='text' maxLength={70} />
                    </div>

                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>País</span>
                        <Tooltip title={<span>Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <select name="CountryID" className={classes.input} value={postalAddressConsignee?.CountryID} onChange={e => { handleChangePostalAddresConsignee(e) }} required>
                        <option></option>
                        {countriesList.map((country) => (
                          <option key={country?.id} value={country?.Sigla}>{country?.Nome}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Agente de Carga - Consolidador Estrangeiro</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <div className={classes.iconWithTitle}>
                        <span>Nome</span>
                        <Tooltip title={<span>Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={freightForwarderName} className={classes.input} onChange={e => setFreightForwarderName(e.target.value)} type='text' maxLength={70} />
                    </div>
                  </div>

                  <div className={classes.inputs} >
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <div className={classes.iconWithTitle}>
                        <span>Endereço</span>
                        <Tooltip title={<span>Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input name="StreetName" value={postalAddressFreightForwarder?.StreetName} className={classes.input} onChange={e => handleChangePostalAddresFreightForwarder(e)} type='text' maxLength={70} />
                    </div>
                  </div>

                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Cidade</span>
                        <Tooltip title={<span>Limite 70 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input name="CityName" value={postalAddressFreightForwarder?.CityName} className={classes.input} onChange={e => handleChangePostalAddresFreightForwarder(e)} type='text' maxLength={70} />
                    </div>

                    <div className={classes.inputBox}>
                      <span>CEP</span>
                      <input name="PostCode" value={postalAddressFreightForwarder?.PostCode} className={classes.input} onChange={e => handleChangePostalAddresFreightForwarder(e)} type='text' maxLength={70} />
                    </div>

                    <div className={classes.inputBox}>
                      <span>País</span>
                      <select name="CountryID" className={classes.input} value={postalAddressFreightForwarder?.CountryID} onChange={e => { handleChangePostalAddresFreightForwarder(e) }} >
                        <option></option>
                        {countriesList.map((country) => (
                          <option key={country?.id} value={country?.Sigla}>{country?.Nome}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Aeroportos</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Origem</span>
                        <Tooltip title={<span>Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <CreatableSelect
                        placeholder={t('selectAirport')}
                        isClearable
                        onChange={handleChangeOriginAirport}
                        onCreateOption={handleCreateOrigin}
                        options={airportsList}
                        value={valueOriginAirport}
                        className={classes.inputSelect}
                        styles={customStyles}
                        isRequired
                      />
                    </div>

                    <div className={classes.inputBox}>
                      <div className={classes.inputBox}>
                        <div className={classes.iconWithTitle}>
                          <span>Destino</span>
                          <Tooltip title={<span>Obrigatório</span>}>
                            <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                          </Tooltip>
                        </div>

                        <CreatableSelect
                          placeholder={t('selectAirport')}
                          isClearable
                          onChange={handleChangeDestinationAirport}
                          onCreateOption={handleCreateDestination}
                          options={airportsList}
                          value={valueDestinationAirport}
                          className={classes.inputSelect}
                          styles={customStyles}
                          isRequired
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Recinto Aduaneiro de Destino</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '50%' }}>
                      <div className={classes.iconWithTitle}>
                        <span>Código</span>
                      </div>
                      <CreatableSelect
                        placeholder={t('selectAirport')}
                        isClearable
                        onChange={handleChangeRecinto}
                        onCreateOption={handleCreateRecinto}
                        options={recintosList}
                        value={valueRecinto}
                        className={classes.inputSelect}
                        styles={customStyles}
                        isRequired
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <div className={classes.iconWithTitle}>
                        <span>Descrição Resumida das Mercadorias</span>
                        <Tooltip title={<span>Obrigatório <br /> Limite 600 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={summaryDescription} className={classes.input} placeholder="" onChange={e => setSummaryDescription(e.target.value)} type='text' required maxLength={600} />
                    </div>

                    {/* <Input title="Descrição Resumida das Mercadorias" required={true} classes={classes} value={summaryDescription} onchange={setSummaryDescription} styleWidth="100%" /> */}
                  </div>
                  <div className={classes.inputs}>

                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' }}>
                        <input type="checkbox" onChange={(e) => setWoodParts(e.target.checked)} checked={woodParts} />
                        <span>Indicador de presença de partes e peças de madeira maciça</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Itens de Carga</span>
                  <div className={classes.inputBox}>
                    <div className={classes.iconWithTitle}>
                      <span>Unidade do Peso</span>
                      <Tooltip title={<span>Obrigatório</span>}>
                        <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                      </Tooltip>
                    </div>
                    <select className={classes.input} value={weightCode} onChange={e => { setWeightCode(e.target.value) }} required >
                      <option key="KGM" value="KG">KG</option>
                      <option key="LBR" value="LB">LB</option>
                    </select>
                  </div>
                  <span className={classes.spanLink} onClick={handleAddConsignmentItems}>
                    <Tooltip title="Incluir Item">
                      <AddIcon />
                    </Tooltip>
                  </span>
                </div>

                {consignmentItems !== undefined && consignmentItems.map((input: any, index: any) => {
                  return (
                    <div key={index} className={classes.inputGroup}>
                      <span className={classes.titleInputGroup}>Item {index + 1}</span>
                      <span className={classes.trash} onClick={() => handleRemoveConsignmentItems(index)} >
                        <Tooltip title="Deletar Item">
                          <DeleteIcon />
                        </Tooltip>
                      </span>
                      <div className={classes.inputs}>
                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>NCM</span>
                            <Tooltip title={<span>Limite 8 caracteres</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          <input name="NCM" value={input.NCM} className={classes.input} placeholder="" onChange={event => handleFormChange(index, event)} type='text' maxLength={8} />
                        </div>
                      </div>
                      <div className={classes.inputs}>

                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>Peso Bruto</span>
                            <Tooltip title={<span>Obrigatório <br /> 3 casas decimais</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          <NumericFormat
                            name="GrossWeightMeasure"
                            value={input.GrossWeightMeasure}
                            className={classes.input}
                            fixedDecimalScale
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={3}
                            allowNegative={false}
                            onChange={event => handleFormChange(index, event)}
                            placeholder='0,000'
                            style={{ textAlign: 'right' }}
                            required
                          />
                        </div>

                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>Volume (cubagem)</span>
                            <Tooltip title={<span> 3 casas decimais</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>

                            {formatNumber(input.GrossVolumeMeasure) < 0.010 && formatNumber(input.GrossVolumeMeasure) > 0 ?
                              <Tooltip title={<span> Valor deve ser maior que 0,010</span>}>
                                <WarningIcon style={{ color: 'red' }} />
                              </Tooltip> : <></>}
                          </div>

                          <NumericFormat
                            name="GrossVolumeMeasure"
                            value={input.GrossVolumeMeasure}
                            className={classes.input}
                            fixedDecimalScale
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={3}
                            allowNegative={false}
                            onChange={event => handleFormChange(index, event)}
                            placeholder='0,000'
                            style={{ textAlign: 'right' }}
                          />
                          {/* <input name="GrossVolumeMeasure" value={input.GrossVolumeMeasure} className={classes.input} placeholder="0,00" onChange={event => handleFormChange(index, event)} type='text' /> */}
                        </div>

                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>Quantidade de Volumes</span>
                            <Tooltip title={<span> Obrigatório <br /> 3 casas decimais</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          <NumericFormat
                            name="PieceQuantity"
                            value={input.PieceQuantity}
                            className={classes.input}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={0}
                            allowNegative={false}
                            onChange={event => handleFormChange(index, event)}
                            placeholder='0'
                            style={{ textAlign: 'right' }}
                            required
                          />
                          {/* <input name="PieceQuantity" value={input.PieceQuantity} className={classes.input} placeholder="0" onChange={event => handleFormChange(index, event)} type='text' required /> */}
                        </div>

                        <div className={classes.inputBox}>
                          <div className={classes.iconWithTitle}>
                            <span>Peso Taxado</span>
                            <Tooltip title={<span> Obrigatório <br /> 3 casas decimais</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          <NumericFormat
                            name="ChargeableWeightMeasure"
                            value={input.ChargeableWeightMeasure}
                            className={classes.input}
                            fixedDecimalScale
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={3}
                            allowNegative={false}
                            onChange={event => handleFormChange(index, event)}
                            placeholder='0,000'
                            style={{ textAlign: 'right' }}
                            required
                          />
                          {/* <input name="ChargeableWeightMeasure" value={input.ChargeableWeightMeasure} className={classes.input} placeholder="0,00" onChange={event => handleFormChange(index, event)} type='text' required /> */}
                        </div>
                      </div>

                      <div className={classes.inputs} >
                        <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                          <div className={classes.iconWithTitle}>
                            <span>Descrição da Mercadoria</span>
                            <Tooltip title={<span> Obrigatório <br /> Limite 70 caracteres</span>}>
                              <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                            </Tooltip>
                          </div>
                          <input name="Identification" value={input.Identification} className={classes.input} placeholder="" onChange={event => handleFormChange(index, event)} type='text' required maxLength={70} />
                        </div>
                      </div>
                    </div>
                  )
                })}

                <div className={classes.separator}></div>
                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Totais na Moeda de Origem</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Forma de Pagamento</span>
                        <Tooltip title={<span> Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <select className={classes.input} value={paymentMethodCode} onChange={e => { setPaymentMethodCode(e.target.value) }} required>
                        <option></option>
                        <option key="PP" value="PP">PREPAID</option>
                        <option key="CC" value="CC">COLLECT</option>
                      </select>
                    </div>

                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Moeda de Origem</span>
                        <Tooltip title={<span> Obrigatório</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <select className={classes.input} value={currencyCode} onChange={e => { setCurrencyCode(e.target.value) }} required>
                        <option></option>
                        {currencyList.map((currency) => (
                          <option key={currency?.moedaid} value={currency?.simbolo_moeda}>{currency?.simbolo_moeda}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className={classes.inputs}>
                    {/* <NumericFormat
                      value={weightTotalAmount}
                      className={classes.input}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      allowNegative={false}
                      onChange={e => { setWeightTotalAmount(e.target.value) }}
                      placeholder='0,00'
                      style={{ textAlign: 'right' }}
                      required
                    />
                     */}
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Por Peso</span>
                        <Tooltip title={<span>Obrigatório <br />2 casas decimais</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <NumericFormat
                        value={weightTotalAmount === '0' ? '0,00' : weightTotalAmount}
                        className={classes.input}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        decimalScale={2}
                        allowNegative={false}
                        onChange={e => setWeightTotalAmount(e.target.value)}
                        placeholder={"0,00"}
                        style={{ textAlign: 'right' }}
                        required
                      />
                    </div>
                    {/* <Input numeric title={`Por Peso`} placeholder="0,00" classes={classes} value={weightTotalAmount} onchange={setWeightTotalAmount} required={true} decimalScale={2} /> */}

                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Por Valor</span>
                        <Tooltip title={<span>2 casas decimais</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <NumericFormat
                        value={valuationChargeAmount === '0' ? '0,00' : valuationChargeAmount}
                        className={classes.input}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        decimalScale={2}
                        allowNegative={false}
                        onChange={e => setValuationChargeAmount(e.target.value)}
                        placeholder={"0,00"}
                        style={{ textAlign: 'right' }}
                      />
                    </div>

                    {/* <Input numeric title={`Por Valor`} placeholder="0,00" classes={classes} value={valuationChargeAmount} onchange={setValuationChargeAmount} decimalScale={2} /> */}
                  </div>

                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Outros Serviços (Agente de Carga)</span>
                        <Tooltip title={<span>2 casas decimais</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <NumericFormat
                        value={agentTotalAmount === '0' ? '0,00' : agentTotalAmount}
                        className={classes.input}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        decimalScale={2}
                        allowNegative={false}
                        onChange={e => setAgentTotalAmount(e.target.value)}
                        placeholder={"0,00"}
                        style={{ textAlign: 'right' }}
                      />
                    </div>
                    {/* <Input numeric title={`Outros Serviços (Agente de Carga)`} placeholder="0,00" classes={classes} value={agentTotalAmount} onchange={setAgentTotalAmount} decimalScale={2} /> */}

                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Outros Serviços (Transportador)</span>
                        <Tooltip title={<span>2 casas decimais</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <NumericFormat
                        value={carrierTotalAmount === '0' ? '0,00' : carrierTotalAmount}
                        className={classes.input}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        decimalScale={2}
                        allowNegative={false}
                        onChange={e => setCarrierTotalAmount(e.target.value)}
                        placeholder={"0,00"}
                        style={{ textAlign: 'right' }}
                      />
                    </div>
                    {/* <Input numeric title={`Outros Serviços (Transportador)`} placeholder="0,00" classes={classes} value={carrierTotalAmount} onchange={setCarrierTotalAmount} decimalScale={2} /> */}
                  </div>

                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Impostos</span>
                        <Tooltip title={<span>2 casas decimais</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <NumericFormat
                        value={taxTotalAmount === '0' ? '0,00' : taxTotalAmount}
                        className={classes.input}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        decimalScale={2}
                        allowNegative={false}
                        onChange={e => setTaxTotalAmount(e.target.value)}
                        placeholder={"0,00"}
                        style={{ textAlign: 'right' }}
                      />
                    </div>

                    {/* <Input numeric title={`Impostos`} placeholder="0,00" required={true} classes={classes} value={taxTotalAmount} onchange={setTaxTotalAmount} decimalScale={2} /> */}

                  </div>

                  <div className={classes.inputs} style={{ fontWeight: 'bold' }}>
                    <div className={classes.inputBox}>
                      <div className={classes.iconWithTitle}>
                        <span>Total</span>
                        <Tooltip title={<span>Obrigatório <br />2 casas decimais</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <NumericFormat
                        //value={Number(formatNumber(weightTotalAmount)) + Number(formatNumber(agentTotalAmount)) + Number(formatNumber(carrierTotalAmount)) + Number(formatNumber(valuationChargeAmount)) + Number(formatNumber(taxTotalAmount))} className={classes.input}
                        value={totalChargeAmount}
                        className={classes.input}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        decimalScale={2}
                        allowNegative={false}
                        onChange={e => setTotalChargeAmount(e.target.value)}
                        placeholder={"0,00"}
                        style={{ textAlign: 'right', fontWeight: 'bold' }}
                        required
                      />
                    </div>

                    {/* <Input numeric title={`Total`} placeholder="0,00" required={true} classes={classes} value={Number(formatNumber(weightTotalAmount)) + Number(formatNumber(agentTotalAmount)) + Number(formatNumber(carrierTotalAmount)) + Number(formatNumber(valuationChargeAmount)) + Number(formatNumber(taxTotalAmount))} onchange={setTotalChargeAmount} decimalScale={2} totalIndicator='bold' /> */}
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Manuseio especial da carga</span>
                  <table style={{ width: '100%' }}>
                    {checkedSpecialHandling.length > 0 && (
                      <tr>
                        <th style={{ width: '25%', textAlign: 'left' }}>Código</th>
                        <th style={{ textAlign: 'left' }}>Detalhe</th>
                        <th style={{ width: '30px' }}>Excluir</th>
                      </tr>
                    )}
                    {checkedSpecialHandling.map((row) => (
                      <tr key={row.id}>
                        <td>
                          {/* <select className={classes.input} onChange={(e) => handleCellEdit(row.id, 'sigla', e.target.value)}>
                            <option></option>
                            {
                              specialHandlingList.map((specialHandling) => (
                                <option key={specialHandling.id}>{specialHandling.sigla}</option>
                              ))
                            }
                          </select> */}
                          <select className={classes.input} value={row.DescriptionCode} onChange={(e) => handleSiglaSpecialHandling(e, row.id)} required>
                            <option></option>
                            {
                              specialHandlingList.map((specialHandling) => (
                                <option key={specialHandling.id}>{specialHandling.DescriptionCode}</option>
                              ))
                            }
                          </select>

                          {/* <CreatableSelect
                            placeholder={t('selectAirport')}
                            isClearable
                            onChange={(e) => handleSiglaSpecialHandling(e, row.id)}
                            // onCreateOption={(e) => handleCreateSpecialHandling(e, row.id)}
                            options={specialHandlingList}
                            value={row}
                            className={classes.inputSelect}
                            styles={customStyles}
                            isRequired
                          /> */}
                        </td>
                        <td>
                          <input
                            maxLength={70}
                            className={classes.input}
                            value={row.Description}
                            onChange={(e) => handleCellEdit(row.id, 'Description', e.target.value)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <span className={classes.trash} onClick={() => handleRemoveSpecialHandling(row.id)} >
                            <Tooltip title="Deletar Item">
                              <DeleteIcon />
                            </Tooltip>
                          </span>
                          {/* <span onClick={handleRemoveSpecialHandling(row.id)}>x</span> */}
                        </td>
                      </tr>
                    ))}
                    <Button onClick={handleAddRowSpecialHandling} style={{ marginTop: '10px' }}>
                      ADICIONAR
                    </Button>
                  </table>
                </div>


                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Solicitação de serviço especial / Outras informações de serviço</span>
                  <table style={{ width: '100%' }}>
                    {checkedHandlingSSROSI.length > 0 && (
                      <tr>
                        <th style={{ width: '30%', textAlign: 'left' }}>Tipo</th>
                        <th style={{ width: '10%', textAlign: 'left' }}>Código</th>
                        <th style={{ textAlign: 'left' }}>Detalhe</th>
                        <th style={{ width: '30px' }}>Excluir</th>
                      </tr>
                    )}
                    {checkedHandlingSSROSI.map((row) => (
                      <tr key={row.id}>
                        <td>
                          <select className={classes.input} value={row.Type} onChange={(e) => handleSiglaHandlingSSROSI(e, row.id, 'Type')}>
                            <option value="SSR">Solicitação de serviço especial</option>
                            <option value="OSI">Outras informações de serviço</option>
                          </select>
                        </td>
                        <td>
                          <select className={classes.input} value={row.DescriptionCode} onChange={(e) => handleSiglaHandlingSSROSI(e, row.id, 'DescriptionCode')}>
                            <option></option>
                            {
                              specialHandlingList.map((specialHandling) => (
                                <option key={specialHandling.id}>{specialHandling.DescriptionCode}</option>
                              ))
                            }
                          </select>
                        </td>
                        <td>
                          <input
                            maxLength={70}
                            className={classes.input}
                            value={row.Description}
                            onChange={(e) => handleCellEditHandlingSSROSI(row.id, 'Description', e.target.value)}
                            required
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <span className={classes.trash} onClick={() => handleRemoveHandlingSSROSI(row.id)} >
                            <Tooltip title="Deletar Item">
                              <DeleteIcon />
                            </Tooltip>
                          </span>
                          {/* <span onClick={handleRemoveSpecialHandling(row.id)}>x</span> */}
                        </td>
                      </tr>
                    ))}
                    <Button onClick={handleAddRowHandlingSSROSI} style={{ marginTop: '10px' }}>
                      ADICIONAR
                    </Button>
                  </table>
                </div>


                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Assinaturas</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>

                      <div className={classes.iconWithTitle}>
                        <span>Embarcador Estrangeiro</span>
                        <Tooltip title={<span>Obrigatório <br />Limite 35 caracteres</span>}>
                          <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                        </Tooltip>
                      </div>
                      <input value={signatoryConsignor} className={classes.input} placeholder="" onChange={e => setSignatoryConsignor(e.target.value)} type='text' required maxLength={35} />
                    </div>
                  </div>
                  <div className={classes.inputs}>
                    <div className={classes.inputs}>
                      <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                        <div className={classes.iconWithTitle}>
                          <span>Transportador</span>
                          {/* <Tooltip title="Para AWB/MAWB, é a companhia aérea. Para HAWB, é o agente de carga."> */}
                          <Tooltip title={<span>Obrigatório <br />Limite 35 caracteres <br />Para AWB/MAWB, é a companhia aérea. Para HAWB, é o agente de carga.</span>}>
                            <HelpOutlineIcon sx={{ fontSize: iconSize }} />
                          </Tooltip>
                        </div>
                        <input value={signatoryCarrier} className={classes.input} placeholder="" onChange={e => setSignatoryCarrier(e.target.value)} type='text' required maxLength={35} />
                      </div>
                    </div>

                    {/* <Input title="Transportador" required={true} classes={classes} value={signatoryCarrier} onchange={setSignatoryCarrier} styleWidth="100%" maxlength={35} /> */}
                  </div>
                </div>


                <div style={{ display: 'flex', width: '100%' }}>
                  {
                    (id !== undefined && documentnumber !== undefined && trashHidden) ?
                      <>
                        <div className={classes.trashContainer}>
                          <div>
                            <span className={classes.trash} onClick={() => setOpenModal(true)} >
                              <Tooltip title="Excluir HAWB-FZB">
                                <DeleteIcon />
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </> : <></>
                  }

                  <div className={classes.buttonContainer}>
                    <Button
                      type='submit'
                      disabled={loading}
                    >
                      {t('send')}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>

                </div>

                <Modal open={openModal} onClose={handleCloseModal}>
                  <Box className={classes.box}>
                    <Box className={classes.boxTitle}>
                      <Typography className={classes.titleModal}>
                        Excluir HAWB
                      </Typography>
                      <button className={classes.buttonExit} onClick={handleCloseModal}>
                        X
                      </button>
                    </Box>
                    <Box className={classes.boxForm}>
                      <p style={{ padding: '10px' }}>Deseja excluir o HAWB <b>{documentnumber}</b> do Portal Único?</p>
                      <div className={classes.buttonContainer}>
                        <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
                        <Button onClick={(e) => handleDeleteDocument(e)}>
                          OK
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </Box>
                  </Box>
                </Modal>
              </CardBody>
            </form>
          </Card>
        </GridItem>
        {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
      </GridContainer >
    </>
  );
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    border: '1px solid gray',
    borderRadius: '4px',
    minHeight: '1px',
    height: '32px',
    boxShadow: state.isFocused ? '0 0 0 2px lightblue' : 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'lightblue' : 'white',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  }),
  menu: (provided) => ({
    ...provided,
  }),
};


export default (withStyles(houseWayBill)(HouseWayBill));
