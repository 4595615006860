import { NumericFormat } from 'react-number-format';

export function Input(props: any) {

  const { classes, value, onchange, title, placeholder, required, maxlength, styleWidth, funcaoFormatacao, name, type = 'text', numeric, decimalScale = 3, totalIndicator } = props;
  return (

    <div className={classes.inputBox} style={{ minWidth: styleWidth }}>
      <span>{title}</span>

      {numeric ? <>
        <NumericFormat
          value={value}
          className={classes.input}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimalScale}
          allowNegative={false}
          fixedDecimalScale
          onChange={e => { onchange(e.target.value) }}
          placeholder={placeholder}
          style={{ textAlign: 'right', fontWeight: totalIndicator }}
          required={required}
        />
      </> :

        funcaoFormatacao === undefined ? <>
          <input name={name} value={value} className={classes.input} placeholder={placeholder} onChange={e => { onchange(e.target.value) }} type={type} required={required} maxLength={maxlength} />
        </> :
          <>
            <input name={name} value={value} className={classes.input} placeholder={placeholder} onChange={e => { onchange(funcaoFormatacao(e.target.value)) }} type={type} required={required} maxLength={maxlength} />
          </>
      }

      {/* {funcaoFormatacao === undefined ? <>
        <input name={name} value={value} className={classes.input} placeholder={placeholder} onChange={e => { onchange(e.target.value) }} type={type} required={required} maxLength={maxlength} />
      </> :
        <>
          <input name={name} value={value} className={classes.input} placeholder={placeholder} onChange={e => { onchange(funcaoFormatacao(e.target.value)) }} type={type} required={required} maxLength={maxlength} />
        </>
      } */}
    </div>

  );
}