import createStyles from '@material-ui/core/styles/createStyles';

import {
    grayColor,
    idataDefaultColor,
} from 'assets/App';

const checkboxStyle = createStyles({
    root: {
        color: grayColor,
        '&$checked': {
            color: idataDefaultColor,
        },
        width: 10,
        height: 10,
        marginRight: 5,
        padding: 12,
    },   
    checked: {
        color: idataDefaultColor
    },
    
});

export default checkboxStyle;
