import AuthPages from 'layouts/authentication';
import Dashboard from 'layouts/Dashboard';
export * from './dashboard';
export * from './Authentication';

const application = process.env.REACT_APP_APPLICATION === undefined ? '' : process.env.REACT_APP_APPLICATION;

export const indexRoutes = [
    { path: `${application}/auth`, name: 'Authentication', component: AuthPages },
    { path: `${application}`, name: 'Home', component: Dashboard }    
];



