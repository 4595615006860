import classNames from 'classnames';
import Profile from './Profile';
import Help from './Help';

import useStyles from './styles';
import './styles.css';

function HeaderLinks(props:any) {
  const classes = useStyles();

  const managerClasses = classNames({
    [classes.managerClasses]: true
  });

  return (
    <div className={managerClasses}>
      <Help />
      <Profile />
    </div>
  );
}

export default HeaderLinks;