import { createStyles } from '@material-ui/core/styles';
import { idataDefaultColor, cardTitle, defaultSubCellColor, defaultText, orangeIdataBoxShadow } from 'assets/App';

const companyPanel = () => createStyles({
  root: {
    minHeight: 500,
    '& .MuiButton-textPrimary': {
      color: '#777',
    },
    '& .MuiBadge-colorPrimary': {
      backgroundColor: idataDefaultColor,
    },
    '& .MuiDataGrid-row *:focus': {
      boxShadow: '0 0 0 0',
      border: '0',
      outline: 0,
    }
  },
  cardGrid: {
    width: '100%',
  },
  cardIcon: {
    borderRadius: '3px',
    backgroundColor: idataDefaultColor,
    padding: '15px',
    marginTop: '-20px',
    marginRight: '15px',
    float: 'left'
  },
  cardTitle: {
    ...cardTitle,
    padding: '15px 0 0 5px',
    marginBottom: '0px',
  },
  cellAlign: {
    justifyContent: 'center'
  },
  cellColor: {
    backgroundColor: defaultSubCellColor
  },
  button: {
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    right: 0,
    position: 'absolute',
    padding: '10px 30px 0 0',
    zIndex: 1,
  },
  input: {
    marginLeft: 6,
    display: 'flex',
    color: '#000',
    width: 150,
    float: 'left',
    '&:after': {
      borderBottomColor: `${idataDefaultColor} !important`
    }

  },
  dataBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px'
  },
  BoxInputs: {
    float: 'right',
    display: 'flex'
  },
  buttonSubmit: {
    background: 'transparent',
    border: 'solid 0',
    cursor: 'pointer',
    color: '#000',
    padding: 0,
    '&:hover,&:focus': {
      color: idataDefaultColor,
      boxShadow: orangeIdataBoxShadow,
      cursor: 'pointer'

    }
  },
  boxTitle: {
    background: '#f6a828',
    borderRadius: 5,
    position: 'absolute',
    color: '#fff',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 35,
    width: 600,
    border: '2px solid #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  box: {
    background: '#fff',
    borderRadius: 5,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    width: 600,
    border: '2px solid #fff',
  },
  buttonExit: {
    padding: '0 15px',
    backgroundColor: 'transparent',
    border: '0',
    color: 'white',
    cursor: 'pointer'
  },
  titleModal: {
    fontSize: 14,
    margin: '.4em 0 .2em ',
    marginLeft: '5px'
  },
  boxForm: {
    fontSize: 14,
    margin: '.1em 0 .2em ',
    marginLeft: '4px',
    height: 'auto',
    marginTop: 30
  },
  textAreaContainer: {
    display: 'flex',
    //justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '15px 30px'
  },
  text: {
    padding: '0 15px',
    ...defaultText
  },
  buttonContainer: {
    marginTop: 20,
    float: 'right',
    marginRight: 12,
    marginBottom: 8,
    color: idataDefaultColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  buttonProgress: {
    color: idataDefaultColor,
    position: 'absolute',
  },
  tableHeader: {
    width: '100%',
    textAlign: 'left',
    color: 'rgb(0, 0, 0, 0.87)',
    padding: '20px 0px 30px 12px',
    tableLayout: 'fixed',
    fontSize: '1rem'

  },
  tableFooter: {
    width: '100%',
    textAlign: 'left',
    color: 'rgb(0, 0, 0, 0.87)',
    padding: 10,
    border: 'solid 1px #e0e0e0',
    tableLayout: 'fixed',
    borderTopColor: '#D7CCE3',
    fontSize: '1rem'
  },
  link: {
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: idataDefaultColor,
    }
  },
});

export default companyPanel;
