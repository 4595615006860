import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import linearProgressStyle from './styles';

function CustomLinearProgress({ ...props }) {
    const { children, classes, ...rest } = props;
    //const defaultClass = 'circular-progress-loading';
    return (
        <LinearProgress {...rest}
            //classes={{root:rest.customClass.root ? rest.customClass.root : defaultClass.root}}
            classes={{
                root: classes.container,
                barColorPrimary: classes.color,            
            }}
        />


    );
}

export default withStyles(linearProgressStyle)(CustomLinearProgress);
