import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { ThemeProvider } from '@material-ui/core/styles';
import { GridContainer, GridItem, Card, CardBody, Snackbar, CardHeader, Button } from 'components';
import { checkPassword, getUser, sendNewPassword, getCompany, linkUserCompany, getCompanysUser, deletelinkUserCompany } from 'services';
import { UserContext } from 'context';
import { user, customTheme } from './styles';
import PersonIcon from '@material-ui/icons/Person';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';

interface UrlParams {
  id: string;
}

interface CompanyInterface {
  id: number;
  cod_emp: number;
  razaosocial_emp: string;
  nome_ramo: string;
  cgc_emp: string;
}

function User(props: any) {

  const { classes } = props;
  const { t } = useTranslation();
  const user = useContext(UserContext) as IUser;
  const [login, setLogin] = useState('');
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);
  const [companiesLinkedUser, setCompaniesLinkedUser] = useState<CompanyInterface[]>([]);
  const [name, setName] = useState('');
  const [compatyIdToLinkUser, setCompatyIdToLinkUser] = useState('');
  const [token, setToken] = useState('');
  const [actualPassword, setActualPassword] = useState('');
  const [confirmedActualPassword, setConfirmedActualPassword] = useState(false);
  const [hiddenLinkedCompanys, setHiddenLinkedCompanys] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [hiddenLinkCompanyUser, setHiddenLinkCompanyUser] = useState(false);
  const [password, setPassword] = useState('');
  const [confimrPassword, setConfirmPassword] = useState('');
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });
  const [valid, setValid] = useState(false);

  const { id } = useParams<UrlParams>();

  const handleLoaduser = useCallback(async () => {
    setLoading(true);
    try {

      const response = await getUser(id);
      if (response && response.data) {
        setLogin(response.data[0].email_usu);
        setName(response.data[0].apelido_usu);
        setToken(response.data[0].tokenHash);
      }
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data ? error?.response?.data : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
    }
    setLoading(false);
  }, [user]);

  useEffect(() => {
    handleLoaduser();

    console.log(user?.eAdministrador);
    if (user?.eAdministrador === '1') {
      loadCompanies();
      loadCompaniesLinkedUser();
    }
  }, []);

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  async function handleCheckPassword(e: any) {
    try {
      const response = await checkPassword(actualPassword, user?.usuarioID);
      setConfirmedActualPassword(prevState => prevState ? false : true);
      setChangePassword(false);
    } catch (error) {
      setOpen({ open: true, severity: 'error', message: error?.response?.data });
    }
  }

  function handleVisibleChangePassword() {
    setChangePassword(prevState => prevState ? false : true);
  }

  function handleLinkUserCompany() {
    setHiddenLinkCompanyUser(prevState => prevState ? false : true);
  }

  function handleHiddenLinkedCompanys() {
    setHiddenLinkedCompanys(prevState => prevState ? false : true);
  }

  async function handleChangePassword() {

    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$/;
    if (!regex.test(password)) {
      setOpen({ open: true, severity: 'error', message: 'Senha em formato inválido.' });
      return;
    }

    if (password !== confimrPassword) {
      setOpen({ open: true, severity: 'error', message: 'Senhas devem ser iguais' });
      return;
    }

    try {
      const response = await sendNewPassword(password, user?.usuarioID);
      setPassword('');
      setConfirmPassword('');
      setOpen({ open: true, severity: 'success', message: 'Senha alterada com sucesso' });

    }
    catch (error) {
      setOpen({ open: true, severity: 'error', message: error?.response?.data });
    }
  }

  const loadCompaniesLinkedUser = async () => {
    try {
      const response = await getCompanysUser(id);
      if (response && response.data) {
        setCompaniesLinkedUser(response.data);
      }
    }
    catch (error) {
    
    }
  }

  const loadCompanies = async () => {
    try {
      const response = await getCompany();
      if (response && response.data) {
        setCompanies(response.data);
      }
    }
    catch (error) {
    }
  };


  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    const hasUppercase = /[A-Z]/.test(newPassword);
    const hasLowercase = /[a-z]/.test(newPassword);
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*-]/.test(newPassword);
    const isValid = newPassword.length >= 6 && newPassword.length <= 16 && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;

    setPassword(newPassword);
    setValid(isValid);
  };


  const handleSubmitCompanyUser = async () => {

    try {
      if (user) {
        const response = await linkUserCompany(id, compatyIdToLinkUser);
        if (response) {
          setOpen({ open: true, severity: 'success', message: 'Empresa vinculada' });
          loadCompaniesLinkedUser();
        }
      }
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data.erro ? error?.response?.data.erro : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
    }
  }

  const handleDeleteLink = async (companyUserId: number) => {

    try {
      if (user) {
        const response = await deletelinkUserCompany(companyUserId);
        if (response) {
          setOpen({ open: true, severity: 'success', message: 'Empresa excluída' });
          loadCompaniesLinkedUser();
        }
      }
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data.erro ? error?.response?.data.erro : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
    }
  }

  return (
    <GridContainer>
      <GridItem xs={11}>
        <Card>
          <CardHeader color='primary' icon>
            <div className={classes.cardIcon}>
              <PersonIcon />
            </div>
            <h4 className={classes.cardTitle}>
              Usuário
            </h4>
          </CardHeader>
          <CardBody>

            <div className={classes.cardGrid}>
              <ThemeProvider theme={customTheme}>
                <div className={classes.dataBox}>
                  <span><b>Nome</b></span>
                  <span>{name}</span>
                </div>
                <div className={classes.dataBox}>
                  <span><b>Login</b></span>
                  <span>{login}</span>
                </div>
                <div className={classes.dataBox}>
                  <span><b>Token API</b></span>
                  <span>{token}</span>
                </div>
                <div className={classes.dataBox}>
                  <span><b>Alterar Senha</b></span>
                  {!confirmedActualPassword && (
                    <div className={classes.dataBox} style={{ width: '100px' }}>
                      <span onClick={handleVisibleChangePassword} style={{ cursor: 'pointer' }}>Redefinir Senha</span>
                    </div>
                  )}
                </div>

                {changePassword && (
                  <>
                    <div style={{ justifyContent: 'right', width: '300px', marginBottom: '100px' }}>
                      <div className={classes.inputBox}>
                        <span>Senha Atual</span>
                        <input
                          onChange={(e) => setActualPassword(e.target.value)}
                          className={classes.input}
                          type='password'
                          required />
                      </div>
                      <Button
                        style={{ float: 'right' }}
                        disabled={loading}
                        onClick={handleCheckPassword}
                      >
                        {'Próxima'}
                      </Button>
                    </div>
                  </>
                )}

                {confirmedActualPassword && (
                  <>
                    <div style={{ justifyContent: 'right', width: '300px' }}>
                      <div className={classes.inputBox}>
                        <span>Nova Senha</span>
                        <input
                          value={password}
                          onChange={handlePasswordChange}
                          className={classes.input}
                          type='password'
                          required />
                      </div>
                      {!valid && <p style={{ textAlign: 'justify', fontSize: '12px' }}>A senha deve ter de 6 a 16 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais.</p>}


                      <div className={classes.inputBox}>
                        <span>Confirmar Nova Senha</span>
                        <input value={confimrPassword} onChange={(e) => setConfirmPassword(e.target.value)} className={classes.input} type='password' required />
                      </div>
                      <Button
                        style={{ float: 'right' }}
                        disabled={loading}
                        onClick={handleChangePassword}
                      >
                        {'Alterar Senha'}
                      </Button>

                    </div>
                  </>
                )}
                {user?.eAdministrador === '1' && <>
                  <div className={classes.dataBox}>
                    <span>
                      <b>Vincular empresa</b>
                    </span>
                  </div>


                  <div style={{ justifyContent: 'right', width: '300px', display: 'block' }}>
                    <select onChange={e => setCompatyIdToLinkUser(e.target.value)} className={classes.input} style={{ width: '400px' }}>
                      {companies.map((company) => (
                        <option key={company.cod_emp} value={company.cod_emp}>{company.razaosocial_emp} - {company.cgc_emp}</option>
                      ))}
                    </select>

                    <Button
                      onClick={handleSubmitCompanyUser}
                    >
                      {'Vincular'}
                    </Button>
                  </div>

                  <div className={classes.dataBox} style={{ marginTop: '50px' }}>
                    <span>
                      <b>Empresas vinculadas</b>
                    </span>
                  </div>

                  <div>
                    <table style={{ borderCollapse: 'collapse' }}>
                      <tr>
                        <td style={{ border: '1px solid #000', padding: '5px' }}><b>Empresa</b></td>
                        <td style={{ border: '1px solid #000', padding: '5px' }}><b>CNPJ</b></td>
                        <td style={{ border: '1px solid #000', padding: '5px' }}></td>
                      </tr>
                      {companiesLinkedUser.map((company) => (
                        <tr>
                          <td style={{ border: '1px solid #000', padding: '5px' }}>{company.razaosocial_emp}</td>
                          <td style={{ border: '1px solid #000', padding: '5px' }}>{company.cgc_emp}</td>
                          <td onClick={() => handleDeleteLink(company.id)} style={{ border: '1px solid #000', padding: '5px' }}>
                            <DeleteIcon style={{ cursor: 'pointer' }} />
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </>}
              </ThemeProvider>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
    </GridContainer >
  );
}

export default (withStyles(user)(User));
