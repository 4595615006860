import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import AuthHeader from './Header';
import pagesStyle from './styles';
import bgImage from 'assets/img/register.jpeg';
import {authRoutes} from 'routes';

function Pages(props:any) {

  useEffect(() => {
    document.body.style.overflow = 'unset';
  }, []);

  const { classes, ...rest } = props;
  return (
    <div>
      <AuthHeader {...rest} />
      <div className={classes.wrapper} >
        <div
          className={classes.fullPage}
          style={{ backgroundImage: 'url(' + bgImage + ')' }}
        >
          <Switch>
            {authRoutes.map((prop, key) => {
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </Switch>
        </div>
      </div>
    </div>
  );
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
