import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

import useStyles from './styles';


import './styles.css';

function Help() {
   const classes = useStyles();

   return (
      <>

         <IconButton
            className={classes.iconButton + ' ' + classes.customIconButton}
         >
            <a href="#">
               <HelpOutlineIcon
                  classes={{
                     root: classes.Icon,
                  }}
               />
            </a>
         </IconButton>

      </>
   )
}


export default Help;






