import {LoginPage,EmailRecovery,PasswordReset} from 'pages';
import Fingerprint from "@material-ui/icons/Fingerprint";

const application = process.env.REACT_APP_APPLICATION === undefined ? '' : process.env.REACT_APP_APPLICATION;

export const authRoutes = [
  {
    path: application + '/auth/login',
    name: 'Login',  
    icon: Fingerprint,
    component: LoginPage,
    invisible:true,   
    mini:'LOG',    
  },
  {
    path: application + '/auth/emailrecovery',
    name: 'Email Recovery', 
    component: EmailRecovery
  },
  {
    path: application + '/auth/:id',
    name: 'Reset Password', 
    component: PasswordReset
  },
];




