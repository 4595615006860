import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import { sendMessage } from 'services';
import { UserContext } from 'context';
import MessagesSelect from './MessagesType'
import uploadMessagesStyle from './styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function UploadMessages(props: any) {
	const { classes } = props;
	const { t } = useTranslation();
	const user = useContext(UserContext) as IUser;
	const [file, setFile] = useState<string | Blob>('');
	const [filename, setFilename] = useState('');
	const [messageType, setMessageType] = useState('');
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState({ open: false, severity: '', message: '' });
	const [openDialog, setOpenDialog] = useState(false);
	const [returnMessage, setReturnMessage] = useState('');

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleFile = (e: any) => {
		let file = e.target.files[0];
		setFile(file);
		setFilename(file.name);
	}

	const removeFile = () => {
		setFile('');
		setFilename('');
	}

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			setOpen({ open: false, severity: '', message: '' });
			return;
		}
		setOpen({ open: false, severity: '', message: '' });
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!messageType || !file) {
			return setOpen({ open: true, severity: 'warning', message: t('warningMessage') });
		}

		setLoading(true);

		// Envia a informação para a API
		try {
			let userId = String(user?.usuarioID);
			const formData = new FormData();
			if (userId && file) {
				formData.append('file', file);
				formData.append('userId', userId);
				formData.append('tipoDocumento', messageType);
				formData.append('cnpjCompany', localStorage.getItem('company'));
			}
			const response = await sendMessage(formData);
			if (response && response.data) {
				const result = response.data.Mensagem;

				setOpenDialog(true);
				setReturnMessage(result.replaceAll("\n", "<br />"));
				//setOpen({ open: true, severity: 'success', message: result });
				removeFile();
			}
		}
		catch (error) {

			if (error?.response?.status === 401) {
				const message = t('expiredToken');
				setOpen({ open: true, severity: 'error', message: String(message) });
			}
			else {
				const message = error?.response?.data?.erro ? error?.response?.data?.erro : error;
				setOpen({ open: true, severity: 'error', message: String(message) });
			}
		}
		setLoading(false);
	}

	return (
		<>
			<div>
				<Dialog
					open={openDialog}
					onClose={handleCloseDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Retorno
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<span dangerouslySetInnerHTML={{ __html: returnMessage }} />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDialog}>Ok</Button>
					</DialogActions>
				</Dialog>
			</div>

			<GridContainer justify='center'>
				<GridItem xs={12} sm={12} md={6}>
					<Card>
						<span className={classes.textTitle}>{t('messageUploadTitle')}</span>
						<form onSubmit={handleSubmit}>
							<CardBody className={classes.container}>
								{/* "Importa" os checkbox */}
								<MessagesSelect
									clear={loading}
									selectType={(value: string) => setMessageType(value)}
								/>

								{/* Se possui arquivo no input exibe um ou o outro*/}
								{!file ? (
									<div className={classes.messageContainer}>
										<div className={classes.hide}>
											<IconButton >
												<PublishIcon />
											</IconButton>
											<input
												className={classes.inputFile}
												type='file'
												onChange={handleFile}
											/>
										</div>
										<span className={classes.text}>
											{t('selectFile')}
										</span>
									</div>
								) : (
									<div className={classes.filename}>
										<h6 >
											{filename}
										</h6>
										<IconButton onClick={removeFile}>
											<DeleteIcon />
										</IconButton>
									</div>
								)}

								<div className={classes.buttonContainer}>
									<Button
										type='submit'
										disabled={loading}
									>
										{t('send')}
									</Button>
									{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
								</div>
							</CardBody>
						</form >
					</Card>
				</GridItem>
			</GridContainer>
			{open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
		</>
	);
}

export default (withStyles(uploadMessagesStyle)(UploadMessages));

