import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { TextField, Button, Card, CardHeader, CardBody, CardFooter, GridContainer, GridItem, LinearProgress, Snackbar } from 'components';
import { useTranslation } from 'react-i18next';
import { sendEmailRecovery } from 'services/api';
import logo from 'assets/img/logo.png';
import authPageStyle from '../styles';

interface IData {
    email: string;
}

function EmailRecovery(props: any) {
    const { t } = useTranslation();
    const classes = authPageStyle();
    const [cardAnimaton, setCardAnimaton] = useState("cardHidden");
    const { register, handleSubmit, errors } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState({ open: false, severity: '', message: '' });

    useEffect(() => {
        let message;
        if (errors.email?.message)
            message = errors.email?.message;
        if (message)
            setOpen({ open: true, severity: 'error', message });
    }, [errors]);

    useEffect(() => {
        let timeOutFunction = setTimeout(
            function () {
                setCardAnimaton("");
            },
            700
        )
        return () => {
            clearTimeout(timeOutFunction);
        }
    }, []);

    const onSubmit = async (data: IData, event: any) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const { email } = data;
            if (email) {
                const response = await sendEmailRecovery(email);
                if (response.data) {
                    const result = response.data
                    setOpen({ open: true, severity: 'success', message: result });
                }
            }
        }
        catch (error) {
            const message = error?.response?.data ? error?.response?.data : error;
            setOpen({ open: true, severity: 'error', message: message });
        }
        setIsLoading(false);
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen({ open: false, severity: '', message: '' });
    };

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem md={4}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        < Card
                            login
                            className={cx({ [classes.cardHidden]: cardAnimaton })}
                        >
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="warning"
                            >
                                {isLoading && <LinearProgress />}
                                <img src={logo} alt="" style={{ width: '80px' }}></img>
                            </CardHeader>
                            <CardBody>
                                <TextField margin="normal"
                                    fullWidth={true}
                                    label={t('email')}
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    //type="email"
                                    // inputRef={register({ required: t("invalidEmail"), pattern: { value: emailRegex, message: t("invalidEmail") } })}
                                    inputRef={register({ required: 'E-mail incorreto' })}
                                />
                                {/* {errors.email && <Snackbar icon severity="error" message={errors.email?.message} />} */}
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}
                            >
                                <Button
                                    type="submit"
                                //size="lg"
                                >
                                    {t('send')}
                                </Button>
                            </CardFooter>
                        </Card>
                    </form >
                </GridItem>
            </GridContainer>
            {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
        </div>
    )
}

export default EmailRecovery;