import withStyles from '@material-ui/core/styles/withStyles';
import customToolbar from './styles';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  useGridApiContext,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { downloadCSV } from 'services/api';
import { useState } from 'react';
import Button from 'components/Button';
import { Menu, MenuItem } from '@material-ui/core';


function CustomToolbar({ ...props }) {
  const {   
    fileName,
  } = props;

  const ExportToCsvButton = () => {
    const apiRef = useGridApiContext();

    const handleClick = async () => {
      const json = JSON.stringify({ csv: apiRef.current.getDataAsCsv() });
      await downloadCSV(json)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {

          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = `${fileName}.xlsx`;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Erro:', error);
        });
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    return (
      <>
        <Button
          onClick={handleClickMenu}
          size='sm'
          color='gray'
        >
          Exportar
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClick}>Excel</MenuItem>
        </Menu>
      </>
    )
  };

  return (
    <div>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <ExportToCsvButton />
      </GridToolbarContainer>
    </div>
  );
}

export default withStyles(customToolbar)(CustomToolbar);