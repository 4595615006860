import createStyles from '@material-ui/core/styles/createStyles';
import { idataDefaultColor, title } from 'assets/App';

const uploadMessagesStyle = () => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    //minHeight: 300
  },
  buttonContainer: {
    marginTop: 20
  },
  filename: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textTitle: {
    textAlign: 'center',
    ...title,
  },
  buttonProgress: {
    color: idataDefaultColor,
    position: 'absolute',
    marginTop: 5,
    marginLeft: -45,
  },
});

export default uploadMessagesStyle;
