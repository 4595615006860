import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderLinks from './Links';
import useStyles from './styles';
import ilogLogo from 'assets/img/logo.svg';
import { defaultIlogIconColor, defaultIlogColor } from 'assets/App';
import { Link } from 'react-router-dom';
import { UserContext } from 'context';


interface Company {
  cod_emp: number;
  razaosocial_emp: string;
}

interface IHeaderProps {
  classes: ReturnType<typeof useStyles>;
  color: 'primary' | 'info' | 'success' | 'warning' | 'danger';
  sidebarMinimize: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}



function Header(props: IHeaderProps) {
  const user = useContext(UserContext) as IUser;

  const { color } = props;
  const classes = useStyles();

  const [companyList, setCompanyList] = useState<Company[]>([]);

  useEffect(() => {
    setCompanyList(user?.empresas || []);
    localStorage.setItem("company", user?.empresas[0]?.cnpj);
  }, [user]);

  const defaultCompany = user?.empresas[0]?.cnpj;

  function handleCompanyCheck(e: any) {
    localStorage.setItem("company", e);
  };

  const appBarClasses = cx({
    [' ' + classes[color]]: color
  });

  var brand = (
    <Link to="/home">
      <div className={classes.logo}>
        <div>
          <img src={ilogLogo} alt='logo' />
        </div>
        <div className={classes.logoNormal}>
        </div>
      </div>
    </Link>
  );

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation='css'>
          <IconButton
            className={classes.iconButton}
            onClick={props.sidebarMinimize}
          >
            <MenuIcon
              classes={{
                root: classes.Icon,
                colorPrimary: defaultIlogIconColor,
                colorSecondary: defaultIlogColor
              }}
            />
          </IconButton>
        </Hidden>
        <Hidden mdUp implementation='css'>
          <IconButton
            onClick={props.handleDrawerToggle}
          >
            <MenuIcon
              classes={{
                root: classes.Icon,
                colorPrimary: defaultIlogIconColor,
                colorSecondary: defaultIlogColor
              }}
            />
          </IconButton>
        </Hidden>
        {brand}
        <div className={classes.flex} />

        {companyList.length > 1 && (
          <select defaultValue={defaultCompany} className={classes.selectInput} onChange={(e) => handleCompanyCheck(e.target.value)}>
            {companyList.map((company) => (
              <option key={company?.cnpj} value={company?.cnpj}>{company?.nomeFantasia}</option>
            ))}
          </select>
        )}

        <Hidden smDown implementation='css'>
          <HeaderLinks />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}


export default Header;
