import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import jwtDecode from 'jwt-decode';
import { TextField, Button, Card, CardHeader, CardBody, CardFooter, GridContainer, GridItem, Snackbar, LinearProgress } from 'components';
import { useTranslation } from 'react-i18next';
import logo from 'assets/img/logo.png';
import { sendNewPassword } from 'services';
import { getPath } from 'utils';
import authPageStyle from '../styles';

interface IData {
    password: string;
    confirmPassword: string;
    userId: string;
}

function PasswordReset(props: any) {
    const classes = authPageStyle();
    const { push } = useHistory();
    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = useState(false);
    const [userId, setIdUser] = useState('');
    const [cardAnimaton, setCardAnimaton] = useState('cardHidden'); //Por default o display é none style pra não mostrar a barra
    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState({ open: false, severity: '', message: '' });

    const [valid, setValid] = useState(false);

    //const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,8}$');
    useEffect(() => {
        let message;
        if (errors.password?.message)
            message = errors.password?.message;
        if (errors.confirmPassword?.message && message !== errors.confirmPassword?.message)
            message += errors.confirmPassword?.message;
        if (message)
            setOpen({ open: true, severity: 'error', message });
    }, [errors]);

    useEffect(() => {
        try {
            const { id } = props.match.params;
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let token = params.get('token');
            if (token) {
                jwtDecode(token);
                setIdUser(id);
            }
            else
                setIsDisabled(true);
        }
        catch (error) {
            setIsDisabled(true);
            setOpen({ open: true, severity: 'error', message: error });
            push(getPath('Login'))
        }
        let timeOutFunction = setTimeout(
            function () {
                setCardAnimaton('');
            },
            700
        )
        return () => {
            clearTimeout(timeOutFunction);
        }
    }, [t, push, props.match.params]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen({ open: false, severity: '', message: '' });
    };


    const onSubmit = async (data: IData, event: any) => {
        event.preventDefault();

        const { password, confirmPassword } = data;
        setIsLoading(true);
        try {

            if (password !== confirmPassword) {
                setOpen({ open: true, severity: 'error', message: t('noMatchPassword') });
                setIsLoading(false);
                return;
            }

            const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$/;
            if (!regex.test(password)) {
                setOpen({ open: true, severity: 'error', message: 'Senha em formato inválido.' });
                setIsLoading(false);
                return;
            }

            if (password && confirmPassword && userId) {
                const response = await sendNewPassword(password, userId);
                if (response.data) {
                    const result = response.data
                    setOpen({ open: true, severity: 'success', message: result });
                }
            }
            else
                setOpen({ open: true, severity: 'error', message: t('noMatchPassword') });
        }
        catch (error) {
            const message = error?.response?.data ? error?.response?.data : error;
            setOpen({ open: true, severity: 'error', message: message });
        }
        setIsLoading(false);
    }

    // const handleChange = (event: any) => {
    //     setPassword(event.target.value);

    //     const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$/;
    //     if (!regex.test(event.target.value)) {
    //     }
    // };



    const handlePasswordChange = (event: any) => {
        const newPassword = event.target.value;

        const hasUppercase = /[A-Z]/.test(newPassword);
        const hasLowercase = /[a-z]/.test(newPassword);
        const hasNumber = /\d/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*-]/.test(newPassword);
        const isValid = newPassword.length >= 6 && newPassword.length <= 16 && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;

        setValid(isValid);
    };

    return (
        <div className={classes.container}>
            <GridContainer justify='center'>
                <GridItem md={4}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        < Card
                            login
                            className={cx({ [classes.cardHidden]: cardAnimaton })}
                        >
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color='warning'
                            >
                                {isLoading && <LinearProgress />}
                                <img src={logo} alt='' style={{ width: '80px' }}></img>
                            </CardHeader>
                            <CardBody>
                                <TextField
                                    onChange={handlePasswordChange}
                                    disabled={isDisabled ? isDisabled : null}
                                    margin='normal'
                                    fullWidth={true}
                                    label={t('password')}
                                    id='password'
                                    name='password'
                                    type='password'
                                    inputRef={register({ required: 'Campo não pode estar vazio' })}
                                //inputRef={register({ required: t('invalidPassword'), pattern: { value: passwordRegex, message: t('complexPassword') } })}
                                />

                                {!valid && <p>A senha deve ter de 6 a 16 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais.</p>}

                                <TextField
                                    disabled={isDisabled ? isDisabled : null}
                                    margin='normal'
                                    fullWidth={true}
                                    label={t('confirmPassword')}
                                    id='confirmPassword'
                                    name='confirmPassword'
                                    type='password'
                                    inputRef={register({ required: 'Campo não pode estar vazio' })}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button
                                    disabled={isDisabled ? isDisabled : null}
                                    type='submit'
                                >
                                    {t('send')}
                                </Button>
                            </CardFooter>
                        </Card>
                    </form >
                </GridItem>
                {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
            </GridContainer>
        </div>
    )
}

export default PasswordReset;


