import createStyles from '@material-ui/core/styles/createStyles';

import {
  defaultFont,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow
} from 'assets/App';

const snackbarContentStyle = createStyles({
  root: {
    ...defaultFont,
    flexWrap: 'unset',
    position: 'relative',
    lineHeight: '20px',   
    fontSize: '13px',
    backgroundColor: 'white',
    color: '#555555',
    borderRadius: '3px',
    boxShadow:
      '0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.2)'
  },
  top20: {
    top: '20px'
  },
  top40: {
    top: '40px'
  },
  info: {
    backgroundColor: '#00d3ee',
    color: '#ffffff',
    ...infoBoxShadow
  },
  success: {
    backgroundColor: '#5cb860',
    color: '#ffffff',
    ...successBoxShadow
  },
  warning: {
    backgroundColor: '#ffa21a',
    color: '#ffffff',
    ...warningBoxShadow
  },
  error: {
    backgroundColor: '#f55a4e',
    color: '#ffffff',
    ...dangerBoxShadow
  },
  primary: {
    backgroundColor: '#af2cc5',
    color: '#ffffff',
    ...primaryBoxShadow
  },
  rose: {
    backgroundColor: '#eb3573',
    color: '#ffffff',
    ...roseBoxShadow
  },
  message: {
    maxHeight: '150px',
    padding: '10px',
    display: 'block',
    maxWidth: '100%',
    justifyContent: 'left',
  },
  messageScroll: {
    maxHeight: '450px',
    padding: '10px',
    display: 'block',
    maxWidth: '100%',
    overflowY: 'scroll',
    justifyContent: 'left',
  },
  messageClasses: {
    display: 'block',
    maxHeight: '150px',
    whiteSpace: 'pre-line',
    textAlign: 'left',
  },
  close: {
    position: 'fixed',
    width: '15px',
    height: '15px',
  },
  iconButton: {
    width: '24px',
    height: '24px',
  },
  containerMessageIcon:{
    display: 'flex',
    textAlign: 'justify',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
   verticalAlign:'middle',
    width: '24px',
    height: '24px',   
    fontSize: '20px', 
    borderRadius: '50%',   
  },
  infoIcon: {
    color: '#00d3ee'
  },
  successIcon: {
    color: '#5cb860'
  },
  warningIcon: {
    color: '#ffa21a'
  },
  dangerIcon: {
    color: '#f55a4e'
  },
  primaryIcon: {
    color: '#af2cc5'
  },
  roseIcon: {
    color: '#eb3573'
  },
  iconMessage: {
    paddingLeft: '15px',    
    alignSelf: 'center'
  },
  
});

export default snackbarContentStyle;
