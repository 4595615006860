import createStyles from '@material-ui/core/styles/createStyles';
import { title, idataDefaultColor } from 'assets/App';

const messagesConverterStyle = () => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    minHeight: 300,
    width: '100%',
  },
  textAreaContainer: {
    width: '100%',
  },
  textAreaMargin: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 20
  },
  textArea: {
    marginTop: 5,
    width: '100%',
    resize: 'none',
    padding: '7px',
    fontFamily: 'Arial'
  },
  textTitle: {
    ...title,
    textAlign: 'center'
  },
  buttonProgress: {
    color: idataDefaultColor,
    position: 'absolute',
    marginTop: 5,
    marginLeft: -45,
  },
});

export default messagesConverterStyle;
