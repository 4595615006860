import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import MessagesSelect from './MessagesType'
import includeMessagesStyle from './styles';

function MessagesInclude(props: any) {
  const { classes } = props;
  const { t } = useTranslation();
  const [messageType, setMessageType] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);

    e.preventDefault();
    // if (!messageType || !file)
    //   return setOpen({ open: true, severity: 'warning', message: t('warningMessage') }); 

    if (!messageType) {
      return setOpen({ open: true, severity: 'warning', message: t('warningMessage') }); 
    }
    setLoading(false);
  }

  return (
    <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <span className={classes.textTitle}>{t('messageIncludeTitle')}</span>
            <form onSubmit={handleSubmit}>
              <CardBody className={classes.container}>
                <MessagesSelect
                  clear={loading}
                  selectType={(value: string) => setMessageType(value)}
                />
                <div className={classes.buttonContainer}>
                  <Button
                    type='submit'
                    disabled={loading}
                  >
                    {t('addMessage')}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </CardBody>
            </form >
          </Card>
        </GridItem>
      </GridContainer>
      { open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
    </>
  );
}

export default (withStyles(includeMessagesStyle)(MessagesInclude));

