import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { TextField, Button, Card, CardHeader, CardBody, CardFooter, GridContainer, GridItem, LinearProgress, Snackbar, Checkbox } from 'components';
import logoSVG from 'assets/img/logo.svg';
import { useTranslation } from 'react-i18next';
import { getPath } from 'utils';
import { signIn } from 'services/api';
import { saveStorage } from 'services';
import authPageStyle from '../styles';

interface IData {
   email: string;
   password: string;
   rememberMe: string;
}

type FormData = {
   email: string;
   password: string;
   rememberMe: Boolean;
};

function Login() {
   const { push, go } = useHistory();
   const { t } = useTranslation();
   const classes = authPageStyle();
   const [isLoading, setIsLoading] = useState(false);
   const [cardAnimaton, setCardAnimaton] = useState('cardHidden'); //Por default o display é none style pra não mostrar a barra
   const [open, setOpen] = useState({ open: false, severity: '', message: '' });
   //const [checked, setChecked] = useState(false);
   const { register, handleSubmit, setValue, getValues, errors } = useForm<FormData>()

   useEffect(() => {
      let message;
      if (errors.password?.message)
         message = errors.password?.message;
      if (errors.email?.message && message !== errors.email?.message)
         message += errors.email?.message;
      if (message)
         setOpen({ open: true, severity: 'error', message });
   }, [errors]);

   useEffect(() => {
      let timeOutFunction = setTimeout(
         function () {
            setCardAnimaton('');
         },
         700
      )
      return () => {
         clearTimeout(timeOutFunction);
      }
   }, []);


   const handleClose = (reason?: string) => {
      if (reason === 'clickaway') {
         return;
      }
      setOpen({ open: false, severity: '', message: '' });
   };

   const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
      //setChecked(e?.target?.checked);
   }

   const onSubmit = async (data: IData, event: any) => {
      event.preventDefault();
      setIsLoading(true);

      try {
         const { email, password } = data;
         const response = await signIn(email, password);
         if (response && response.status === 200 && response.data) {
            const result = JSON.stringify(response.data);
            //if (rememberMe)

            const application = process.env.REACT_APP_APPLICATION === undefined ? '' : process.env.REACT_APP_APPLICATION;

            await saveStorage(result);
            push(application + getPath('Dashboard'));
            go(0);
         }
         else throw response;
      }
      catch (error) {
         const message = error?.response?.data ? t('incorrectCredentials') : error;
         setOpen({ open: true, severity: 'error', message: String(message) });
      }
      setIsLoading(false);
   }


   return (
      <div className={classes.container}>
         <GridContainer justify='center' >
            <GridItem xs={12} sm={6} md={4}>
               <form onSubmit={handleSubmit(onSubmit)}>
                  < Card
                     login
                     className={cx({ [classes.cardHidden]: cardAnimaton })}
                  >
                     <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color='warning'
                     >
                        {isLoading && <LinearProgress />}
                        <img src={logoSVG} alt='' style={{ width: '80px' }}></img>
                     </CardHeader>
                     <CardBody>
                        <TextField margin='normal' fullWidth={true}
                           label={t('email')}
                           id='email'
                           name='email'
                           autoComplete='email'
                           inputRef={register({ required: 'Campo não pode estar vazio' })}
                        />
                        <TextField margin='normal' fullWidth={true}
                           label={t('password')}
                           id='password'
                           name='password'
                           autoComplete='password'
                           type='password'
                           inputRef={register({ required: 'Campo não pode estar vazio' })}
                        />
                        <div className={classes.justifyContentSpace} >
                           <div>
                              <Checkbox
                                 onChange={onChangeCheckbox}
                              />
                              <Button
                                 href=''
                                 link
                                 disableRipple
                                 size='sm'
                                 onClick={() => setValue('rememberMe', !getValues('rememberMe'))}
                              >
                                 {t('rememberMe')}
                              </Button>
                           </div>
                           <Link to={getPath('Email Recovery')}
                           >
                              <Button
                                 href=''
                                 link
                                 disableRipple
                                 size='sm'
                                 onClick={handleSubmit}
                              >
                                 {t('forgotPas')}
                              </Button>
                           </Link>
                        </div>
                     </CardBody>
                     <CardFooter className={classes.justifyContentCenter} >
                        <Button type='submit' >
                           {t('loginButton')}
                        </Button>
                     </CardFooter>
                  </Card>
               </form >
            </GridItem>
            {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
         </GridContainer>
      </div>
   )
}

export default Login;



