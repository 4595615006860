import createStyles from '@material-ui/core/styles/createStyles';

import {
    idataDefaultColor
} from 'assets/App';

const textFieldStyle = createStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.54) !important'
    },
    underline: {
        '&:after': {
            borderBottomColor: `${idataDefaultColor} !important`
        }
    }
});

export default textFieldStyle;