import { useState } from 'react';
import { Checkbox } from 'components';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';

const messagesTypes = [ 
  { code: 'XFWB', label: 'XML Waybill Message (XFWB)' },
  { code: 'XFZB', label: 'XML House Waybill Message (XFZB)' },
];

export default function MessagesSelect(props: any) {
  const { selectType } = props;
  const { t } = useTranslation();
  const [selected, setSelected] = useState('');

  const handleChange = (event: any) => {
    setSelected(event.target.id);
    selectType(event.target.id)
  };

  return (
    <FormControl component="fieldset" >
      <FormLabel focused={false} component="legend">{t('selectMessage')}</FormLabel>
      <FormGroup>
        {messagesTypes.map((message: any) =>
          <FormControlLabel
            key={message.code}
            control={
              <Checkbox
                checked={selected === message.code}
                onChange={handleChange} id={message.code}
              />
            }
            label={message.label}
          />
        )}
      </FormGroup>
    </FormControl>
  );
}



