import { useState, useEffect, useCallback } from 'react';
import { GridContainer, GridItem, Card, CardBody, Button, Snackbar } from 'components';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import freightWayBill from './styles';
import { listAirports, sendFreightWaybill, listCountries, getFreightWaybill, listCurrency } from '../../services/api';
import { formatDocumentNumber } from '../../utils/formatDocumentNumber';
import { formatNumber, formatNumberBR } from 'utils/formatNumber';
import { Input } from 'components/Input/Input';
import { useLocation, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';


interface UrlParams {
  id: string;
  documentnumber: string;
}

interface Airports {
  id: number;
  Abreviatura: string;
}

interface Currency {
  moedaid: number;
  simbolo_moeda: string;
}

interface Countries {
  id: number;
  Nome: string;
  Sigla: string;
  Nomeing: string;
}

interface PostalAddressInterface {
  StreetName: string;
  CityName: string;
  CountryID: string;
};

function FreightWayBill(props: any) {
  const location = useLocation();

  const { classes } = props;
  const { t } = useTranslation();

  //const user = useContext(UserContext) as IUser;

  //const [redirect, setRedirect] = useState(false);

  const [airportsList, setAirportsList] = useState<Airports[]>([]);
  const [countriesList, setCountriesList] = useState<Countries[]>([]);
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);

  const [documentNumber, setDocumentNumber] = useState('');
  const [operation, setOperation] = useState('');
  const [typeDocument, setTypeDocument] = useState('MAWB');
  const [dateOfIssue, setDateOfIssue] = useState(new Date().toISOString().substring(0, 10));
  const [signatoryCarrier, setSignatoryCarrier] = useState('');
  const [signatoryLocation, setSignatoryLocation] = useState('');
  const [includedTareGrossWeightMeasure, setIncludedTareGrossWeightMeasure] = useState('');
  const [grossVolumeMeasure, setGrossVolumeMeasure] = useState('');
  const [totalPieceQuantity, setTotalPieceQuantity] = useState('');

  const [consignorName, setConsignorName] = useState('');

  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeCNPJ, setConsigneeCNPJ] = useState('');

  const [originAirport, setOriginAirport] = useState('');
  const [destinationAirport, setDestinationAirport] = useState('');

  const [currencyCode, setCurrencyCode] = useState('');
  const [shippingPaymentMethod, setShippingPaymentMethod] = useState('');
  const [weightCode, setWeightCode] = useState('');
  const [volumeCode, setVolumeCode] = useState('');

  const [grossWeightItem, setGrossWeightItem] = useState('');
  const [grossVolumeItem, setGrossVolumeItem] = useState('');
  const [pieceQuantityItem, setPieceQuantityItem] = useState('');
  const [descriptionItem, setDescriptionItem] = useState('');

  const [rateCode, setRateCode] = useState('');
  const [chargeableWeightItem, setChargeableWeightItem] = useState('');
  const [appliedRateItem, setAppliedRateItem] = useState('');
  const [appliedAmountItem, setAppliedAmountItem] = useState('');
  const [informationItem, setInformationItem] = useState('NDA');

  //const [otherChargesPaymentMethod, setOtherChargesPaymentMethod] = useState('');
  const [weightTotalAmountPrepaid, setWeightTotalAmountPrepaid] = useState('');
  const [carrierTotalAmountPrepaid, setCarrierTotalAmountPrepaid] = useState('');
  const [agentTotalAmountPrepaid, setAgentTotalAmountPrepaid] = useState('');
  const [taxTotalAmountPrepaid, setTaxTotalAmountPrepaid] = useState('');
  const [grandTotalAmountPrepaid, setGrandTotalAmountPrepaid] = useState('0');

  const [weightTotalAmountCollect, setWeightTotalAmountCollect] = useState('');
  const [carrierTotalAmountCollect, setCarrierTotalAmountCollect] = useState('');
  const [agentTotalAmountCollect, setAgentTotalAmountCollect] = useState('');
  const [taxTotalAmountCollect, setTaxTotalAmountCollect] = useState('');
  const [grandTotalAmountCollect, setGrandTotalAmountCollect] = useState('0');

  //const [statusMessage, setStatusMessage] = useState('');

  const [postalAddressConsignor, setPostalAddressConsignor] = useState<PostalAddressInterface>({
    StreetName: '',
    CityName: '',
    CountryID: '',
  });

  const [postalAddressConsignee, setPostalAddressConsignee] = useState<PostalAddressInterface>({
    StreetName: '',
    CityName: '',
    CountryID: '',
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });


  // const loadAirports = useCallback(async () => {
  //   const response = await listAirports();
  //   setAirportsList(response?.data);
  // }, []);

  const loadAirports = useCallback(async () => {
    const response = await listAirports();

    const newArray = response.data.map((objeto) => {
      objeto.label = `${objeto.Abreviatura} ${objeto.Nome === null ? '' : ' - ' + objeto.Nome}`;
      objeto.value = objeto.Abreviatura;
      return objeto;
    });
    setAirportsList(newArray);
    //setOptions(newArray);
  }, []);

  const loadCountries = useCallback(async () => {
    const response = await listCountries();
    setCountriesList(response?.data);
  }, []);

  const loadCurrency = useCallback(async () => {
    const response = await listCurrency();
    setCurrencyList(response?.data);
  }, []);

  const { id, documentnumber } = useParams<UrlParams>();

  useEffect(() => {
    if (id) {
      loadDocument(id, documentnumber);
      setDocumentNumber(documentnumber);
    }

    loadAirports();
    loadCurrency();
    loadCountries();
  }, [location]);

  // useEffect(() => {
  //   if (id) {
  //     loadDocument(id, documentnumber);
  //     setDocumentNumber(documentnumber);
  //   }

  //   loadAirports();
  //   loadCurrency();
  //   loadCountries();
  // }, [location]);



  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      setOpen({ open: false, severity: '', message: '' });
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  const loadDocument = async (id: string, documentnumber: string) => {
    const response = await getFreightWaybill(id, documentnumber);
    const waybill = response?.data?.Waybill;

    //setStatusMessage(response?.data?.Status);

    setDocumentNumber(waybill.MasterConsignment?.MasterNumber);
    setTypeDocument(waybill.MasterConsignment?.TypeDocument);
    setDateOfIssue(waybill.DateIssue.substring(0, 10));
    setOperation(waybill.Operation);
    setSignatoryCarrier(waybill.BusinessHeaderDocument?.SignatoryCarrier.Signatory);
    setSignatoryLocation(waybill.BusinessHeaderDocument?.SignatoryCarrier.Location);
    setIncludedTareGrossWeightMeasure(formatNumberBR(waybill.MasterConsignment?.IncludedTareGrossWeightMeasure));
    setGrossVolumeMeasure(formatNumberBR(waybill.MasterConsignment?.GrossVolumeMeasure));
    setTotalPieceQuantity(formatNumberBR(waybill.MasterConsignment?.TotalPieceQuantity));

    setConsignorName(waybill.MasterConsignment?.ConsignorParty?.Name);
    setPostalAddressConsignor(waybill.MasterConsignment?.ConsignorParty?.PostalStructuredAddress);

    setConsigneeName(waybill.MasterConsignment?.ConsigneeParty?.Name);
    setConsigneeCNPJ(waybill.MasterConsignment?.ConsigneeParty?.CNPJ);
    setPostalAddressConsignee(waybill.MasterConsignment?.ConsigneeParty?.PostalStructuredAddress);

    const origin = waybill.MasterConsignment?.OriginLocation;
    const destination = waybill.MasterConsignment?.FinalDestinationLocation;

    setOriginAirport(origin);
    setDestinationAirport(destination);

    setValueOriginAirport({ label: origin, value: origin });
    setValueDestinationAirport({ label: destination, value: destination });

    setCurrencyCode(waybill.MasterConsignment?.CurrencyCode);
    setShippingPaymentMethod(waybill.MasterConsignment?.TotalChargeIndicator);
    setWeightCode(waybill.MasterConsignment?.WeightCode === "KGM" ? "KG" : "LB");
    setVolumeCode(waybill.MasterConsignment?.VolumeCode === "MTQ" ? "M3" : "CM3",);

    setGrossWeightItem(formatNumberBR(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.GrossWeightMeasure));
    setGrossVolumeItem(formatNumberBR(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.GrossVolumeMeasure));
    setPieceQuantityItem(formatNumberBR(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.PieceQuantity));
    setDescriptionItem(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.Identification);
    setInformationItem(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.Information);

    setRateCode(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.ApplicableFreightRateServiceCharge?.CategoryCode);
    setChargeableWeightItem(formatNumberBR(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.ApplicableFreightRateServiceCharge?.ChargeableWeightMeasure));
    setAppliedRateItem(formatNumberBR(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.ApplicableFreightRateServiceCharge?.AppliedRate));
    setAppliedAmountItem(formatNumberBR(waybill.MasterConsignment?.ApplicableRating?.IncludedMasterConsignmentItem?.ApplicableFreightRateServiceCharge?.AppliedAmount));

    if (waybill.MasterConsignment?.ApplicableTotalRating?.ApplicablePrepaidMonetarySummation !== null) {
      //setOtherChargesPaymentMethod(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicablePrepaidMonetarySummation?.PaymentIndicator ? "PP" : "CC");
      setWeightTotalAmountPrepaid(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicablePrepaidMonetarySummation?.WeightChargeTotalAmount));
      setCarrierTotalAmountPrepaid(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicablePrepaidMonetarySummation?.CarrierTotalDuePayableAmount));
      setAgentTotalAmountPrepaid(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicablePrepaidMonetarySummation?.AgentTotalDuePayableAmount));
      setTaxTotalAmountPrepaid(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicablePrepaidMonetarySummation?.AgentTotalDuePayableAmount));
      setGrandTotalAmountPrepaid(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicablePrepaidMonetarySummation?.GrandTotalAmount));
    }
    if (waybill.MasterConsignment?.ApplicableTotalRating?.ApplicableCollectMonetarySummation !== null) {
      //setOtherChargesPaymentMethod(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicableCollectMonetarySummation?.PaymentIndicator ? "PP" : "CC");
      setWeightTotalAmountCollect(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicableCollectMonetarySummation?.WeightChargeTotalAmount));
      setCarrierTotalAmountCollect(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicableCollectMonetarySummation?.CarrierTotalDuePayableAmount));
      setAgentTotalAmountCollect(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicableCollectMonetarySummation?.AgentTotalDuePayableAmount));
      setTaxTotalAmountCollect(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicableCollectMonetarySummation?.Tax));
      setGrandTotalAmountCollect(formatNumberBR(waybill.MasterConsignment?.ApplicableTotalRating?.ApplicableCollectMonetarySummation?.GrandTotalAmount));
    }
  }


  const handleChangePostalAddresConsignee = (event: any) => {
    let data = postalAddressConsignee;
    // @ts-ignore: Unreachable code error
    data[event.target.name] = event.target.value;

    setPostalAddressConsignee((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  }

  const handleChangePostalAddresConsignor = (event: any) => {
    let data = postalAddressConsignor;
    // @ts-ignore: Unreachable code error
    data[event.target.name] = event.target.value;

    setPostalAddressConsignor((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  }

  const handleSubmit = async (e: any) => {
    setLoading(true);

    e.preventDefault();

    const waybill = {
      IssuerCNPJ: localStorage.getItem("company"),
      Operation: operation,
      BusinessHeaderDocument: {
        SignatoryCarrier: {
          Signatory: signatoryCarrier,
          Location: signatoryLocation,
        },
      },
      DateIssue: dateOfIssue,
      MasterConsignment: {
        MasterNumber: documentNumber,
        TypeDocument: typeDocument,
        TotalChargeIndicator: shippingPaymentMethod,
        //TotalDisbursementIndicator: true,
        IncludedTareGrossWeightMeasure: formatNumber(includedTareGrossWeightMeasure),
        WeightCode: weightCode === "KG" ? "KGM" : "LBR",
        GrossVolumeMeasure: formatNumber(grossVolumeMeasure),
        VolumeCode: volumeCode === "M3" ? "MTQ" : "CMQ",
        TotalPieceQuantity: formatNumber(totalPieceQuantity),
        ConsignorParty: {
          Name: consignorName,
          PostalStructuredAddress: postalAddressConsignor
        },
        ConsigneeParty: {
          Name: consigneeName,
          CNPJ: consigneeCNPJ,
          PostalStructuredAddress: postalAddressConsignee
        },
        OriginLocation: originAirport,
        FinalDestinationLocation: destinationAirport,
        CurrencyCode: currencyCode,
        TransportPaymentMethodCode: shippingPaymentMethod,
        ApplicableRating: {
          IncludedMasterConsignmentItem: {
            GrossWeightMeasure: formatNumber(grossWeightItem),
            GrossVolumeMeasure: formatNumber(grossVolumeItem),
            PieceQuantity: formatNumber(pieceQuantityItem),
            Identification: descriptionItem,
            Information: informationItem,
            ApplicableFreightRateServiceCharge: {
              CategoryCode: rateCode,
              ChargeableWeightMeasure: formatNumber(chargeableWeightItem),
              AppliedRate: formatNumber(appliedRateItem),
              AppliedAmount: formatNumber(appliedAmountItem)
            }
          }
        },
        ApplicableTotalRating: {
          TypeCode: "F",
          ApplicablePrepaidMonetarySummation: {
            WeightChargeTotalAmount: formatNumber(weightTotalAmountPrepaid),
            CarrierTotalDuePayableAmount: formatNumber(carrierTotalAmountPrepaid),
            AgentTotalDuePayableAmount: formatNumber(agentTotalAmountPrepaid),
            TaxTotalAmount: formatNumber(taxTotalAmountPrepaid),
            GrandTotalAmount: formatNumber(grandTotalAmountPrepaid),
          },
          ApplicableCollectMonetarySummation: {
            WeightChargeTotalAmount: formatNumber(weightTotalAmountCollect),
            CarrierTotalDuePayableAmount: formatNumber(carrierTotalAmountCollect),
            AgentTotalDuePayableAmount: formatNumber(agentTotalAmountCollect),
            TaxTotalAmount: formatNumber(taxTotalAmountCollect),
            GrandTotalAmount: formatNumber(grandTotalAmountCollect),
          },
        }
      }
    };

    try {
      const response = await sendFreightWaybill(waybill);
      //history.push(`/insertdocumentfreightwaybill/${response.data.id}/${response.data.NumeroConhecimento}`);

      setDocumentNumber('');
      setDateOfIssue('');
      setSignatoryCarrier('');
      setSignatoryLocation('');
      setIncludedTareGrossWeightMeasure('');
      setGrossVolumeMeasure('');
      setTotalPieceQuantity('');
      setConsignorName('');
      setPostalAddressConsignor({
        StreetName: '',
        CityName: '',
        CountryID: '',
      });
      setConsigneeName('');
      setConsigneeCNPJ('');
      setPostalAddressConsignee({
        StreetName: '',
        CityName: '',
        CountryID: '',
      });
      setOriginAirport('');
      setDestinationAirport('');
      setCurrencyCode('');
      setShippingPaymentMethod('');
      setWeightCode('');
      setGrossWeightItem('');
      setGrossVolumeItem('');
      setPieceQuantityItem('');
      setDescriptionItem('');
      setRateCode('');
      setChargeableWeightItem('');
      setAppliedRateItem('');
      setAppliedAmountItem('');
      //setOtherChargesPaymentMethod('');
      setWeightTotalAmountPrepaid('');
      setCarrierTotalAmountPrepaid('');
      setAgentTotalAmountPrepaid('');
      setGrandTotalAmountPrepaid('0');
      setTaxTotalAmountPrepaid('');
      setWeightTotalAmountCollect('');
      setCarrierTotalAmountCollect('');
      setAgentTotalAmountCollect('');
      setGrandTotalAmountCollect('0');
      setTaxTotalAmountCollect('');

      setOpen({ open: true, severity: 'success', message: response?.data.Mensagem });

      //setRedirect(true);
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else if (error?.name === 'Error') {
        const message = error?.response?.data?.erro ? error?.response?.data?.erro : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = t('networkError');
        setOpen({ open: true, severity: 'error', message: message });
      }
    }

    setLoading(false);
  }

  //const [options, setOptions] = useState();

  const createOption = (label: string) => ({
    label,
    value: label.replace(/\W/g, ''),
  });

  useEffect(() => {
    setGrandTotalAmountPrepaid(formatNumberBR(Number(formatNumber(weightTotalAmountPrepaid)) + Number(formatNumber(taxTotalAmountPrepaid)) + Number(formatNumber(agentTotalAmountPrepaid)) + Number(formatNumber(carrierTotalAmountPrepaid))));
  }, [weightTotalAmountPrepaid, taxTotalAmountPrepaid, agentTotalAmountPrepaid, carrierTotalAmountPrepaid]);

  useEffect(() => {
    setGrandTotalAmountCollect(formatNumberBR(Number(formatNumber(weightTotalAmountCollect)) + Number(formatNumber(taxTotalAmountCollect)) + Number(formatNumber(agentTotalAmountCollect)) + Number(formatNumber(carrierTotalAmountCollect))));
  }, [weightTotalAmountCollect, taxTotalAmountCollect, agentTotalAmountCollect, carrierTotalAmountCollect]);



  const [valueOriginAirport, setValueOriginAirport] = useState();

  const handleCreateOrigin = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setAirportsList((prev) => [...prev, newOption]);
    setValueOriginAirport(newOption);
    setOriginAirport(newOption?.value);
  };

  function handleChangeOriginAirport(event) {
    setValueOriginAirport(event);
    setOriginAirport(event?.value);
  }

  const [valueDestinationAirport, setValueDestinationAirport] = useState();

  const handleCreateDestination = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setAirportsList((prev) => [...prev, newOption]);
    setValueDestinationAirport(newOption);
    setDestinationAirport(newOption?.value);
  };

  function handleChangeDestinationAirport(event) {
    setValueDestinationAirport(event);
    setDestinationAirport(event?.value);
  }


  return (
    <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <span className={classes.textTitle}>Cadastro de FWB</span>
            <form onSubmit={handleSubmit}>
              <CardBody className={classes.container}>

                <div className={classes.inputGroup}>
                  <div className={classes.inputBox}>
                    <span>Tipo</span>
                    <select className={classes.input} value={typeDocument} onChange={e => { setTypeDocument(e.target.value) }} required>
                      <option key="MAWB" value="MAWB">MAWB</option>
                      <option key="AWB" value="AWB">AWB</option>
                    </select>
                  </div>
                </div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Conhecimento</span>
                  <div className={classes.inputs}>
                    <Input title="Número do Conhecimento" required={true} classes={classes} value={documentNumber} onchange={setDocumentNumber} maxlength={12} funcaoFormatacao={formatDocumentNumber} />

                    <Input title="Data de Emissão" required={true} classes={classes} value={dateOfIssue} onchange={setDateOfIssue} type="date" />

                    <div className={classes.inputBox}>
                      <span>Operação</span>
                      <select className={classes.input} value={operation} onChange={e => { setOperation(e.target.value) }} required>
                        <option></option>
                        <option value="Deletion">Deletar</option>
                        <option value="Update">Retificar</option>
                        <option value="Creation">Criar</option>
                        {/* {statusMessage === "Processed" ? (
                          <>
                            <option value="Deletion">Deletar</option>
                            <option value="Update">Retificar</option>
                          </>
                        ) :
                          (
                            <>
                              <option value="Creation">Criar</option>
                            </>
                          )
                        } */}

                        {/* {statusMessage === "Processed" && (
                          <>
                            <option value="Deletion">Deletar</option>
                            <option value="Update">Retificar</option>
                          </>
                        )} */}

                      </select>
                    </div>

                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Assinatura Carrier</span>
                  <div className={classes.inputs}>

                    <Input title="Assinatura" required={true} classes={classes} value={signatoryCarrier} onchange={setSignatoryCarrier} maxLength={35} />

                    <Input title="Localização do Carrier" required={true} classes={classes} value={signatoryLocation} onchange={setSignatoryLocation} />

                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Quantidades</span>
                  <div className={classes.inputs}>
                    <Input numeric title={`Peso Bruto Total (${weightCode})`} required={true} classes={classes} value={includedTareGrossWeightMeasure} onchange={setIncludedTareGrossWeightMeasure} placeholder="0,000" />
                    <Input numeric title={`Volume Total (${volumeCode})`} required={true} classes={classes} value={grossVolumeMeasure} onchange={setGrossVolumeMeasure} placeholder="0,000"/>
                    <Input numeric title="Quantidade de Peças" required={true} classes={classes} value={totalPieceQuantity} onchange={setTotalPieceQuantity}  placeholder="0" decimalScale={0}/>
                  </div>

                  <div className={classes.inputs} >
                    <div className={classes.inputBox}>
                      <span>Unidade Peso</span>
                      <select className={classes.input} value={weightCode} onChange={e => { setWeightCode(e.target.value) }} required>
                        <option></option>
                        <option key="KGM" value="KG">KG</option>
                        <option key="LBR" value="LB">LB</option>
                      </select>
                    </div>

                    <div className={classes.inputBox}>
                      <span>Unidade Volume</span>
                      <select className={classes.input} value={volumeCode} onChange={e => { setVolumeCode(e.target.value) }} required>
                        <option></option>
                        <option key="KGM" value="M3">M3</option>
                        <option key="LBR" value="CM3">CM3</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Embarcador estrangeiro</span>
                  <div className={classes.inputs}>
                    <Input title="Nome" required={true} classes={classes} value={consignorName} onchange={setConsignorName} styleWidth={"100%"} maxlength={70} />
                  </div>
                </div>

                <div className={classes.inputGroup}>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <span>Endereço</span>
                      <input name="StreetName" value={postalAddressConsignor?.StreetName} className={classes.input} onChange={event => handleChangePostalAddresConsignor(event)} type='text' required maxLength={70} />
                    </div>
                  </div>
                </div>

                <div className={classes.inputGroup}>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <span>Cidade</span>
                      <input name="CityName" value={postalAddressConsignor?.CityName} className={classes.input} onChange={event => handleChangePostalAddresConsignor(event)} type='text' required maxLength={70} />
                    </div>
                    <div className={classes.inputBox}>
                      <span>Pais</span>
                      <select name="CountryID" className={classes.input} value={postalAddressConsignor?.CountryID} onChange={e => { handleChangePostalAddresConsignor(e) }} required >
                        <option></option>
                        {countriesList.map((country) => (
                          <option key={country?.id} value={country?.Sigla}>{country?.Nome}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Consignatário</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <span>Nome</span>
                      <input value={consigneeName} className={classes.input} placeholder="" onChange={e => { setConsigneeName(e.target.value) }} type='text' required maxLength={70} />
                    </div>
                  </div>
                </div>

                <div className={classes.inputGroup}>
                  <div className={classes.inputs} >
                    <div className={classes.inputBox} style={{ minWidth: '100%' }}>
                      <span>Endereço</span>
                      <input name="StreetName" value={postalAddressConsignee?.StreetName} className={classes.input} onChange={event => handleChangePostalAddresConsignee(event)} type='text' required maxLength={70} />
                    </div>
                  </div>
                </div>


                <div className={classes.inputGroup}>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <span>Cidade</span>
                      <input name="CityName" value={postalAddressConsignee?.CityName} className={classes.input} onChange={event => handleChangePostalAddresConsignee(event)} type='text' required maxLength={70} />
                    </div>

                    <div className={classes.inputBox}>
                      <span>Pais</span>
                      <select name="CountryID" className={classes.input} value={postalAddressConsignee?.CountryID} onChange={e => { handleChangePostalAddresConsignee(e) }} required >
                        <option></option>
                        {countriesList.map((country) => (
                          <option key={country?.id} value={country?.Sigla}>{country?.Nome}</option>
                        ))}
                      </select>
                    </div>

                    <Input title="CNPJ / CPF" required={true} classes={classes} value={consigneeCNPJ} onchange={setConsigneeCNPJ} />
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Aeroportos</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <span>Origem</span>

                      <CreatableSelect
                        placeholder={t('selectAirport')}
                        isClearable
                        onChange={handleChangeOriginAirport}
                        onCreateOption={handleCreateOrigin}
                        options={airportsList}
                        value={valueOriginAirport}
                        className={classes.inputSelect}
                        styles={customStyles}
                        isRequired
                      />

                      {/* <select className={classes.input} value={originAirport} onChange={e => { setOriginAirport(e.target.value) }} required >
                        <option></option>
                        {airportsList.map((airport) => (
                          <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                        ))}
                      </select> */}
                    </div>

                    <div className={classes.inputBox}>
                      <span>Destino</span>

                      <CreatableSelect
                        placeholder={t('selectAirport')}
                        isClearable
                        onChange={handleChangeDestinationAirport}
                        onCreateOption={handleCreateDestination}
                        options={airportsList}
                        value={valueDestinationAirport}
                        className={classes.inputSelect}
                        styles={customStyles}
                        isRequired
                      />
                      {/* <select className={classes.input} value={destinationAirport} onChange={e => { setDestinationAirport(e.target.value) }} required >
                        <option></option>
                        {airportsList.map((airport) => (
                          <option key={airport?.id} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Moeda e Modelo</span>
                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <span>Moeda</span>
                      <select className={classes.input} value={currencyCode} onChange={e => { setCurrencyCode(e.target.value) }} required>
                        <option></option>
                        {currencyList.map((currency) => (
                          <option key={currency?.moedaid} value={currency?.simbolo_moeda}>{currency?.simbolo_moeda}</option>
                        ))}
                      </select>
                    </div>

                    <div className={classes.inputBox}>
                      <span>Modelo Taxa de Serviço</span>
                      <select className={classes.input} value={shippingPaymentMethod} onChange={e => { setShippingPaymentMethod(e.target.value) }} required>
                        <option></option>
                        <option key="PP" value="PP">PREPAID</option>
                        <option key="CC" value="CC">COLLECT</option>
                      </select>
                    </div>



                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Item</span>
                  <div className={classes.inputs}>
                    <Input numeric title={`Peso Taxado (${weightCode})`} placeholder="0,000" required={true} classes={classes} value={grossWeightItem} onchange={setGrossWeightItem} />

                    <Input numeric title={`Volume Total (${volumeCode})`} placeholder="0,000" required={true} classes={classes} value={grossVolumeItem} onchange={setGrossVolumeItem} />

                    <Input title="Quantidade" placeholder="0" required={true} classes={classes} value={pieceQuantityItem} onchange={setPieceQuantityItem} />
                  </div>

                  {/* 
                  {(grossVolumeItem === '0' || grossVolumeItem === '') && <>
                    <div className={classes.inputs} >
                      <Input title="Quanidade" required={true} classes={classes} value={descriptionItem} onchange={setDescriptionItem} maxlength={70} />
                      <Input title="WidthMeasure" required={true} classes={classes} value={descriptionItem} onchange={setDescriptionItem} maxlength={70} />
                      <Input title="LengthMeasure" required={true} classes={classes} value={descriptionItem} onchange={setDescriptionItem} maxlength={70} />
                      <Input title="HeightMeasure" required={true} classes={classes} value={descriptionItem} onchange={setDescriptionItem} maxlength={70} />
                      <Input title="Unidade" required={true} classes={classes} value={descriptionItem} onchange={setDescriptionItem} maxlength={70} />
                    </div>
                  </>
                  } */}

                  {(grossVolumeItem === '0' || grossVolumeItem === '') && (
                    <div className={classes.inputs}>
                      <Input title="Informação" required={true} classes={classes} value={informationItem} onchange={setInformationItem} maxlength={3} />
                    </div>
                  )}



                  <div className={classes.inputs} >
                    <Input title="Descrição" required={true} classes={classes} value={descriptionItem} onchange={setDescriptionItem} maxlength={70} styleWidth={"100%"} />
                  </div>


                  <div className={classes.inputs}>
                    <div className={classes.inputBox}>
                      <span>Categoria da Taxa</span>
                      <select className={classes.input} value={rateCode} onChange={e => { setRateCode(e.target.value) }}>
                        <option></option>
                        <option key="B" value="B">Basic Charge</option>
                        <option key="R" value="R">Class Rate Reduction</option>
                        <option key="S" value="S">Class Rate Surcharge</option>
                        <option key="P" value="P">International Priority Service Rate</option>
                        <option key="M" value="M">Minimum Charge</option>
                        <option key="N" value="N">Normal Rate</option>
                        <option key="Q" value="Q">Quantity Rate</option>
                        <option key="K" value="K">Rate per Kilogram</option>
                        <option key="C" value="C">Specific Commodity Rate</option>
                        <option key="X" value="X">Unit Load Device Additional Information</option>
                        <option key="E" value="E">Unit Load Device Additional Rate</option>
                        <option key="U" value="U">Unit Load Device Basic Charge or Rate</option>
                        <option key="Y" value="Y">Unit Load Device Discount</option>
                      </select>
                    </div>

                    <Input numeric title={`Peso Taxado (${weightCode})`} placeholder="0,000" required={true} classes={classes} value={chargeableWeightItem} onchange={setChargeableWeightItem} decimalScale={3}/>

                    <Input numeric title="Taxa" placeholder="0,00" required={true} classes={classes} value={appliedRateItem} onchange={setAppliedRateItem} decimalScale={2} />

                    <Input numeric title="Valor Total da Taxa" placeholder="0,00" required={true} classes={classes} value={appliedAmountItem} onchange={setAppliedAmountItem} decimalScale={2} />

                  </div>
                </div>

                <div className={classes.separator}></div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Valores Totais Cobrança (PP)</span>
                  <div className={classes.inputs}>
                    <Input numeric title="Valor Peso Total Taxado" placeholder="0,00" classes={classes} value={weightTotalAmountPrepaid} onchange={setWeightTotalAmountPrepaid} decimalScale={2} />
                    <Input numeric title="Valor Total Devido ao Transportador" placeholder="0,00" classes={classes} value={carrierTotalAmountPrepaid} onchange={setCarrierTotalAmountPrepaid} decimalScale={2} />
                    <Input numeric title="Valor Total Devido ao Agente" placeholder="0,00" classes={classes} value={agentTotalAmountPrepaid} onchange={setAgentTotalAmountPrepaid} decimalScale={2} />
                  </div>

                  <div className={classes.inputs}>
                    <Input numeric title="Valor de Impostos" placeholder="0,00" classes={classes} value={taxTotalAmountPrepaid} onchange={setTaxTotalAmountPrepaid} decimalScale={2} />
                    <Input numeric title="Valor Total" placeholder="0,00" required={true} classes={classes} value={grandTotalAmountPrepaid} onchange={setGrandTotalAmountPrepaid} decimalScale={2} />
                  </div>
                </div>

                <div className={classes.inputGroup}>
                  <span className={classes.titleInputGroup}>Valores Totais Cobrança (CC)</span>
                  <div className={classes.inputs}>
                    <Input numeric title="Valor Peso Total Taxado" placeholder="0,00" classes={classes} value={weightTotalAmountCollect} onchange={setWeightTotalAmountCollect} decimalScale={2} />
                    <Input numeric title="Valor Total Devido ao Transportador" placeholder="0,00" classes={classes} value={carrierTotalAmountCollect} onchange={setCarrierTotalAmountCollect} decimalScale={2} />
                    <Input numeric title="Valor Total Devido ao Agente" placeholder="0,00" classes={classes} value={agentTotalAmountCollect} onchange={setAgentTotalAmountCollect} decimalScale={2} />
                  </div>

                  <div className={classes.inputs}>
                    <Input numeric title="Valor de Impostos" placeholder="0,00" classes={classes} value={taxTotalAmountCollect} onchange={setTaxTotalAmountCollect} decimalScale={2} />
                    <Input numeric title="Valor Total" placeholder="0,00" required={true} classes={classes} value={grandTotalAmountCollect} onchange={setGrandTotalAmountCollect} decimalScale={2} />
                  </div>
                </div>

                <div className={classes.buttonContainer}>
                  <Button
                    disabled={loading}
                    type='submit'
                  >
                    {t('send')}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </CardBody>
            </form >
          </Card>
        </GridItem>
        {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
      </GridContainer>
    </>
  );
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    border: '1px solid gray',
    borderRadius: '4px',
    minHeight: '1px',
    height: '32px',
    boxShadow: state.isFocused ? '0 0 0 2px lightblue' : 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'lightblue' : 'white',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  }),
  menu: (provided) => ({
    ...provided,
  }),
};

export default (withStyles(freightWayBill)(FreightWayBill));
