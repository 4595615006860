import { useState, useEffect, useContext, useCallback } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { GridContainer, GridItem, Card, CardBody, Snackbar, CardHeader } from 'components';
import { getCompany } from 'services';
import { UserContext } from 'context';
import { DataGrid, GridToolbar, GridColDef } from '@material-ui/data-grid';
import PeopleIcon from '@material-ui/icons/People';
import companyPanel from './styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Company {
	cod_emp: number;
	razaosocial_emp: string;
	fantasia_emp: string;
	cgc_emp: string;
	nome_ramo: string;
}

function CompanyPanel(props: any) {
	const { t } = useTranslation();
	const { classes } = props;
	const user = useContext(UserContext) as IUser;
	const [companyList, setcompanyList] = useState<Company[]>([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState({ open: false, severity: '', message: '' });

	const handleLoaduser = useCallback(async () => {

		setLoading(true);
		try {

			const response = await getCompany();
			if (response && response.data) {
				setcompanyList(response.data);
			}
		}
		catch (error) {
			if (error?.response?.status === 401) {
				const message = t('expiredToken');
				setOpen({ open: true, severity: 'error', message: String(message) });
			}
			else {
				const message = error?.response?.data ? error?.response?.data : error;
				setOpen({ open: true, severity: 'error', message: String(message) });
			}
		}
		setLoading(false);
	}, [user]);

	useEffect(() => {
		handleLoaduser();
	}, [handleLoaduser]);

	const handleClose = (reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen({ open: false, severity: '', message: '' });
	};

  const application = process.env.REACT_APP_APPLICATION === undefined ? '' : process.env.REACT_APP_APPLICATION;

	const columns: GridColDef[] = [
		{
			field: 'razaosocial_emp',
			headerName: 'Nome',
			flex: 4,
			renderCell: params => {
				return <Link to={`${application}/companys/${params.row.id}`} className={classes.link} >{params.value}</Link>
			}
		},
		{
			field: 'cgc_emp',
			headerName: 'CNPJ',
			flex: 1
		},
		{
			field: 'nome_ramo',
			headerName: 'Tipo',
			flex: 1
		}

	];

	return (
		<GridContainer>
			<GridItem xs={11}>
				<Card>
					<CardHeader color='primary' icon>
						<div className={classes.cardIcon}>
							<PeopleIcon />
						</div>
						<h4 className={classes.cardTitle}>
							Empresas
						</h4>
					</CardHeader>
					<CardBody>
						<div className={classes.cardGrid}>
							<DataGrid
								pageSize={30}
								getRowId={(row) => row.cod_emp}
								autoHeight
								loading={loading}
								className={classes.root}
								rows={companyList}
								hideFooterRowCount={true}
								hideFooterSelectedRowCount={true}
								columns={columns}
								components={{
									Toolbar: GridToolbar,
								}}
							/>
						</div>

					</CardBody>

				</Card>
			</GridItem>
			{open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
		</GridContainer>
	);
}

export default (withStyles(companyPanel)(CompanyPanel));
