import createStyles from '@material-ui/core/styles/createStyles';
import { defaultText, idataDefaultColor,title } from 'assets/App';

const uploadMessagesStyle = () => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    minHeight: 300
  },
  textTitle: {
    ...title,
    textAlign: 'center',
  },
  messageContainer: {
    marginTop: 20,
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: 20
  },
  hide: {
    overflow: 'hidden',
  },
  input: {
    cursor: 'pointer',
    display: 'block',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  inputFile: {
    cursor: 'pointer',
    display: 'block',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
 },
  filename: {
    ...defaultText,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    ...defaultText,
  },
  buttonProgress: {
    color: idataDefaultColor,
    position: 'absolute',
    marginTop: 5,
    marginLeft: -45,
  },
});

export default uploadMessagesStyle;
