import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import DrawerNavItem from './DrawerNavItem';
import HeaderLinks from 'layouts/Dashboard/Header/Links';
import sidebarStyle from './styles';

var ps: PerfectScrollbar;

function SidebarWrapper(props: any) {

  const { className, user, headerLinks, links } = props;

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar("#sidebarWrapper", {
        suppressScrollX: true,
        suppressScrollY: false,

      });
    }
  }, []);

  useEffect(() => {
    return () => {
      if (navigator.platform.indexOf("Win") > -1)
        ps.destroy();
    }
  }, []);

  return (
    <div className={className} id="sidebarWrapper">
      {user}
      {headerLinks}
      {links}
    </div>
  );
}

function renderNavItems(props: any, routes: any, depth: any, miniActive: boolean) {
  return (
    <List className={props.classes.list}>
      {routes.reduce(
        (items: any, page: any) => reduceChildRoutes(props, items, page, depth, miniActive),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes(props: any, items: any, page: any, depth: any, miniActive: boolean) {

  const activeRoute = (routeName: string) => {
    //const pathname = new URL(routeName).pathname;
    return window.location.pathname === routeName ? true : false;
  }

  const { name, miniName, icon, invisible, path } = page;
  const { mobileOpen, onClose, color } = props;


  if (page.views && page.views.length > 0) {
    items.push(
      <DrawerNavItem
        depth={depth}
        key={name}
        invisible={invisible}
        ItemIcon={icon}
        name={name}
        miniName={miniName}
        miniActiveProp={props.miniActive} // vem do dashboard
        miniActive={miniActive} // state local
        mobileOpen={mobileOpen}
        color={color}
      >
        {renderNavItems(props, page.views, depth + 1, miniActive)}
      </DrawerNavItem>,
    );
  } else {
    items.push(
      <DrawerNavItem
        depth={depth}
        key={name}
        invisible={invisible}
        ItemIcon={icon}
        name={name}
        miniName={miniName}
        miniActiveProp={props.miniActive} // vem do dashboard
        miniActive={miniActive} // state local
        mobileOpen={mobileOpen}
        color={color}
        href={path}
        activeRoute={activeRoute(path)}
        onClick={onClose}
      />
    );
  }
  return items;
}

function Sidebar(props: any) {
  const {
    classes,
    image,
    routes,
    bgColor,    
  } = props;


  const [miniActive, setMiniActive] = useState(true);

  const handleMiniActiveClose = () => {
    setMiniActive(false);
  }

  const handleMiniActiveOpen = () => {
    setMiniActive(true);
  }

  const drawerPaper =
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]:
        props.miniActive && miniActive
    });
  const sidebarWrapper =
    classes.sidebarWrapper +
    " " +
    cx({
      [classes.drawerPaperMini]:
        props.miniActive && miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });

  return (
    <div >
      <Hidden mdUp implementation='css'>
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={props.open}
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"]
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <SidebarWrapper
            className={sidebarWrapper}
            headerLinks={<HeaderLinks />}
            links={renderNavItems(props, routes, 0, miniActive)}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Drawer
          onMouseEnter={handleMiniActiveClose}
          onMouseLeave={handleMiniActiveOpen}
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"]
          }}
        >
          <SidebarWrapper
            className={sidebarWrapper}
            //user={user}
            links={renderNavItems(props, routes, 0, miniActive)}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
            
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

export default withStyles(sidebarStyle)(Sidebar);
