import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'components';
import withStyles from '@material-ui/core/styles/withStyles';
import { ThemeProvider } from '@material-ui/core/styles';
import { GridContainer, GridItem, Card, CardBody, Snackbar, CardHeader } from 'components';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { Box, IconButton, Input } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { manageCertificate, customTheme } from './styles';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserContext } from 'context';
import { insertCertificate, getCertificates, updateCertificates } from 'services';


interface IMessages {
   certificateId: number;
   id: number;
   label: string;
   expirationDate: string;
}

function ManageCertificate(props: any) {

   const user = useContext(UserContext) as IUser;
   const [certificatesConsult, setCertificatesConsult] = useState<IMessages[]>([]);
   const { classes } = props;
   const { t } = useTranslation();
   const [openModalEdit, setOpenModalEdit] = useState(false);
   //const [openModalDelete, setOpenModalDelete] = useState(false);
   const [openModalInsert, setOpenModalInsert] = useState(false);
   const [file, setFile] = useState<string | Blob>('');
   const [filename, setFilename] = useState('');
   const [loading, setLoading] = useState(false);
   const [passwordCertificate, setPasswordCertificate] = useState('');
   const [infoCertificateModal, setInfoCertificateModal] = useState({});
   const [open, setOpen] = useState({ open: false, severity: '', message: '' });



   const getCertificatesAsync = useCallback(async () => {
      setLoading(true);
      try {
         if (user) {
            const response = await getCertificates();
            if (response && response.data) {
               setCertificatesConsult(response.data);
            }
         }
      }
      catch (error) {
         if (error?.response?.status === 401) {
            const message = t('expiredToken');
            setOpen({ open: true, severity: 'error', message: String(message) });
         }
         else {
            const message = error?.response?.data ? error?.response?.data : error;
            setOpen({ open: true, severity: 'error', message: String(message) });
         }
      }
      setLoading(false);

   }, [user]);

   useEffect(() => {
      getCertificatesAsync();
   }, [getCertificatesAsync]);


   //const handleCloseModalDelete = () => setOpenModalDelete(false);

   const handleCloseModalEdit = () => {
      setOpenModalEdit(false);
      setPasswordCertificate('');
      setFile('');
   };
   const handleCloseModalInsert = () => {
      setOpenModalInsert(false);
      setPasswordCertificate('');
      setFile('');
   };

   function handleEditCertificate(params: any) {
      setOpenModalEdit(prevState =>
         prevState = prevState === true ? false : true)

      setInfoCertificateModal({ label: params.row.label, certificateId: params.row?.certificateId });
   }

   const handleInsertCertificate = () => {
      setOpenModalInsert(prevState =>
         prevState = prevState === true ? false : true)
   }

   const handleFile = (e: any) => {
      let file = e.target.files[0];
      setFile(file);
      setFilename(file.name);
   }

   const removeFile = () => {
      setFile('');
      setFilename('')
   }

   const handleClose = (reason?: string) => {
      if (reason === 'clickaway') {
         return;
      }
      setOpen({ open: false, severity: '', message: '' });
   };

   const handleSubmitInsertNewCertificate = async (e: any) => {
      e.preventDefault();
      setLoading(true);


      try {
         let userId = String(user?.usuarioID);
         const formData = new FormData();
         if (userId && file) {
            formData.append('file', file);
            formData.append('senhaCertificado', passwordCertificate);
            formData.append('userId', userId);
         }
         const response = await insertCertificate(formData);
         if (response && response.data) {
            const result = response.data;
            setOpen({ open: true, severity: 'success', message: String(result) });
            removeFile();
            setPasswordCertificate('');
            getCertificatesAsync();
         }
      }
      catch (error) {
         if (error?.response?.status === 401) {
            const message = t('expiredToken');
            setOpen({ open: true, severity: 'error', message: String(message) });
         }
         else {
            const message = error?.response?.data?.erro ? error?.response?.data?.erro : error;
            setOpen({ open: true, severity: 'error', message: String(message) });
         }
      }
      setLoading(false);
   }


   const handleSubmitUpdateCertificate = async (e: any) => {
      e.preventDefault();
      setLoading(true);

      try {
         let userId = String(user?.usuarioID);
         const formData = new FormData();
         if (userId && file) {
            formData.append('file', file);
            formData.append('senhaCertificado', passwordCertificate);
            formData.append('userId', userId);
         }
         const response = await updateCertificates(infoCertificateModal.certificateId, formData);
         if (response && response.data) {
            const result = response.data;
            setOpen({ open: true, severity: 'success', message: String(result) });
            removeFile();
            setPasswordCertificate('');
            getCertificatesAsync();
            setOpenModalEdit(false);
         }
      }
      catch (error) {

         if (error?.response?.status === 401) {
            const message = t('expiredToken');
            setOpen({ open: true, severity: 'error', message: String(message) });
         }
         else {
            const message = error?.response?.data ? error?.response?.data : error;
            setOpen({ open: true, severity: 'error', message: String(message) });
         }
      }
      setLoading(false);
   }


   const columns: GridColDef[] = [
      {
         field: 'label',
         headerName: 'Nome',
         flex: 1,
      },
      {
         field: 'expirationDate',
         headerName: 'Data de validade',
         flex: 1,
      },
      {
         field: "Editar",
         renderCell: params => {
            return (
               <button className={classes.buttonEdit}

                  onClick={() => {
                     handleEditCertificate(params);
                  }}>
                  <EditIcon className={classes.link} />
               </button>
            );
         }
      }
   ];

   return (
      <GridContainer>
         <GridItem xs={11}>
            <Card>
               <CardHeader color='primary' icon>
                  <div className={classes.cardIcon}>
                     <CardMembershipIcon />
                  </div>
                  <h4 className={classes.cardTitle}>
                     {t('certificates')}
                  </h4>

                  {user?.eAdministrador.toString() === '1' && (
                     <div className={classes.addCertificate}>
                        <a className={classes.buttonSubmit} onClick={handleInsertCertificate}>Adicionar certificado</a>
                     </div>
                  )}

               </CardHeader>




               {openModalInsert ?
                  <>
                     <Modal openModal={openModalInsert} handleClose={handleCloseModalInsert} title={t('addCertificate')}>
                        <form onSubmit={handleSubmitInsertNewCertificate}>
                           <Box className={classes.boxForm}>

                              {!file ? (
                                 <div className={classes.messageContainer}>
                                    <div className={classes.hide}>
                                       <IconButton >
                                          <PublishIcon />
                                       </IconButton>
                                       <input
                                          className={classes.inputFile}
                                          type='file'
                                          onChange={handleFile}
                                       />
                                    </div>
                                    <span className={classes.text}>
                                       {t('selectFile')}
                                    </span>
                                 </div>
                              ) : (
                                 <div className={classes.filename}>
                                    <h6 >
                                       {filename}
                                    </h6>
                                    <IconButton onClick={removeFile}>
                                       <DeleteIcon />
                                    </IconButton>
                                 </div>
                              )}

                              <div className={classes.textAreaContainer} >
                                 <span className={classes.text}>Senha do certificado</span>
                                 <Input value={passwordCertificate} className={classes.input} placeholder="********" onChange={e => { setPasswordCertificate(e.target.value) }} required />
                              </div>

                              <div className={classes.buttonContainer}>
                                 <Button type='submit'>
                                    {t('ok')}
                                 </Button>
                                 {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                              </div>
                           </Box>
                        </form>
                     </Modal>
                  </>
                  : <></>}


               {openModalEdit ?
                  <>
                     <Modal openModal={openModalEdit} handleClose={handleCloseModalEdit} title={t('editCertificate') + ' - ' + infoCertificateModal.label}>
                        <form onSubmit={handleSubmitUpdateCertificate}>
                           <Box className={classes.boxForm}>

                              {!file ? (
                                 <div className={classes.messageContainer}>
                                    <div className={classes.hide}>
                                       <IconButton >
                                          <PublishIcon />
                                       </IconButton>
                                       <input
                                          className={classes.inputFile}
                                          type='file'
                                          onChange={handleFile}
                                       />
                                    </div>
                                    <span className={classes.text}>
                                       {t('selectFile')}
                                    </span>
                                 </div>
                              ) : (
                                 <div className={classes.filename}>
                                    <h6 >
                                       {filename}
                                    </h6>
                                    <IconButton onClick={removeFile}>
                                       <DeleteIcon />
                                    </IconButton>
                                 </div>
                              )}

                              <div className={classes.textAreaContainer} >
                                 <span className={classes.text}>Senha do certificado</span>
                                 <Input value={passwordCertificate} className={classes.input} placeholder="********" onChange={e => { setPasswordCertificate(e.target.value) }} required />
                              </div>

                              <div className={classes.buttonContainer}>
                                 <Button type='submit'>{t('ok')}</Button>
                                 {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                              </div>
                           </Box>
                        </form>
                     </Modal>
                  </>
                  : <></>
               }

               <CardBody>
                  <div>
                     <ThemeProvider theme={customTheme}>
                        <DataGrid
                           localeText={{
                              noRowsLabel: t('noRowsLabel')
                           }}
                           pageSize={20}
                           loading={loading}
                           autoHeight
                           hideFooterRowCount={true}
                           hideFooterSelectedRowCount={true}
                           columns={columns}
                           rows={certificatesConsult}
                        />
                     </ThemeProvider>
                  </div>
               </CardBody>

            </Card>
         </GridItem>
         {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
      </GridContainer>
   )

}

export default (withStyles(manageCertificate)(ManageCertificate));
