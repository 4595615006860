import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CustomToolbar, Modal } from 'components';
import withStyles from '@material-ui/core/styles/withStyles';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem, Card, CardBody, Snackbar, CardHeader } from 'components';
import { GridColDef, GridToolbar } from '@material-ui/data-grid';
import { internalControl, customTheme } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserContext } from 'context';
import { Input } from './../../components/Input/Input';
import { getDocumentsSended, getCompanyByPromotor, listAirports } from './../../services/api';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FilterListIcon from '@material-ui/icons/FilterList';
import { formatDate } from './../../utils/formatDate';
import { Link } from 'react-router-dom';

import {
  DataGrid,
} from '@mui/x-data-grid';

interface CompanyInterface {
  id: number;
  nome: string;
}

interface AirportInterface {
  id: number;
  Abreviatura: string;
}

function InternalControl(props: any) {

  const classesGrid = useStyles();

  const user = useContext(UserContext) as IUser;
  const { classes } = props;
  const { t } = useTranslation();
  const [openModalInsert, setOpenModalInsert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ open: false, severity: '', message: '' });
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);
  const [company, setCompany] = useState('');
  const [destinationAirport, setDestinationAirport] = useState('');
  const [initialDate, setInititalDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [datas, setDatas] = useState([]);
  const [airportsList, setAirportsList] = useState<AirportInterface[]>([]);


  useEffect(() => {
    setOpenModalInsert(true);
  }, [])


  const loadCompanies = useCallback(async () => {
    setLoading(true);
    try {
      if (user) {
        const response = await getCompanyByPromotor();
        if (response && response.data) {
          setCompanies(response.data);
        }
      }
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data ? error?.response?.data : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
    }
    setLoading(false);

  }, [user]);

  useEffect(() => {
    loadCompanies();
    loadAirports();
  }, [loadCompanies]);

  const loadAirports = useCallback(async () => {
    const response = await listAirports();

    const airports = response?.data.filter((airport) => airport.UTC != null);

    const newArray = airports.map((objeto) => {
      objeto.label = `${objeto.Abreviatura} ${objeto.Nome === null ? '' : ' - ' + objeto.Nome}`;
      objeto.value = objeto.Abreviatura;
      return objeto;
    });

    setAirportsList(newArray);
  }, []);

  const handleCloseModalInsert = () => {
    setOpenModalInsert(false);
  };

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ open: false, severity: '', message: '' });
  };

  const columns: GridColDef[] = [
    {
      field: 'razaoSocial',
      headerName: 'Empresa',
      flex: 2,
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 150,
    },
    {
      field: 'usuarioNome',
      headerName: 'Usuário',
      width: 250,
      renderCell: params => {
        if(destinationAirport === '' || destinationAirport === undefined) {        
          return <Link target="_blank" to={`/internalcontroldetail/${params.row.empresaId}/${params.row.usuarioId}/${initialDate}/${finalDate}`} className={classes.link} >{params.value}</Link>
        } else {
          return <Link target="_blank" to={`/internalcontroldetail/${params.row.empresaId}/${params.row.usuarioId}/${initialDate}/${finalDate}/${destinationAirport}`} className={classes.link} >{params.value}</Link>
        }
      },
    },
    {
      field: 'obsUsuario',
      headerName: 'Obs.',
      width: 90
    },
    {
      field: 'promotorNome',
      headerName: 'Promotor',
      width: 150
    },
    {
      field: 'quantSucesso',
      headerName: 'Envios',
      width: 100
    },
  ];

  const columnsDataGridFooter: GridColDef[] = [
    {
      field: 'total',
      headerName: '',
      flex: 2,
    },
    {
      field: 'x',
      headerName: '',
      width: 640,
    },
    {
      field: 'quantTotal',
      headerName: '',
      width: 100
    },
  ];


  function handleOpenModal() {
    setCompany('');
    setInititalDate('');
    setFinalDate('');
    setOpenModalInsert(true);
  }

  async function handleSumit(e: any) {
    e.preventDefault();
    try {
      const response = await getDocumentsSended(initialDate, finalDate, company, destinationAirport);
      setDatas(response.data);


      let somaQuantSucesso = 0;
      for (const item of response.data) {
        somaQuantSucesso += item.quantSucesso;
      }
      setTotal([{ identificador: 1, total: 'TOTAL', quantTotal: somaQuantSucesso }]);

      // const totalRow = {
      //   empresaId: 'Total:',
      //   quantidade: '',
      //   quantSucesso: total.toFixed(2), // Formate o total como desejar
      // };

      // setRowsWithTotal([response.data, totalRow]);

      setOpenModalInsert(false);
    }
    catch (error) {
      if (error?.response?.status === 401) {
        const message = t('expiredToken');
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
      else {
        const message = error?.response?.data.erro ? error?.response?.data.erro : error;
        setOpen({ open: true, severity: 'error', message: String(message) });
      }
    }
  }

  const [total, setTotal] = useState([{ identificador: 1, total: 'TOTAL', quantTotal: 0 }]);
  const [filtersDataGrid, setFiltersDataGrid] = useState();

  useEffect(() => {
    let datasFiltered = [];

    const field = filtersDataGrid?.items[0]?.field;
    const value = filtersDataGrid?.items[0]?.value;

    if (value === "" || value === undefined) {
      datasFiltered = datas;
    }
    else if (field === "razaoSocial") {
      datasFiltered = datas.filter((d) => {
        if (d.razaoSocial !== "" && value !== "" && value !== undefined) {
          const razaoSocial = d.razaoSocial?.toLowerCase();
          if (razaoSocial.includes(value.toLowerCase())) {
            return d;
          }
        }
      });
    }
    else if (field === "usuarioNome") {
      datasFiltered = datas.filter((d) => {
        if (d.usuarioNome !== "" && value !== "" && value !== undefined) {
          const usuarioNome = d.usuarioNome?.toLowerCase();
          if (usuarioNome.includes(value.toLowerCase())) {
            return d;
          }
        }
      });
    }
    else if (field === "promotorNome") {
      datasFiltered = datas.filter((d) => {
        if (d.promotorNome !== "" && value !== "" && value !== undefined) {
          const promotorNome = d.promotorNome?.toLowerCase();
          if (promotorNome.includes(value.toLowerCase())) {
            return d;
          }
        }
      });
    }
    else if (field === "obsUsuario") {
      datasFiltered = datas.filter((d) => {
        if (d.obsUsuario !== "" && value !== "" && value !== undefined) {
          const obsUsuario = d.obsUsuario?.toLowerCase();
          if (obsUsuario.includes(value.toLowerCase())) {
            return d;
          }
        }
      });
    }
    else if (field === "cnpj") {
      datasFiltered = datas.filter((d) => {
        if (d.cnpj !== "" && value !== "" && value !== undefined) {
          const cnpj = d.cnpj?.toLowerCase();
          if (cnpj.includes(value.toLowerCase())) {
            return d;
          }
        }
      });
    }

    let somaQuantSucesso = 0;
    for (const item of datasFiltered) {
      somaQuantSucesso += item.quantSucesso;
    }

    setTotal([{ identificador: 1, total: 'TOTAL', quantTotal: somaQuantSucesso }]);
  }, [filtersDataGrid]);


  // columnsDataGridFooter.forEach((column) => {
  //   column.disableColumnMenu = true;
  //   column.disableClickEventBubbling = true;
  // });

  return (
    <GridContainer>
      <GridItem xs={11}>
        <Card>
          {openModalInsert ?
            <>
              <Modal openModal={openModalInsert} handleClose={handleCloseModalInsert} title="Controle de Envios">
                <form onSubmit={handleSumit}>
                  <div className={classes.inputGroup}>
                    <div className={classes.inputs}>
                      <div className={classes.inputBox}>
                        <span>Empresa</span>
                        <select className={classes.input} value={company} onChange={e => { setCompany(e.target.value) }}>
                          <option></option>
                          {companies.map((companie) => (
                            <option key={companie?.cod_emp} value={companie?.cod_emp}>{companie?.razaosocial_emp}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputGroup}>
                    <div className={classes.inputs}>
                      <div className={classes.inputBox}>
                        <span>Aeroporto Destino</span>
                        <select className={classes.input} value={destinationAirport} onChange={e => { setDestinationAirport(e.target.value) }}>
                          <option></option>
                          {airportsList.map((airport) => (
                            <option key={airport?.Abreviatura} value={airport?.Abreviatura}>{airport?.Abreviatura}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputGroup}>
                    <div className={classes.inputs}>
                      <Input title="Data Inicial" onchange={setInititalDate} classes={classes} type="date" required={true} />
                      <Input title="Data Final" onchange={setFinalDate} classes={classes} type="date" required={true} />
                    </div>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button type='submit'>
                      {t('ok')}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </form>
              </Modal>
            </> : <>
              <CardHeader color='primary' icon>
                <div className={classes.cardIcon}>
                  <AssessmentIcon />
                </div>
                <h4 className={classes.cardTitle}>
                  Envios
                </h4>

                <div style={{ display: 'flex' }}>
                  <div className={classes.cardGrid}>
                    {(initialDate !== '' && finalDate !== '') && (
                      <>
                        <div>
                          <span className={classes.spanSubTitleInfo}>Período: </span>
                          <span>{formatDate(initialDate)} a {formatDate(finalDate)}</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <button onClick={handleOpenModal} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}>
                      <FilterListIcon />
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>

                <div>
                  <ThemeProvider theme={customTheme}>
                    <DataGrid
                      rowHeight={40}
                      getRowId={(row) => row.identificador}
                      localeText={{
                        noRowsLabel: t('noRowsLabel')
                      }}
                      pageSize={20}
                      loading={loading}
                      autoHeight
                      className={classes.root}
                      hideFooterRowCount={true}
                      hideFooterSelectedRowCount={true}
                      hideFooterPagination={true}
                      columns={columns}
                      rows={datas}
                      localeText={{
                        toolbarFilters: t('toolbarFilters'),
                        toolbarFiltersLabel: t('toolbarFiltersLabel'),
                        toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide'),
                        toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow'),
                        toolbarFiltersTooltipActive: (count) => count !== 1 ? `${count} ${t('toolbarFiltersTooltipActive')}s` : `${count} ${t('toolbarFiltersTooltipActive')}`,
                        rootGridLabel: t('rootGridLabel'),
                        noRowsLabel: t('noRowsLabel'),
                        errorOverlayDefaultLabel: t('errorOverlayDefaultLabel'),
                        toolbarDensity: t('toolbarDensity'),
                        toolbarDensityLabel: t('toolbarDensityLabel'),
                        toolbarDensityCompact: t('toolbarDensityCompact'),
                        toolbarDensityStandard: t('toolbarDensityStandard'),
                        toolbarDensityComfortable: t('toolbarDensityComfortable'),
                        toolbarColumns: t('toolbarColumns'),
                        toolbarColumnsLabel: t('toolbarColumnsLabel'),
                        toolbarExport: t('toolbarExport'),
                        toolbarExportLabel: t('toolbarExportLabel'),
                        toolbarExportCSV: t('toolbarExportCSV'),
                        columnsPanelTextFieldLabel: t('columnsPanelTextFieldLabel'),
                        columnsPanelTextFieldPlaceholder: t('columnsPanelTextFieldPlaceholder'),
                        columnsPanelDragIconLabel: t('columnsPanelDragIconLabel'),
                        columnsPanelShowAllButton: t('columnsPanelShowAllButton'),
                        columnsPanelHideAllButton: t('columnsPanelHideAllButton'),
                        filterPanelAddFilter: t('filterPanelAddFilter'),
                        filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
                        filterPanelOperators: t('filterPanelOperators'),
                        filterPanelOperatorAnd: t('filterPanelOperatorAnd'),
                        filterPanelOperatorOr: t('filterPanelOperatorOr'),
                        filterPanelColumns: t('filterPanelColumns'),
                        filterPanelInputLabel: t('filterPanelInputLabel'),
                        filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
                        filterOperatorContains: t('filterOperatorContains'),
                        filterOperatorEquals: t('filterOperatorEquals'),
                        filterOperatorStartsWith: t('filterOperatorStartsWith'),
                        filterOperatorEndsWith: t('filterOperatorEndsWith'),
                        filterOperatorIs: t('filterOperatorIs'),
                        filterOperatorNot: t('filterOperatorNot'),
                        filterOperatorAfter: t('filterOperatorAfter'),
                        filterOperatorOnOrAfter: t('filterOperatorOnOrAfter'),
                        filterOperatorBefore: t('filterOperatorBefore'),
                        filterOperatorOnOrBefore: t('filterOperatorOnOrBefore'),
                        columnMenuLabel: t('columnMenuLabel'),
                        columnMenuShowColumns: t('columnMenuShowColumns'),
                        columnMenuFilter: t('columnMenuFilter'),
                        columnMenuHideColumn: t('columnMenuHideColumn'),
                        columnMenuUnsort: t('columnMenuHideColumn'),
                        columnMenuSortAsc: t('columnMenuSortAsc'),
                        columnMenuSortDesc: t('columnMenuSortDesc'),
                        columnHeaderFiltersTooltipActive: (count) =>
                          count !== 1 ? `${count} ${t('toolbarFiltersTooltipActive')}s` : `${count} ${t('toolbarFiltersTooltipActive')}`,
                        columnHeaderFiltersLabel: t('columnHeaderFiltersLabel'),
                        columnHeaderSortIconLabel: t('columnHeaderSortIconLabel'),
                        footerRowSelected: (count) => count !== 1 ? `${count.toLocaleString()} ${t('footerRowSelecteds')}` : `${count.toLocaleString()} ${t('footerRowSelected')}`,
                        footerTotalRows: t('footerTotalRows'),
                      }}
                      onFilterModelChange={(props) => {
                        setFiltersDataGrid(props);
                      }}
                      slots={{
                        toolbar: () => (
                          <CustomToolbar fileName={'numeroenvios'} />
                        ),
                        footer: () => (
                          <DataGrid
                            rowHeight={25}
                            getRowId={(row) => row.identificador}
                            rows={total}
                            columns={columnsDataGridFooter}
                            className={classesGrid.hideSortIcon}
                            disableColumnMenu={true}
                            disableColumnFilter={true}
                            hideFooterSelectedRowCount={true}
                            hideFooterPagination={true}
                            hideFooter={true}
                            sx={{
                              '& .MuiDataGrid-cell': {
                                backgroundColor: '#DEDEDE',
                                fontWeight: 'bold'
                              },
                            }}
                          />
                        )
                      }}
                    />

                  </ThemeProvider>
                </div>
              </CardBody>
            </>}


        </Card>
      </GridItem>
      {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
    </GridContainer>
  )

}


const useStyles = makeStyles({

  //MuiDataGrid-columnHeaders MuiDataGrid-withBorderColor css-1iyq7zh-MuiDataGrid-columnHeaders
  hideSortIcon: {
    '& .MuiDataGrid-columnHeaderIcon': {
      display: 'none !important',
      outline: '0 !important',
      cursor: 'auto !important'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none !important',
      outline: '0 !important',
      cursor: 'auto !important'
    },
    '& .MuiDataGrid-columnHeader': {
      outline: '0 !important',
      cursor: 'auto !important'
    },
    '.MuiDataGrid-columnHeader--sortable': {
      outline: '0 !important',
      cursor: 'auto !important'
    },
    '& .MuiDataGrid-withBorderColor': {
      outline: '0 !important',
      cursor: 'auto !important'
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none !important',
      cursor: 'auto !important'
    },
    '& .MuiDataGrid-columnSeparator--sideRight': {
      display: 'auto !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      height: '30px !important',
      minHeight: '30px !important'
    }
  },
});

// const ExportToCsvButton = () => {
//   const apiRef = useGridApiContext();

//   const handleClick = async () => {
//     const json = JSON.stringify({ csv: apiRef.current.getDataAsCsv() });
//     await downloadCSV(json)
//       .then((response) => {
//         return response.blob();
//       })
//       .then((blob) => {

//         const url = window.URL.createObjectURL(blob);

//         const a = document.createElement('a');
//         a.href = url;
//         a.download = 'relatoriogeral.xlsx'; // Defina o nome do arquivo desejado
//         document.body.appendChild(a);
//         a.click();

//         window.URL.revokeObjectURL(url);
//       })
//       .catch((error) => {
//         console.error('Erro:', error);
//       });
//   };

//   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//   const open = Boolean(anchorEl);
//   const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };


//   return (
//     <>
//       <Button
//         onClick={handleClickMenu}
//         size='sm'
//         color='gray'
//       >
//         Exportar
//       </Button>
//       <Menu
//         id="basic-menu"
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         MenuListProps={{
//           'aria-labelledby': 'basic-button',
//         }}
//       >
//         <MenuItem onClick={handleClick}>Excel</MenuItem>
//       </Menu>
//     </>
//   )
// };


// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton />
//       <GridToolbarDensitySelector />
//       <ExportToCsvButton />
//     </GridToolbarContainer>
//   );
// }


export default (withStyles(internalControl)(InternalControl));
