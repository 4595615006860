
import {
  loginPath, uploadMessagesPath, getMessagesPath, emailRecoveryPath, resetPasswordPath, getRowMessagesPath, getColumnsMessagesPath,
  uploadColumnsMessagesPath, registerNewUserPath, getSetorPath, getMenuPath, consultManifestPath, certificatePath,
  aiportPath, sendFreightWaybillPath, countryPath, sendHouseWaybillPath, getFreightWaybillPath,
  currencyPath, registerNewCompanyPath, companyPath, sendFlightManifestpath, getFlightManifestPath, sendHouseManifestPath, interControlPath,
  getHouseWaybillPath, consultHousesPath, getHouseManifestPath, consultUserPath, confirmPasswordPath, uldPath, recintoPath, manuseioPath, messagePath,
  downloadPath, userPath
} from './settings';
import autenticacaoHeader from './authenticationHeader';
import axios from 'axios';



// function Login() {
//   const [open, setOpen] = useState({ open: false, severity: '', message: '' });
//   const [showAlert, setShowAlert] = React.useState(true)
//   const handleClose = (reason?: string) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setOpen({ open: false, severity: '', message: '' });
//   };

//   return (
//     {open.open && <Snackbar open={open.open} place="bc" icon severity={open.severity} message={open.message} close={handleClose} />}
//   );
// }


axios.interceptors.request.use(function (response) {
  return response;
}, function (error) {//Oops!! Email inválido. 
  if (error?.response?.data === 'Oops!! Seu Login e / ou Senha não coincidem. ' || error?.response?.data === 'Oops!! Email inválido. ' || error?.response?.data === 'Oops!! Your Login and/or Password do not match. ') {
    return error;
  }
  else if (401 === error.response.status) {
    alert("Sessão expirada. Necessário realizar login novamente.");
  }
  else {
    return error;
  }
});



export const signIn = async (email: string, password: string) => {
  const data = { email, password }
  return await axios.post(loginPath, data);
}

export const sendEmailRecovery = async (email: string) => {
  const data = { email: email }
  return await axios.post(emailRecoveryPath, data);
}

export const sendNewPassword = async (newPassword: string, userId: string) => {
  const data = { newPassword, userId }
  return await axios.post(resetPasswordPath, data);
}

export const checkPassword = async (Senha: string, userId: string) => {
  const data = { Senha, userId }
  const headers = autenticacaoHeader();
  return await axios.post(confirmPasswordPath, data, headers);
}

export const sendMessage = async (message: FormData) => {
  const headers = autenticacaoHeader();
  return await axios.post(uploadMessagesPath, message, headers);
}

export const getMessage = async (valueFlightNum: string = '', valueDepartureDate: string = '', documentNumber: string = '', limitFirst: number = 0, limitLast: number = 0 ) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${getMessagesPath}?flightNum=${valueFlightNum}&DepartureDate=${valueDepartureDate}&documentNumber=${documentNumber}&limitFirst=${limitFirst}&limitLast=${limitLast}`, headers);
}


export const getRowMessage = async (userId: number) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${getRowMessagesPath}/${userId}`, headers);
}

export const getColumnsMessage = async (userId: number) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${getColumnsMessagesPath}/${userId}`, headers);
}

export const sendColumns = async (message: FormData) => {
  const headers = autenticacaoHeader();
  return await axios.post(uploadColumnsMessagesPath, message, headers);
}

export const createNewUser = async (data: any) => {
  const headers = autenticacaoHeader();
  return await axios.post(registerNewUserPath, data, headers);
}

export const createNewCompany = async (data: any) => {
  const headers = autenticacaoHeader();
  return await axios.post(registerNewCompanyPath, data, headers);
}

export const updateCompany = async (data: FormData, companyId: number) => {
  const headers = autenticacaoHeader();
  return await axios.put(`${companyPath}/${companyId}`, data, headers);
}

export const getSetor = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(getSetorPath, headers);
}

export const getMenuTest = async (userId: number) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${getMenuPath}/${userId}`, headers);
}

export const getConsultManifest = async (valueFlightNum: string, valueDepartureDate: string, finalDestination: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${consultManifestPath}/${valueFlightNum}/${valueDepartureDate}/${finalDestination}`, headers);
}

export const getCertificates = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${certificatePath}`, headers);
}

export const insertCertificate = async (message: FormData) => {
  const headers = autenticacaoHeader();
  return await axios.post(`${certificatePath}`, message, headers);
}

export const updateCertificates = async (certificateId: number, message: FormData) => {
  const headers = autenticacaoHeader();
  return await axios.put(`${certificatePath}/${certificateId}`, message, headers);
}


export const listAirports = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${aiportPath}/GetAirports`, headers);
}

export const listCountries = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${countryPath}/GetCountries`, headers);
}

export const listCurrency = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${currencyPath}/Currency`, headers);
}

export const sendFreightWaybill = async (data: any) => {
  const headers = autenticacaoHeader();
  return await axios.post(`${sendFreightWaybillPath}`, data, headers);
}

export const sendHouseWaybill = async (data: any) => {
  const headers = autenticacaoHeader();
  return await axios.post(`${sendHouseWaybillPath}`, data, headers);
}

export const getFreightWaybill = async (id: string, documentNumber: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${getFreightWaybillPath}?id=${id}&documentNumber=${documentNumber}`, headers);
}

export const getHouseManifest = async (id: string, documentNumber: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${getHouseManifestPath}?id=${id}&documentNumber=${documentNumber}`, headers);
}

export const getFlightManifest = async (id: string, documentNumber: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${getFlightManifestPath}?id=${id}&documentNumber=${documentNumber}`, headers);
}

export const getHouseWaybill = async (id: string, documentnumber: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${getHouseWaybillPath}?id=${id}&documentNumber=${documentnumber}`, headers);
}

export const sendFlightManifest = async (data: any) => {
  const headers = autenticacaoHeader();
  return await axios.post(`${sendFlightManifestpath}`, data, headers);
}

export const sendHouseManifest = async (data: any) => {
  const headers = autenticacaoHeader();
  return await axios.post(`${sendHouseManifestPath}`, data, headers);
}

export const getCompany = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${companyPath}/Company`, headers);
}

export const getCompanyByPromotor = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${companyPath}/GetCompanyByPromotor`, headers);
}

export const getCompanyWithUsers = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${companyPath}/GetCompanyWithUsers`, headers);
}

export const getUserCompany = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${companyPath}/GetUserCompany`, headers);
}

export const getCompanyById = async (companyId: number) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${companyPath}/${companyId}`, headers);
}

export const getDocumentsSended = async (initialDate: string, finalDate: string, company: string, destinationAirport: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${interControlPath}/DocumentsSended?dataInicial=${initialDate}&dataFinal=${finalDate}&empresa=${company}&destino=${destinationAirport}`, headers);
}

export const getDocumenstByTypeId = async (empresaId: string, usuarioId: string, initialDate: string, finalDate: string, destinationAirport: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${interControlPath}/DocumenstByTypeId?empresaId=${empresaId}&userId=${usuarioId}&dataInicial=${initialDate}&dataFinal=${finalDate}&destino=${destinationAirport}`, headers);
}

export const getHouses = async (documentNumber: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${consultHousesPath}?documentNumber=${documentNumber}`, headers);
}

export const getUser = async (userId: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${consultUserPath}/${userId}`, headers);
}

export const getUsers = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${consultUserPath}`, headers);
}

export const getULD = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${uldPath}`, headers);
}

export const getRecintos = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${recintoPath}`, headers);
}

export const getSpecialHandling = async () => {
  const headers = autenticacaoHeader();
  return await axios.get(`${manuseioPath}`, headers);
}

export const getCompanyWithCNPJ = async (cnpjconsignee: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${companyPath}/GetCompanyCNPJ?cnpjconsignee=${cnpjconsignee}`, headers);
}

export const deleteDocument = async (documentNumber: string, id: string, type: string, cnpj: string) => {
  const headers = autenticacaoHeader();
  const data = { documentNumber, id, type, cnpj };
  return await axios.post(`${messagePath}/Document`, data, headers);
}

export const downloadCSV = async (csv: string) => {
  const headers = autenticacaoHeader();

  headers.headers["Content-Type"] = "application/json";

  return fetch(`${downloadPath}/csv`, {
    method: 'POST',
    headers: headers.headers,
    body: csv,
    redirect: 'follow'
  })
}

export const downloadExtratoPDF = async (id: number, cod_emp: number) => {
  const headers = autenticacaoHeader();

  headers.headers["Content-Type"] = "application/json";

  return fetch(`${downloadPath}/extrato/${id}/${cod_emp}`, {
    method: 'GET',
    headers: headers.headers,
    redirect: 'follow'
  })
}


export const linkUserCompany = async (usuarioId: string, empresaId: string) => {
  const headers = autenticacaoHeader();
  const data = { usuarioId, empresaId };
  return await axios.post(`${userPath}/LinkUserCompany`, data, headers);
}

export const deletelinkUserCompany = async (companyUserId: number) => {
  const headers = autenticacaoHeader();
  return await axios.delete(`${userPath}/LinkUserCompany?companyUserId=${companyUserId}`, headers);
}

export const getCompanysUser = async (usuarioId: string) => {
  const headers = autenticacaoHeader();
  return await axios.get(`${userPath}/GetCompanysUser?usuarioId=${usuarioId}`, headers);
}
