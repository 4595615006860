import dayjs from 'dayjs';

export function formatDate(data: any) {

  var dataFormatada = dayjs(data).format('DD/MM/YYYY');
  return dataFormatada;

  // console.log(data);
  // var partes = data.split('-');
  // var novaData = new Date(data);
  // console.log(novaData);
  // //var novaData = new Date(partes[0], partes[1] - 1, partes[2]); // Cria um objeto Date com as partes da data
  // var dia = novaData.getDate().toString().padStart(2, '0');
  // console.log(dia);
  // var mes = (novaData.getMonth() + 1).toString().padStart(2, '0');
  // console.log(mes);
  // var ano = novaData.getFullYear().toString();
  // console.log(ano);

  // return dia + '/' + mes + '/' + ano;
}