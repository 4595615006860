import { createMuiTheme, createStyles, styled } from '@material-ui/core/styles';
import { idataDefaultColor, defaultText, cardTitle, defaultFontColor } from 'assets/App';
import { DataGrid } from '@material-ui/data-grid';


export const consultManifest = () => createStyles({
  box: {
    background: '#fff',
    borderRadius: 5,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    width: 600,
    border: '2px solid #fff',
  },
  boxTitle: {
    background: '#f6a828',
    borderRadius: 5,
    position: 'absolute',
    color: '#fff',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 35,
    width: 600,
    border: '2px solid #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonExit: {
    padding: '0 15px',
    backgroundColor: 'transparent',
    border: '0',
    color: 'white',
    cursor: 'pointer'
  },
  titleModal: {
    fontSize: 14,
    margin: '.4em 0 .2em ',
    marginLeft: '5px'
  },
  boxForm: {
    fontSize: 14,
    margin: '.1em 0 .2em ',
    marginLeft: '4px',
    height: 'auto',
    marginTop: 30
  },
  inputGroup: {
    display: 'block',
    width: '100%',
    padding: '10px',
  },
  titleInputGroup: {
    fontSize: '16px',
    fontWeight: 'bolder'
  },
  inputBox: {
    width: '100%',
    maxWidth: '17rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    //justifyContent: 'space-beetwen',
    gap: '0.5rem',
    flex: '1 1 auto',
  },
  input: {
    // color: '#000',
    // width: '50%',
    // float: 'left',
    // '&:after': {
    //   borderBottomColor: `${idataDefaultColor} !important`
    // }
    fontSize: '14px',
    color: '#000',
    width: '100%',
    float: 'left',
    padding: '5px',
    '&:after': {
      borderBottomColor: `${idataDefaultColor} !important`
    }
  },
  textAreaContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '15px 30px'
  },
  text: {
    ...defaultText,
  },
  buttonContainer: {
    marginTop: 20,
    float: 'right',
    marginRight: 12,
    marginBottom: 8,
    color: idataDefaultColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  buttonProgress: {
    color: idataDefaultColor,
    position: 'absolute',
  },
  cardIcon: {
    borderRadius: '3px',
    backgroundColor: idataDefaultColor,
    padding: '15px',
    marginTop: '-20px',
    marginRight: '15px',
    float: 'left'
  },
  tableHeader: {
    width: '100%',
    textAlign: 'left',
    color: 'rgb(0, 0, 0, 0.87)',
    padding: '20px 0px 30px 12px',
    tableLayout: 'fixed',
    fontSize: '1rem'

  },
  tableFooter: {
    width: '100%',
    textAlign: 'left',
    color: 'rgb(0, 0, 0, 0.87)',
    padding: 10,
    border: 'solid 1px #e0e0e0',
    tableLayout: 'fixed',
    borderTopColor: '#D7CCE3',
    fontSize: '1rem'
  },
  root: {
    minHeight: 500,
    '& .MuiButton-textPrimary': {
      color: '#777',
    },
    '& .MuiBadge-colorPrimary': {
      backgroundColor: idataDefaultColor,
    },
    '& .MuiDataGrid-row *:focus': {
      boxShadow: '0 0 0 0',
      border: '0',
      outline: 0,
    },
  },
  cardGrid: {
    width: '100%'
  },
  cardTitle: {
    ...cardTitle,
    padding: '15px 0 0 5px',
    marginBottom: '0px',
  },
  dadosGeraisContainer: {
    marginTop: '60px',
    height: '50px',
    width: '100%',
    border: 'solid 1.5px #000',
    borderRadius: '5px'
  },
  link: {
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: idataDefaultColor,
    }
  },
  spanTitleInfo: {
    display: 'block',
    fontSize: '16px'
  },
  spanSubTitleInfo: {
    fontWeight: 400
  }

});

export const customTheme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      colorPrimary: {
        '&$checked': {
          color: idataDefaultColor,
        },
        '&$checked + .MuiSwitch-track': {
          backgroundColor: idataDefaultColor,
        },
      },
      track: { backgroundColor: idataDefaultColor }
    },

    MuiFormLabel: {
      root: { '&$focused': { color: defaultFontColor } }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: idataDefaultColor
        }
      }
    },
    MuiButton: {
      textPrimary: {
        fontSize: 13,
        color: idataDefaultColor,
      },
    },
    MuiCircularProgress: {
      colorPrimary: { color: idataDefaultColor }
    },
    MuiMenuItem: {
      root: {
        fontSize: 15
      }
    }
  },
});

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    true ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: true ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${true ? '#f0f0f0' : '#303030'
      }`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${true ? '#f0f0f0' : '#303030'
      }`,
  },
  '& .MuiDataGrid-cell': {
    color:
      true ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  }
}));

export default consultManifest;