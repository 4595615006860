import createStyles from '@material-ui/core/styles/createStyles';

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from 'assets/App';

const dashboardStyle = (theme:any) => createStyles({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '' ,
    }
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: 'hidden',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch'
  },
  content: {
   marginTop: '60px',  
   padding: '0 15px',
   paddingTop: '15px',
   height: '100vh'
  },
  container: { ...containerFluid },
  map: {
    marginTop: '70px'
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',   
  }
});

export default dashboardStyle;
